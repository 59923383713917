import React, { useState, useCallback, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Form } from '@unform/web';
import Select from '../../components/SelectLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Text from '../../components/Text';
import Checkbox from '../../components/Checkbox';

import Students from '../../components/icons/SelectLoginIcons/StudentsIcon';
import Instructor from '../../components/icons/SelectLoginIcons/InstructorIcon';
import CFCicon from '../../components/icons/SelectLoginIcons/CFCicon';
import PinIcon from '../../components/icons/SelectStateIcons/PinIcon';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationError';

import './index.css';

interface SignInFormData {
  identifier: string;
  password: string;
}

interface UserData {
  accept_terms: number | null;
}

const Signin: React.FC = () => {
  const [selected, setSelected] = useState(1);
  const [selectedPin, setSelectedPin] = useState(1);
  const [selectedRemember, setSelectedRemember] = useState(true);

  const [identy, setIdenty] = useState('');
  const [pass, setPass] = useState('');

  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const location = useLocation();

  useEffect(() => {
    const getRemember = localStorage.getItem('@UniCFC:rememberme');
    const getIdentifier = localStorage.getItem('@UniCFC:identifier');
    const getPass = localStorage.getItem('@UniCFC:pass');

    if (getRemember) {
      setSelectedRemember(true);
    }

    if (getIdentifier) {
      setIdenty(getIdentifier);
    }

    if (getPass) {
      setPass(getPass);
    }
  }, []);

  const handleLocale = (pin: number) => {
    localStorage.setItem('@UniCFC:locale', pin === 2 ? 'SC' : 'ALL');
    setSelectedPin(pin);
  };

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          identifier: Yup.string().required('E-mail ou CPF obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          identifier: data.identifier.includes('@')
            ? data.identifier
            : cpfMask(data.identifier),
          password: data.password,
        });

        if (selectedRemember) {
          localStorage.setItem('@UniCFC:rememberme', 'true');
          localStorage.setItem('@UniCFC:identifier', data.identifier);
          localStorage.setItem('@UniCFC:pass', data.password);
        }

        if (!selectedRemember) {
          localStorage.removeItem('@UniCFC:rememberme');
          localStorage.removeItem('@UniCFC:identifier');
          localStorage.removeItem('@UniCFC:pass');
        }

        const getData = localStorage.getItem('@UniCFC:user');
        if (getData) {
          const { accept_terms } = JSON.parse(getData) as UserData;

          const refer = location.search.replace('?refer=', '');

          if (accept_terms === 1) {
            if (refer) {
              history.push(refer);
            } else {
              history.push('/');
            }
          } else {
            history.push('/terms');
          }
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, selectedRemember, location.search, history, addToast],
  );

  return (
    <div className="SigninContainer">
      <div className="Content">
        <div className="Main">
          <div>
            <div className="dataInfo">
              <span>
                <Text
                  style={{
                    fontSize: 82,
                    fontWeight: 200,
                    color: '#a3bac8',
                  }}
                >
                  UNI
                </Text>
                <Text style={{ fontWeight: 700, color: '#FFF' }}>CFC</Text>
              </span>
            </div>
            <div className="loginContainer">
              <Text style={{ fontSize: 20, fontWeight: 700 }}>
                Entrar como:
              </Text>
              <div className="loginType">
                <Select
                  Icon={
                    <Students color={selected === 1 ? '#FFFF' : '#ADC3D9'} />
                  }
                  relItem="Aluno"
                  isSelected={selected === 1}
                  onClick={() => {
                    setSelected(1);
                  }}
                />

                <Select
                  Icon={
                    <Instructor color={selected === 2 ? '#FFFF' : '#ADC3D9'} />
                  }
                  relItem="Instrutor"
                  isSelected={selected === 2}
                  onClick={() => {
                    setSelected(2);
                  }}
                />
                <Select
                  Icon={
                    <CFCicon color={selected === 3 ? '#FFFF' : '#ADC3D9'} />
                  }
                  relItem="CFC"
                  isSelected={selected === 3}
                  onClick={() => {
                    setSelected(3);
                  }}
                />
              </div>
              <div className="loginLocale">
                <Text style={{ fontSize: 20, fontWeight: 700 }}>
                  Pelo estado de:
                </Text>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      handleLocale(1);
                    }}
                    style={{
                      display: 'flex',

                      border: 0,
                      background: 'transparent',
                    }}
                  >
                    <span
                      style={
                        selectedPin === 1
                          ? { color: '#1976D2', fontWeight: 'bold' }
                          : { color: '#ADC3D9', fontWeight: 'bold' }
                      }
                    >
                      <PinIcon
                        color={selectedPin === 1 ? '#1976D2' : '#ECF2F8'}
                      />
                      Rio grande do Sul
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLocale(2);
                    }}
                    style={{ border: 0, background: 'transparent' }}
                  >
                    <span
                      style={
                        selectedPin === 2
                          ? { color: '#1976D2', fontWeight: 'bold' }
                          : { color: '#ADC3D9', fontWeight: 'bold' }
                      }
                    >
                      <PinIcon
                        color={selectedPin === 2 ? '#1976D2' : '#ECF2F8'}
                      />
                      Santa Catarina
                    </span>
                  </button>
                </div>
              </div>

              <Form ref={formRef} onSubmit={handleSubmit} className="form">
                <Input
                  name="identifier"
                  type="text"
                  placeholder="Digite seu CPF ou e-mail"
                  defaultValue={identy}
                />
                <Input
                  name="password"
                  type="password"
                  placeholder="Digite sua senha"
                  defaultValue={pass}
                />

                <Link to="/forgot">Esqueci minha senha</Link>

                <div className="RememberContainer">
                  <Checkbox
                    isSelected={selectedRemember}
                    onClick={() => {
                      setSelectedRemember(!selectedRemember);
                    }}
                  />

                  <Text style={{ fontSize: 12, color: '#434D59' }}>
                    Lembrar-me neste computador?
                  </Text>
                </div>

                <Button loading={loading} type="submit">
                  Entrar
                </Button>
                <Button
                  onClick={() => {
                    history.push('/help');
                  }}
                  outerline
                  backgroundColor="#1976D2"
                  color="#1976D2"
                  hoverOff
                >
                  Preciso de ajuda!
                </Button>
              </Form>

              <section />
            </div>
            <div className="dataInfo">
              <span>Plataforma de Ensino a Distância</span>
            </div>
          </div>
        </div>
      </div>

      <footer className="Footer">
        ©2021 UNICFC S/A - Todos os direitos reservados
      </footer>
    </div>
  );
};

export default Signin;
