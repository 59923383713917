import React, { InputHTMLAttributes } from 'react';

import { FaCheck } from 'react-icons/fa';
import { CheckboxInput } from './style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  color?: string;
  iconColor?: string;
  isSelected?: boolean;
}

const Checkbox: React.FC<InputProps> = ({
  iconColor = '#FFFFFF',
  isSelected = false,
  ...rest
}) => {
  return (
    <button
      type="button"
      style={{
        border: 0,
        background: 'transparent',
        height: 20,
        width: 20,
        marginRight: 10,
      }}
    >
      <CheckboxInput {...rest} isSelected={isSelected}>
        {isSelected === true ? <FaCheck color={iconColor} /> : ''}
      </CheckboxInput>
    </button>
  );
};

export default Checkbox;
