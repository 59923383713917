import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Content,
  Container,
  Main,
  // NextClassContainer,
  LeassonCfcStyled,
} from './styles';
import NotePad from '../../components/InsertNoteCards';
import CfcCard from '../../components/CardCFC/cfc.content.card';
import Loading from '../../components/Loading';
import { useApp } from '../../hooks/app';

const EadContent: React.FC = () => {
  const {
    currentUnit,
    currentCourse,
    setCurrentUnitExamContent,
    currentUnitExamContent,
  } = useApp();
  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowLayout(true), 5000);
  });

  return (
    <Content>
      {!showLayout && <Loading />}
      <NotePad />
      <Main>
        <Container>
          {/* <NextClassContainer> */}
          <span>Cursos - CFC</span>
          <LeassonCfcStyled className="class-course-card">
            <Row className="header-container">
              <Col md="12">
                <CfcCard
                  currentUnit={currentUnit}
                  currentCourse={currentCourse}
                  setCurrentUnitExamContent={setCurrentUnitExamContent}
                  currentUnitExamContent={currentUnitExamContent}
                />
              </Col>
              <Col md="12">
                {currentUnit &&
                  currentUnit[0] &&
                  currentUnit[0].materials &&
                  currentUnit[0].materials[0] && (
                    <div
                      className="container-content"
                      /* eslint-disable-next-line */
                      dangerouslySetInnerHTML={{
                        __html: currentUnit[0].materials[0].content,
                      }}
                    />
                  )}
              </Col>
            </Row>
          </LeassonCfcStyled>
          {/* </NextClassContainer> */}
        </Container>
      </Main>
    </Content>
  );
};

export default EadContent;
