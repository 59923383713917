import styled from 'styled-components';

export const CardContainer = styled.div`
  .class-card {
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    -moz-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .delete-icon {
    button {
      background: unset;
      width: initial;
      position: absolute;
      margin-right: -55px;
      margin-top: -10px;
      left: -15px;
      top: 30px;
      border: unset;
      padding: 3px 8px;
      border-radius: 100px;
    }
  }

  .class-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #1976d2;
    color: #fff;
    padding: 5px 10px;

    > div {
      display: flex;
    }

    h5 {
      line-height: 25px;
      margin: 0;
    }
  }

  .class-body {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

    color: #0e4378;
  }
`;

export const HandoutCardStyle = styled.div`
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  -moz-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);

  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;

// /* Group 128 */

// position: absolute;
// width: 410px;
// height: 182px;
// left: 398px;
// top: 277px;

// /* Group 115 */

// position: absolute;
// width: 32px;
// height: 32px;
// left: 776px;
// top: 300px;

// /* Ellipse 18 */

// position: absolute;
// width: 32px;
// height: 32px;
// left: 776px;
// top: 300px;

// background: #1976D2;

// /* Group 114 */

// position: absolute;
// width: 16.15px;
// height: 15px;
// left: 784px;
// top: 309px;

// /* Group 113 */

// position: absolute;
// width: 16.15px;
// height: 15px;
// left: 784px;
// top: 309px;

// /* Vector */

// position: absolute;
// left: 57.39%;
// right: 41.42%;
// top: 21.12%;
// bottom: 78.68%;

// background: #FFFFFF;

// /* Vector */

// position: absolute;
// left: 57.48%;
// right: 41.51%;
// top: 21.4%;
// bottom: 77.85%;

// background: #FFFFFF;

// /* Rectangle 154 */

// position: absolute;
// width: 370px;
// height: 44px;
// left: 398px;
// top: 299px;

// background: #1976D2;
// border-radius: 6px 6px 0px 0px;

// /* Rectangle 155 */

// position: absolute;
// width: 370px;
// height: 76px;
// left: 768px;
// top: 419px;

// background: #DEEBF8;
// border-radius: 6px 6px 0px 0px;
// transform: rotate(-180deg);

// /* Título */

// position: absolute;
// width: 46px;
// height: 19px;
// left: 414px;
// top: 312px;

// font-family: Inter;
// font-style: normal;
// font-weight: bold;
// font-size: 16px;
// line-height: 19px;

// color: #FFFFFF;

// /* Dia 10/02/2021 - 08:00hs */

// position: absolute;
// width: 147px;
// height: 15px;
// left: 398px;
// top: 277px;

// font-family: Inter;
// font-style: normal;
// font-weight: 500;
// font-size: 12px;
// line-height: 15px;
// /* identical to box height */

// /* cores/azul/azul E-4 */
// color: #0E4378;

// /* ic:round-expand-more */

// position: absolute;
// width: 24px;
// height: 24px;
// left: 736px;
// top: 387px;

// /* Vector */

// position: absolute;
// left: 24.99%;
// right: 28.45%;
// top: 37.49%;
// bottom: 35.07%;

// background: #5A9DDE;

// /* Quer mesmo pagar sua anotação? */

// position: absolute;
// visibility: hidden;
// width: 200px;
// height: 15px;
// left: 398px;
// top: 436px;

// font-family: Inter;
// font-style: normal;
// font-weight: bold;
// font-size: 12px;
// line-height: 15px;
// /* identical to box height */

// /* cores/laranja ★ */
// color: #FF5722;

// /* • A placa de pare é vermelha • Dirigir sempre atento • Isso pode cair na prova: */

// position: absolute;
// width: 165px;
// height: 60px;
// left: 414px;
// top: 354px;

// font-family: Inter;
// font-style: normal;
// font-weight: 500;
// font-size: 12px;
// line-height: 15px;

// /* cores/azul/azul E-4 */
// color: #0E4378;

// /* Rectangle 161 */

// position: absolute;
// width: 67px;
// height: 32px;
// left: 626px;
// top: 427px;

// border-radius: 6.94137px;

// /* Rectangle 162 */

// position: absolute;
// width: 67px;
// height: 32px;
// left: 701px;
// top: 427px;

// border-radius: 6.94137px;

// /* Sim */

// position: absolute;
// width: 25px;
// height: 17px;
// left: 647px;
// top: 434.5px;

// font-family: Inter;
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 17px;
// /* identical to box height */
// display: flex;
// align-items: center;
// text-align: center;

// /* Não */

// position: absolute;
// width: 27px;
// height: 17px;
// left: 721px;
// top: 434.5px;

// font-family: Inter;
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 17px;
// /* identical to box height */
// display: flex;
// align-items: center;
// text-align: center;
