import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

import ForumRespCard from '../../components/ForumRespCard';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NoteStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';

const Forum: React.FC = () => {
  const [, setForumData] = useState([]);

  useEffect(() => {
    const newsForumData = () => {
      axios
        .get('https://z0nmx78ryk.execute-api.us-east-2.amazonaws.com/dev/')
        .then((result) => {
          const getForumData = result.data;
          setForumData(getForumData);
        });
    };
    newsForumData();
  }, []);

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <span>Forum UNICFC EAD - Resposta</span>
            <NoteStyled>
              {/* {forumData.map((value: any) => {
                return ( */}
              <Row>
                <Col md="12">
                  <ForumRespCard title="teste" description="" text="teste" />
                </Col>
                <Col md="12">
                  <span style={{ color: '#1976d2' }}>Digite sua resposta</span>
                </Col>
                <Col className="class-editor" md="12">
                  <Editor
                    initialValue=""
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
                    }}
                  />
                </Col>
                <Col md="6">
                  <Button className="class-first-button">Voltar</Button>
                </Col>
                <Col
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  md="6"
                >
                  <Button className="class-second-button">Enviar</Button>
                </Col>
              </Row>
              {/* ); */}
              {/* })} */}
            </NoteStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Forum;
