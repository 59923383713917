import React, { CSSProperties } from 'react';

import { TextComponent } from './styles';

interface TextIterface {
  style?: CSSProperties;
  fontSize?: number;
  fontWeight?: string;
  color?: string;
  className?: string;
}

const Text: React.FC<TextIterface> = ({ children, style, ...rest }) => {
  return (
    <TextComponent style={style} {...rest}>
      {children}
    </TextComponent>
  );
};

export default Text;
