import React from 'react';
// import { Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import notFoundImage from '../../assets/images/not-found.jpg';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NotFoundContainer,
} from './styles';
import Button from '../../components/Button';

const NotFound: React.FC = () => {
  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <NotFoundContainer>
              <img src={notFoundImage} alt="Pagina não encontrada" />
              <div className="title-description">Página não encontrada!</div>
              <div className="text-description">
                Por favor, clique no botão abaixo para retornar a Plataforma!
              </div>
              <Link to="/">
                <Button
                  color="#1976D2"
                  backgroundColor="#1976D2"
                  hoverOff
                  outerline
                  className="button-container"
                  type="button"
                >
                  ACESSAR PRINCIPAL
                </Button>
              </Link>
            </NotFoundContainer>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default NotFound;
