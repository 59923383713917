import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from '../../assets/images/leasson.svg';
import checkIcon from '../../assets/icons/checkCircleIcon.svg';
import editIcon from '../../assets/icons/editCircleIcon.svg';
import Countdown from '../CountdownV2';
import Button from '../Button';

import './cfc.css';
import { getExam, makeStudyUnit } from '../../services/courses';

interface CfcContentCardProp {
  currentUnit?: any;
  currentCourse?: any;
  setCurrentUnitExamContent?: any;
}

const CfcContentCard: React.FC<CfcContentCardProp> = ({
  currentUnit,
  currentCourse,
  setCurrentUnitExamContent,
}) => {
  const [simulateAvaliable, setsimulateAvaliable] = useState(false);

  useEffect(() => {
    const getUnitExamContent = async () => {
      const paylod = {
        student_id: currentUnit[0].student_id,
        course_id: currentUnit[0].course,
        unit_id: currentUnit[0].id,
        matriculation_id: currentUnit[0].matriculation_id,
        moduluse_id: currentUnit[0].moduluse_id,
      };
      const examData = await getExam(paylod);
      if (examData)
        setCurrentUnitExamContent({ type: 'unit', content: examData });
    };
    if (currentUnit.length > 0) getUnitExamContent();
  }, [currentUnit]); /* eslint-disable-line */

  const makeStudyStatus = async () => {
    const paylod = {
      student_id: currentUnit[0].student_id,
      course_id: currentUnit[0].course,
      unit_id: currentUnit[0].id,
      matriculation_id: currentUnit[0].matriculation_id,
      moduluse_id: currentUnit[0].moduluse_id,
      finalized: currentUnit[0].student_unit_status === 'ready' ? 1 : 0,
    };
    const result = await makeStudyUnit(paylod);
    if (result) {
      setsimulateAvaliable(true);
    }
  };

  const nameUnit =
    currentUnit && currentUnit[0] && currentUnit[0].name
      ? currentUnit[0]?.name.split(' - ')
      : [];

  const ButtonType = () => {
    if (
      currentUnit[0] &&
      (currentUnit[0].student_unit_status !== 'ready' || simulateAvaliable)
    ) {
      return (
        <Col md="4" className="open-class">
          <Link to="/course/ead">
            <Button
              hoverColor="linear-gradient(117.12deg, #3EC1B1 53.53%, #FDFEFF 112.46%)"
              color="#FFF"
              backgroundColor="#3EC1B1"
            >
              <img src={checkIcon} alt="Prova final" /> Finalizar Aula
            </Button>
          </Link>
          <Link to="/course/ead/content/exam">
            <Button
              hoverColor="linear-gradient(117.12deg, #FF5722 53.53%, #FDFEFF 112.46%)"
              color="#FFF"
              backgroundColor="#FF5722"
            >
              <img src={editIcon} alt="Prova final" /> Iniciar Avaliação
            </Button>
          </Link>
        </Col>
      );
    }

    return (
      <Col md="4" className="with-timer">
        <div>
          <div style={{ marginBottom: '15px' }}>
            {/* <span>Duração da aula</span> */}
            <Countdown
              initialMinute={Math.round(currentUnit.time / 60)}
              actionEndTime={makeStudyStatus}
            />
          </div>
          <Button
            hoverColor="linear-gradient(117.12deg, #a5a5a5 53.53%, #fafafa 112.46%)"
            color="#FFF"
            backgroundColor="#ADC3D9"
          >
            <img src={editIcon} alt="Prova final" /> Iniciar Avaliação
          </Button>
        </div>
      </Col>
    );
  };

  return (
    <section className="Cfc">
      <div className="CfcContainer">
        <div className="CfcContent">
          <Row>
            <Col md="3">
              <img src={Image} alt="Primeira Habilitação" />
            </Col>
            <Col md="5" className="CfcDetails">
              <Row>
                <Col md="12">
                  <h4>CURSO {currentCourse?.course_name}</h4>
                </Col>
                <Col md="12">
                  {nameUnit[0]} -
                  <span className="destaque-subtitle status-text-blue">
                    {nameUnit[1]}
                  </span>
                </Col>
              </Row>
            </Col>
            <ButtonType />
          </Row>
          <div>
            <div />
          </div>

          <div />
        </div>
      </div>
    </section>
  );
};

export default CfcContentCard;
