import React from 'react';

interface SvgIcon {
  color?: string;
}

const DownArrowIcon: React.FC<SvgIcon> = ({ color = '#373F49' }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.06547 0.550322L5.94547 4.43032L9.82547 0.550322C9.91805 0.45774 10.028 0.3843 10.1489 0.334195C10.2699 0.28409 10.3995 0.258301 10.5305 0.258301C10.6614 0.258301 10.7911 0.28409 10.912 0.334195C11.033 0.3843 11.1429 0.45774 11.2355 0.550322C11.3281 0.642904 11.4015 0.752814 11.4516 0.873778C11.5017 0.994742 11.5275 1.12439 11.5275 1.25532C11.5275 1.38625 11.5017 1.5159 11.4516 1.63686C11.4015 1.75783 11.3281 1.86774 11.2355 1.96032L6.64547 6.55032C6.55296 6.64302 6.44307 6.71657 6.3221 6.76675C6.20112 6.81694 6.07144 6.84277 5.94047 6.84277C5.8095 6.84277 5.67982 6.81694 5.55885 6.76675C5.43787 6.71657 5.32799 6.64302 5.23547 6.55032L0.645472 1.96032C0.552768 1.86781 0.47922 1.75792 0.429039 1.63695C0.378858 1.51597 0.353027 1.38629 0.353027 1.25532C0.353027 1.12435 0.378858 0.994671 0.429039 0.873697C0.47922 0.752724 0.552768 0.642835 0.645472 0.550322C1.03547 0.170322 1.67547 0.160322 2.06547 0.550322Z"
        fill={color}
      />
    </svg>
  );
};

export default DownArrowIcon;
