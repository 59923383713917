import React from 'react';

interface SvgIcon {
  color?: string;
}

/* eslint-disable-next-line */
const AddIcon: React.FC<SvgIcon> = ({ color = '#1976D2' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16343 24.8366 0 16 0C7.16343 0 0 7.16343 0 16C0 24.8366 7.16343 32 16 32ZM14.8571 10.2857C14.8571 9.98261 14.9776 9.69192 15.1919 9.47759C15.4062 9.26327 15.6969 9.14286 16 9.14286C16.3031 9.14286 16.5938 9.26327 16.8081 9.47759C17.0225 9.69192 17.1429 9.98261 17.1429 10.2857V14.8571H21.7143C22.0174 14.8571 22.3081 14.9776 22.5224 15.1919C22.7367 15.4062 22.8571 15.6969 22.8571 16C22.8571 16.3031 22.7367 16.5938 22.5224 16.8081C22.3081 17.0225 22.0174 17.1429 21.7143 17.1429H17.1429V21.7143C17.1429 22.0174 17.0225 22.3081 16.8081 22.5224C16.5938 22.7367 16.3031 22.8571 16 22.8571C15.6969 22.8571 15.4062 22.7367 15.1919 22.5224C14.9776 22.3081 14.8571 22.0174 14.8571 21.7143V17.1429H10.2857C9.98261 17.1429 9.69192 17.0225 9.47759 16.8081C9.26327 16.5938 9.14286 16.3031 9.14286 16C9.14286 15.6969 9.26327 15.4062 9.47759 15.1919C9.69192 14.9776 9.98261 14.8571 10.2857 14.8571H14.8571V10.2857Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default AddIcon;
