import React from 'react';

interface SvgIcon {
  color: string;
}

const StudentsIcon: React.FC<SvgIcon> = ({ color }) => {
  return (
    <svg
      width="36"
      height="41"
      viewBox="0 0 36 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0659 26.3023C35.0659 24.422 33.6754 22.8489 31.8331 22.4871V18.2817C31.8331 17.8986 31.6381 17.5399 31.3113 17.3216C30.9844 17.1034 30.5672 17.0534 30.1951 17.188L27.8812 18.024C26.3618 16.502 24.5149 15.3557 22.491 14.652C24.4798 13.2321 25.7715 10.9549 25.7715 8.39223C25.7715 4.09842 22.146 0.605263 17.6894 0.605263C13.2329 0.605263 9.60737 4.09842 9.60737 8.39223C9.60737 10.9549 10.899 13.2321 12.8879 14.652C10.8639 15.3558 9.01705 16.5021 7.49762 18.024L5.18381 17.188C4.81155 17.0534 4.39435 17.1034 4.06759 17.3216C3.74075 17.5399 3.54582 17.8986 3.54582 18.2817V22.4871C1.70351 22.8489 0.312988 24.4219 0.312988 26.3023C0.312988 28.1826 1.70351 29.7556 3.54582 30.1174V34.6343C3.54582 35.1212 3.85932 35.5571 4.33244 35.728L17.2638 40.4002C17.5364 40.4981 17.8423 40.4981 18.115 40.4002L31.0463 35.728C31.5195 35.5571 31.833 35.1212 31.833 34.6343V30.1174C33.6754 29.7556 35.0659 28.1825 35.0659 26.3023ZM12.032 8.39223C12.032 5.38662 14.5699 2.94135 17.6894 2.94135C20.809 2.94135 23.3469 5.38662 23.3469 8.39223C23.3469 11.3978 20.809 13.8431 17.6894 13.8431C14.5699 13.8431 12.032 11.3978 12.032 8.39223ZM17.6894 16.1792C20.5297 16.1792 23.2425 17.1682 25.3592 18.9352L17.6894 21.7064L10.0196 18.9352C12.1364 17.1682 14.8492 16.1792 17.6894 16.1792ZM2.73761 26.3023C2.73761 25.4435 3.46273 24.7449 4.35402 24.7449H5.16223V27.8597H4.35402C3.46273 27.8597 2.73761 27.161 2.73761 26.3023ZM5.97044 30.1566C6.89155 29.9757 7.58685 29.1891 7.58685 28.249V24.3555C7.58685 23.4154 6.89155 22.6288 5.97044 22.4479V19.9672L16.4771 23.7633V37.6211L5.97044 33.8249V30.1566ZM18.9017 37.621V23.7633L29.4084 19.9672V22.4479C28.4873 22.6288 27.792 23.4154 27.792 24.3555V28.249C27.792 29.1891 28.4873 29.9757 29.4084 30.1566V33.8249L18.9017 37.621ZM31.0248 27.8597H30.2166V24.7449H31.0248C31.9161 24.7449 32.6413 25.4435 32.6413 26.3023C32.6413 27.161 31.9162 27.8597 31.0248 27.8597Z"
        fill={color}
      />
    </svg>
  );
};

export default StudentsIcon;
