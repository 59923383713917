import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { CardContainer } from './styles';
import examImg from '../../assets/images/exam.svg';

interface NoteCardProp {
  type?: any;
  title: string;
  description: string;
  date: string;
}
const NoteCard: React.FC<NoteCardProp> = () => {
  return (
    <CardContainer>
      <Row>
        <Col md="12">
          <Card className="class-card">
            <Card.Body className="class-body">
              <Row>
                <Col md="3">
                  <img className="class-img" src={examImg} alt="Exam Imagem" />
                </Col>
                <Col className="class-inside-card-information " md="9">
                  <Row>
                    <Col md="12">
                      <Card.Title>
                        <p>Avaliação</p>
                      </Card.Title>
                    </Col>
                    <Col className="class-subtitle" md="12">
                      <p>
                        Curso - Atualização para condutores de transporte
                        escolar
                      </p>
                    </Col>
                    <Col className="class-description" md="12">
                      <p>
                        Aula 02 - <strong>Normas Gerais de Circulação</strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default NoteCard;
