import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Text from '../../components/Text';

import ForgotPasswordImage from '../../assets/images/forgotPassword.svg';

import { useToast } from '../../hooks/toast';
import api from '../../services/apiClient';
import getValidationErrors from '../../utils/getValidationError';

import './forgot.css';

interface SignInFormData {
  identifier: string;
}

const Forgot: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [succeed, setSucessed] = useState(false);

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          identifier: Yup.string().required('E-mail ou CPF obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/password/forgot', {
          email: data.identifier,
        });

        setSucessed(true);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [addToast],
  );

  return (
    <div className="SigninContainer">
      <div className="Content">
        <div className="Main">
          <div>
            <div className="dataInfo">
              <span>
                <Text
                  style={{
                    fontSize: 82,
                    fontWeight: 200,
                    color: '#a3bac8',
                  }}
                >
                  UNI
                </Text>
                <Text style={{ fontWeight: 700, color: '#FFF' }}>CFC</Text>
              </span>
            </div>
            <div className="loginContainer">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="forgotForm"
              >
                <div>
                  <img
                    src={ForgotPasswordImage}
                    alt="Esqueceu sua senha ?"
                    width={236}
                  />
                </div>
                {succeed ? (
                  <>
                    <div className="HeaderContent">
                      <Text color="#FF5722" fontSize={20} fontWeight="bold">
                        Uhuuuull!
                      </Text>

                      <Text color="#434D59" fontSize={12}>
                        Tudo certo para recuperar sua senha.
                      </Text>
                    </div>

                    <Text
                      fontSize={18}
                      color="#236e65"
                      fontWeight="400"
                      style={{ textAlign: 'center', margin: '20% 0' }}
                    >
                      Um e-mail com instruções para alterar sua senha foi
                      enviado.
                    </Text>
                  </>
                ) : (
                  <>
                    <div className="HeaderContent">
                      <Text color="#FF5722" fontSize={20} fontWeight="bold">
                        Vamos resolver isso!
                      </Text>

                      <Text color="#434D59" fontSize={12}>
                        Preencha o campo à baixo com seu e-mail de cadastro e
                        enviaremos um e-mail de redefinição de senha dentro de
                        alguns minutos.
                      </Text>
                    </div>

                    <Input
                      name="identifier"
                      type="text"
                      placeholder="Digite seu CPF ou e-mail"
                    />

                    <Button loading={loading} type="submit">
                      Trocar minha senha
                    </Button>
                  </>
                )}

                <Button
                  type="submit"
                  color="#1976D2"
                  backgroundColor="#1976D2"
                  outerline
                  hoverOff
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Voltar
                </Button>
              </Form>
            </div>
            <div className="dataInfo">
              <span>Plataforma de Ensino a Distância</span>
            </div>
          </div>
        </div>
      </div>

      <footer className="Footer">
        ©2021 UNICFC S/A - Todos os direitos reservados
      </footer>
    </div>
  );
};

export default Forgot;
