import React from 'react';

import { Row, Col, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import Image from '../../assets/images/leasson.svg';
import playIcon from '../../assets/icons/playIcon.svg';

import './cfc.css';

interface CfcCardComponentProp {
  type?: string;
  content?: any;
}

const CfcHistoryCard: React.FC<CfcCardComponentProp> = ({ content }) => {
  const TimerType = () => {
    if (content.progress === 95) {
      return (
        <>
          <Col md="12" className="history-finished-text">
            {/* <img src={checkIcon} alt="concluido" /> */}
            <span>Faça a prova final para finalizar o curso</span>
          </Col>
        </>
      );
    }
    return (
      <Col md="12" className="history-progress">
        <ProgressBar now={content.progress} variant="original-uni" />
        <span>{content.progress}%</span>
      </Col>
    );
  };

  const ButtonType = () => {
    if (content.progress === 95) {
      return (
        <Col md="4" className="history-finished">
          <span className="started-in">
            Iniciado em{' '}
            <b>
              {moment(content.created_at, 'YYYY-MM-DD HH:mm').format(
                'DD/MM/YYYY',
              )}
            </b>
          </span>
          <button type="button" className="button-container orange">
            <img src={playIcon} alt="Prova final" />
            Prova final
          </button>
        </Col>
      );
    }
    return (
      <Col md="4" className="history-in-time">
        <span className="started-in">
          {moment(content.created_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY')}
        </span>
        {/* <button type="button">Estudar</button> */}
      </Col>
    );
  };

  return (
    <section className="Cfc">
      <div className="CfcContainer">
        <div className="CfcContent">
          <Row>
            <Col md="3">
              <img src={Image} alt="Primeira Habilitação" />
            </Col>
            <Col md="5" className="CfcDetails">
              <Row>
                <Col md="12">
                  <h1>{content.course_name}</h1>
                </Col>
                <TimerType />
              </Row>
            </Col>
            <ButtonType />
          </Row>
          <div>
            <div />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CfcHistoryCard;
