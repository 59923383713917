import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { CardContainer } from './styles';

interface QuestionCardProp {
  content?: any;
  handleQuestion?: any;
}

const parseAlternative = ['A', 'B', 'C', 'D', 'E', 'F'];
const AskCard: React.FC<QuestionCardProp> = ({ content, handleQuestion }) => {
  return (
    <CardContainer>
      <Row>
        <Col md="12">
          <Card className="class-card">
            <Card.Body className="class-body">
              <Row>
                <Col md="10">
                  <Card.Title className="class-title">
                    <p>{content.name}</p>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: content.rectification,
                      }}
                    /> */}
                  </Card.Title>
                </Col>
                <Col className="class-cod" md="2">
                  <p>
                    <strong>Cód:</strong>
                    {content.question_code}
                  </p>
                </Col>
                <Col className="class-cod" md="2">
                  {content.image_url && (
                    <img src={content.image_url} alt="imagem" height="300px" />
                  )}
                </Col>
                {content.question_alternatives &&
                  content.question_alternatives.map(
                    (alternative: any, index: any) => {
                      return (
                        <Col
                          className="class-answers"
                          md="12"
                          key={alternative.id}
                        >
                          <div>
                            <input
                              className="class-input"
                              type="radio"
                              name={`content_${content.id}`}
                              onChange={() => {
                                handleQuestion({
                                  question_id: content.id,
                                  name: content.name,
                                  alternative: alternative.title,
                                  correct: alternative.correct,
                                  code: content.question_code,
                                });
                              }}
                            />
                            <strong> {parseAlternative[index]}- </strong>
                            {alternative.title}
                          </div>
                        </Col>
                      );
                    },
                  )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default AskCard;
