import React, { useState, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import {
  Container,
  ForgotContainer,
  ForgotContent,
  Content,
  Main,
} from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Text from '../../components/Text';

import ForgotPasswordImage from '../../assets/images/recoveryPassword.svg';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationError';
import { updatePassword } from '../../services/firstPassword';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { user, updateUser } = useAuth();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string()
            .required('Senha obrigatória')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
              'Sua senha precisa conter 6 caracteres e pelo menos uma letra maiuscula, um numero e um simbolo',
            ),
          password_confirmation: Yup.string()
            .required('Confirmacao obrigatória')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
              'Sua senha precisa conter 6 caracteres e pelo menos uma letra maiuscula, um numero e um simbolo',
            )
            .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.password !== data.password_confirmation) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao alterar senha.',
            description: 'A senha e a confirmacão de senha estão diferentes.',
          });
          return;
        }

        if (data.password.length < 6) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao alterar senha.',
            description: 'A senha deve ter pelo menos 6 caracteres.',
          });
          return;
        }

        // const token = location.search.replace('?token=', '');

        // if (!token) {
        //   throw new Error();
        // }

        const resultUpdatePassword = await updatePassword({
          new_password: data.password_confirmation,
          student_id: user.id,
          first_access: 1,
        });

        if (resultUpdatePassword) {
          addToast({
            type: 'success',
            title: 'Senha alterada com sucesso.',
            description: 'Sua senha foi alterada com sucesso!',
          });
          updateUser(resultUpdatePassword);
          setLoading(false);
          formRef.current?.reset();
          setTimeout(() => history.push('/'), 3000);
        } else {
          addToast({
            type: 'error',
            title: 'Oops. Tivemos um problema.',
            description:
              'Não foi possível atualizar sua senha. Tente novamente!',
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao alterar senha.',
          description:
            'Ocorreu um erro ao fazer a alteração, cheque as credenciais.',
        });
      }
    },
    [addToast, history, updateUser, user.id],
  );

  return (
    <Main>
      <Container>
        {/* <div style={{ fontSize: 40 }}>
          <Text
            style={{
              fontSize: 82,
              fontWeight: 200,
              color: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            UNI
            <Text style={{ fontWeight: 700, color: '#FFF' }}>CFC</Text>
          </Text>
        </div> */}
        <Content>
          <ForgotContainer ref={formRef} onSubmit={handleSubmit}>
            <img src={ForgotPasswordImage} alt="Esqueceu sua senha ?" />

            <ForgotContent>
              <Text color="#FF5722" fontSize={20} fontWeight="bold">
                Crie uma nova senha
              </Text>

              <Input
                name="password"
                type="password"
                placeholder="Digite sua nova senha"
              />

              <Input
                name="password_confirmation"
                type="password"
                placeholder="Digite novamente"
              />
            </ForgotContent>

            <span>
              Sua senha deve conter pelo menos 6 caracteres. <br /> Sua senha
              deve conter uma letra maiúscula, um número e um símbolo.
            </span>

            <Button loading={loading} type="submit">
              Trocar minha senha
            </Button>
          </ForgotContainer>
        </Content>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 40,
            letterSpacing: '-0.01em',
            color: '#FFF',
            fontWeight: 300,
          }}
        >
          <Text>Plataforma de</Text>
          <Text>Ensino a Distância</Text>
        </div> */}
      </Container>

      {/* <Text
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 12,
          color: '#FFF',
          marginBottom: 45,
        }}
      >
        ©2021 UNICFC S/A - Todos os direitos reservados
      </Text> */}
    </Main>
  );
};

export default ResetPassword;
