/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Image from '../../assets/images/leasson.svg';

import './leassonSelected.css';

const LeassonSelectedComponent = ({
  action,
  content,
  courseDetails,
  status,
}) => {
  const indexLeasson = content.currentIndex + 1;

  const ButtonType = () => {
    switch (status) {
      case 'oneHour':
      case 'progress':
        return (
          <Col md="4" className="leasson-in-progress">
            <button type="button" onClick={() => action(true)}>
              sair da aula
            </button>
            <button
              type="button"
              onClick={() => window.open(content.video_url)}
            >
              Abrir aplicativo
            </button>
          </Col>
        );
      default:
        return (
          <Col md="4" className="leasson-finished">
            <span>Aula Finalizada</span>
            <button type="button">
              Finalizar e sair
              {/* <Link to="/liveclass/123">Finalizar e sair</Link> */}
            </button>
          </Col>
        );
    }
  };

  return (
    <section className="Leasson">
      <div className="LeassonContainer">
        <div className="LeassonContent">
          <Row>
            <Col md="3">
              <img src={Image} alt="Primeira Habilitação" />
            </Col>
            <Col md="5" className="LeassonDetails">
              <Row>
                <Col md="12">
                  <h1>{courseDetails.course_name}</h1>
                </Col>
                <Col md="12">
                  <b>
                    Aula {indexLeasson < 10 ? `0${indexLeasson}` : indexLeasson}
                  </b>{' '}
                  - {content.title}
                </Col>
                <Col md="12">
                  Nesta aula vamos aprender sobre as principais regras de
                  transito, placas importantes e cuidados extras.
                </Col>
              </Row>
            </Col>
            <ButtonType />
          </Row>
          <div>
            <div />
          </div>

          <div />
        </div>
      </div>
    </section>
  );
};

export default LeassonSelectedComponent;
