import React from 'react';
import Logo from '../../assets/logos/UNICFC_LOADING.svg';
import LoadingContainer from './styles';

const Loading = (props) => {
  return (
    <LoadingContainer>
      {/* <div className="container-loading" /> */}
      <div id="breathing-button">
        <img src={Logo} />
      </div>
    </LoadingContainer>
  );
};

export default Loading;
