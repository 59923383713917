import axios from 'axios';

const userLocale = localStorage.getItem('@UniCFC:locale');

const options = {
  headers: {
    'Application-Locale': userLocale,
  },
};

export const getCourses = async () => {
  const response = await axios.get(
    'https://kl3yts2u6f.execute-api.us-east-2.amazonaws.com/dev/',
  );

  const { data } = response;
  return data;
};

export const getLeassons = async (
  matriculation_id: string,
  classroom_id: string,
) => {
  const response = await axios.get(
    `https://sxiaxe76k3.execute-api.us-east-2.amazonaws.com/dev/matriculations/${matriculation_id}/classrooms/${classroom_id}`,
  );

  const { data } = response;
  return data;
};

export const getMatriculations = async () => {
  const userData: string = JSON.parse(
    localStorage.getItem('@UniCFC:user') || '',
  );
  const response = await axios.get(
    `https://38k1ieqkbe.execute-api.us-east-2.amazonaws.com/dev/${userData.id}`,
  );

  const { data } = response;
  return data;
};

export const getStudentClassroom = async (student_id: string) => {
  const response = await axios.get(
    `https://5sktb50fh5.execute-api.us-east-2.amazonaws.com/dev/${student_id}`,
  );

  const { data } = response;
  return data;
};

export const getEadDetail = async (
  matriculation_id: string,
  course_id: string,
) => {
  const response = await axios.get(
    `https://i6yylfrqt4.execute-api.us-east-2.amazonaws.com/dev/matriculations/${matriculation_id}/courses/${course_id}`,
  );

  const { data } = response;
  return data;
};

export const getEadUnit = async (
  matriculation_id: string,
  course_id: string,
  moduluse_id: string,
  unit_id: string,
) => {
  const response = await axios.get(
    `https://y380xxyih8.execute-api.us-east-2.amazonaws.com/dev/matriculations/${matriculation_id}/courses/${course_id}/modulos/${moduluse_id}/units/${unit_id}`,
  );

  const { data } = response;
  return data;
};

export const getAnnotations = async () => {
  const userData: string = JSON.parse(
    localStorage.getItem('@UniCFC:user') || '',
  );
  const response = await axios.get(
    `https://nz6qt20hec.execute-api.us-east-2.amazonaws.com/dev/${userData.id}`,
  );

  const { data } = response;
  return data;
};

export const deleteAnnotations = async (payload) => {
  const response = await axios.post(
    ` https://13sntmpi8i.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const getNotices = async () => {
  const response = await axios.get(
    `https://z0nmx78ryk.execute-api.us-east-2.amazonaws.com/dev/`,
    options,
  );

  const { data } = response;
  return data;
};

export const justifyLeasson = async (payload) => {
  const response = await axios.post(
    `https://6mktjd0fee.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const makeStudyUnit = async (payload) => {
  const response = await axios.post(
    `https://gphgte36e0.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const getExam = async (payload) => {
  const response = await axios.post(
    `https://royklh1km2.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const saveExam = async (payload) => {
  const response = await axios.post(
    `https://evj0csiei2.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const getModuleExam = async (payload) => {
  const response = await axios.post(
    `https://lxqtwct9ue.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};

export const saveModuleExam = async (payload) => {
  const response = await axios.post(
    `https://llu9s8uzgc.execute-api.us-east-2.amazonaws.com/dev/`,
    payload,
  );

  const { data } = response;
  return data;
};
