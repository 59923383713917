/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Image from '../../assets/images/course.svg';
import ClockIcon from '../../assets/icons/clockIcon.svg';
import DownArrow from '../icons/DownArrow';
import { getStudentClassroom } from '../../services/courses';
import Timer from '../CountdownV2';
import './course.css';
import { useApp } from '../../hooks/app';
import Button from '../Button';

const CourseCardComponent = ({ content }) => {
  const { currentLeasson, setCurrentLeasson, setCurrentCourse } = useApp();
  const [IsExpand, setIsExpand] = useState(false);
  const [leassons, setLeassons] = useState(false);

  useEffect(() => {
    const leassonsService = async () => {
      if (leassons === false) {
        const result = await getStudentClassroom(content.student_id);
        setLeassons(result);
      }
    };
    leassonsService();
  }, [leassons, content.course_id, content.student_id]);

  useEffect(() => {
    const getCurrentLeasson = () => {
      let leasson = leassons.find((item, index) => {
        const defineTime = () => {
          const now = moment(new Date());
          const start = moment(item.init, 'YYYY/MM/DD HH:mm:ss');
          const differenceTime = start.diff(now, 'minutes');
          item.minutesToStart = differenceTime;
          item.currentIndex = index;
          return true;
        };
        if (item.status === 'ready') return defineTime();
        if (item.status === 'pending') return defineTime();
      });
      if (!leasson) leasson = leassons[leassons.length - 1];
      setCurrentLeasson(leasson);
      setCurrentCourse(content);
    };
    leassons && getCurrentLeasson();
  }, [currentLeasson, leassons, setCurrentLeasson, setCurrentCourse, content]);

  const handleExpand = () => {
    setIsExpand(!IsExpand);

    const el = document.getElementById('dropdownButton');

    if (!IsExpand === true) {
      el?.classList.add('dropdownContainerExpand');
      return;
    }

    el?.classList.remove('dropdownContainerExpand');
  };

  const parseStatus = {
    finished: 'Concluida',
    closed: 'Encerrada',
    pending: 'Pendente',
    '': 'Em Breve',
  };

  const parseStatusCSS = {
    finished: '#3EC1B1',
    ready: '#1976D2',
    pending: '#ffc107',
    closed: '#FF5722',
  };

  const CourseTable = () => {
    return (
      <div className="coursesTableSection">
        {leassons &&
          leassons.map((item, index) => {
            const [indexLesson, title] = item.title.split('-');
            return (
              <div className="coursesTableContainer">
                <div>
                  <b>{indexLesson}</b> - {title}
                </div>
                <div>
                  {moment(item.init, 'YYYY/MM/DD HH:mm:ss').format('HH:mm')} às{' '}
                  {moment(item.end, 'YYYY/MM/DD HH:mm:ss').format('HH:mm')}
                </div>
                <div style={{ color: parseStatusCSS[item.status] }}>
                  {parseStatus[item.status]}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const TimerType = () => {
    let status;
    if (currentLeasson.minutesToStart > 60) status = 'inTime';
    else if (
      currentLeasson.minutesToStart > 0 &&
      currentLeasson.minutesToStart < 60
    )
      status = 'oneHour';
    else if (currentLeasson.minutesToStart < 0) status = 'started';

    switch (status) {
      case 'inTime':
        return (
          <Col md="12" className="timer-type-in-Time">
            <h4>Próxima aula</h4>
            <h4>
              Dia{' '}
              {moment(currentLeasson.init, 'YYYY/MM/DD HH:mm:ss').format(
                'DD/MM/YYYY - HH:mm',
              )}{' '}
              às{' '}
              {moment(currentLeasson.end, 'YYYY/MM/DD HH:mm:ss').format(
                'HH:mm',
              )}
            </h4>
          </Col>
        );
      case 'oneHour':
        return (
          <Col md="12" className="timer-type-one-hour">
            <img src={ClockIcon} alt="Começando em horas" />
            <span>
              Sua aula começa em:
              <Timer initialMinute={currentLeasson.minutesToStart} />
            </span>
          </Col>
        );
      case 'started':
        return (
          <Col md="12" className="timer-type-started">
            {/* <AlertIcon /> */}
            <FaExclamationTriangle color="#FF5722" size={29} />
            <span>Sua aula já começou</span>
          </Col>
        );
      default:
        return (
          <Col md="12" className="timer-type-started">
            {/* <AlertIcon /> */}
            <FaExclamationTriangle color="#FF5722" size={29} />
            <span>Aulas Encerradas</span>
          </Col>
        );
    }
  };

  const ButtonType = () => {
    let status;
    if (currentLeasson.minutesToStart > 60) status = 'inTime';
    else if (
      currentLeasson.minutesToStart > 0 &&
      currentLeasson.minutesToStart < 60
    )
      status = 'oneHour';
    else if (currentLeasson.minutesToStart < 0) status = 'started';

    switch (status) {
      case 'inTime':
        return (
          <Col md="4" className="btn-in-time">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
            <Button color="#FFF" backgroundColor="#ADC3D9">
              Definir Lembrete
            </Button>
          </Col>
        );
      case 'oneHour':
        return (
          <Col md="4" className="btn-one-hour">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
            <Link to="/course/liveclass">
              <Button
                hoverColor="linear-gradient(117.12deg, #1976D2 53.53%, #FDFEFF 112.46%)"
                color="#FFF"
                backgroundColor="#ADC3D9"
              >
                Assistir aula
              </Button>
            </Link>
          </Col>
        );
      case 'started':
        return (
          <Col md="4" className="btn-one-hour">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
            <Link to="/course/liveclass">
              <Button
                hoverColor="linear-gradient(117.12deg, #1976D2 53.53%, #FDFEFF 112.46%)"
                color="#FFF"
                backgroundColor="#ADC3D9"
              >
                Assistir aula
              </Button>
            </Link>
          </Col>
        );
      default:
        return (
          <Col md="4" className="btn-one-hour">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
          </Col>
        );
    }
  };

  return (
    currentLeasson && (
      <section className="Course">
        <div className="CourseContainer">
          <div className="CourseContent">
            <Row>
              <Col md="3">
                <img src={Image} alt="Primeira Habilitação" />
              </Col>
              <Col md="5" className="CourseDetails">
                <Row>
                  <Col md="12">
                    <h1>{content.course_name}</h1>
                  </Col>
                  <Col md="12">
                    <b>
                      Próxima aula
                      {/* Aula {currentLeasson && currentLeasson.currentIndex + 1} */}
                    </b>{' '}
                    - {currentLeasson && currentLeasson.title}
                  </Col>
                  <TimerType status="inTime" />
                </Row>
              </Col>
              <ButtonType status="inTime" />
            </Row>
            <div>
              <div />
            </div>

            <div />
          </div>
          {IsExpand && <CourseTable />}
        </div>

        <div className="dropdownContainer" id="dropdownButton">
          <button
            type="button"
            onClick={() => {
              handleExpand();
            }}
          >
            <span>Ver aulas</span>
            <DownArrow />
          </button>
        </div>
      </section>
    )
  );
};

export default CourseCardComponent;
