import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { CardContainer } from './styles';

interface AskCardProp {
  content: any;
  result: any;
}

const AskCard: React.FC<AskCardProp> = ({ content, result }) => {
  const parseAlternative = ['A', 'B', 'C', 'D', 'E', 'F'];

  return (
    <CardContainer>
      <Row>
        <Col md="12">
          <Card className="class-card">
            <Card.Body className="class-body">
              <Row>
                <Col md="10">
                  <Card.Title className="class-title">
                    <p>{content.name}</p>
                  </Card.Title>
                </Col>
                <Col className="class-cod" md="2">
                  <p>
                    <strong>Cód:</strong>
                    {content.question_code}
                  </p>
                </Col>
                {content.question_alternatives.map((question, index) => {
                  return (
                    <Col className="class-answers" md="12">
                      <div>
                        <strong> {parseAlternative[index]}- </strong>
                        {question.title}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Card.Body>
            <div
              className={`class-notification class-notification-${
                result.correct === 1 ? 'success' : 'wrong'
              }`}
            >
              {result.correct === 1
                ? 'Muito Bem! Você acertou essa questão.'
                : 'Oops! Você errou essa questão.'}
            </div>
            <div className="class-second-card">
              <div
                className="class-first-letters"
                /* eslint-disable-next-line */
                dangerouslySetInnerHTML={{ __html: content.rectification }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default AskCard;
