import React, { useState } from 'react';

import { Row, Col, Form } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { JustificationScreen } from './styles';
import Button from '../Button';

interface JustificationModalProps {
  setShowJustification: any;
  handleJustification: any;
}

const JustificationModal: React.FC<JustificationModalProps> = ({
  setShowJustification,
  handleJustification,
}) => {
  const [justificationText, setJustificationText] = useState('');

  return (
    <JustificationScreen>
      <div className="justification-background" />
      <div className="justification-container">
        <button
          type="button"
          className="close-btn"
          onClick={() => setShowJustification(false)}
        >
          X
        </button>
        <Row className="icon-container">
          <Col md="2">
            <FaExclamationTriangle />
          </Col>
          <Col md="10">
            <h3>Sair da aula contará como falta, lembre-se disso.</h3>
          </Col>
        </Row>
        <Row className="timer-container">
          <Col md="9">Digite sua justificativa no campo abaixo:</Col>
          <Col md="3">
            <div>
              {/* <span>Duração da aula</span>
              <span>
                <Countdown />
              </span> */}
            </div>
          </Col>
        </Row>
        <Row className="form-container">
          <Col md="12">
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  className="text-area"
                  as="textarea"
                  rows={5}
                  onChange={(e) => setJustificationText(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Link to="/dashboard">
          {' '}
          <Button
            type="button"
            onClick={() => {
              window.confirm('Deseja mesmo sair da aula?') /* eslint-disable-line */
                ? handleJustification(justificationText)
                : null;
            }}
            style={{
              background: '#FF5722',
              color: '#fff',
            }}
          >
            <span>Sair da aula</span>
          </Button>
        </Link>
      </div>
    </JustificationScreen>
  );
};

export default JustificationModal;
