import React from 'react';

interface SvgIcon {
  color?: string;
}

const AddIcon: React.FC<SvgIcon> = ({ color = '#1976D2' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 -1.02779e-06 16 -1.39876e-06C11.7565 -1.76974e-06 7.68688 1.68571 4.68629 4.68629C1.68571 7.68687 1.76974e-06 11.7565 1.39876e-06 16C1.02779e-06 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32V32ZM9 17C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 8.10536 15.4804 8.29289 15.2929C8.48043 15.1054 8.73478 15 9 15L20.586 15L16.292 10.708C16.199 10.615 16.1253 10.5046 16.075 10.3832C16.0246 10.2617 15.9987 10.1315 15.9987 10C15.9987 9.86851 16.0246 9.73831 16.075 9.61683C16.1253 9.49535 16.199 9.38497 16.292 9.292C16.385 9.19902 16.4954 9.12527 16.6168 9.07495C16.7383 9.02463 16.8685 8.99874 17 8.99874C17.1315 8.99874 17.2617 9.02463 17.3832 9.07495C17.5046 9.12527 17.615 9.19902 17.708 9.292L23.708 15.292C23.8011 15.3849 23.875 15.4952 23.9254 15.6167C23.9758 15.7382 24.0018 15.8685 24.0018 16C24.0018 16.1315 23.9758 16.2618 23.9254 16.3833C23.875 16.5048 23.8011 16.6151 23.708 16.708L17.708 22.708C17.5202 22.8958 17.2656 23.0013 17 23.0013C16.7344 23.0013 16.4798 22.8958 16.292 22.708C16.1042 22.5202 15.9987 22.2656 15.9987 22C15.9987 21.7344 16.1042 21.4798 16.292 21.292L20.586 17L9 17Z"
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
