import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import NoteCard from '../../components/NoteCards';
import useAuth from '../../hooks/auth';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NoteStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';
import { getAnnotations } from '../../services/courses';
import Loading from '../../components/Loading';

const Annotation: React.FC = () => {
  const { user } = useContext(useAuth);
  const [annotations, setAnnotations] = useState([]);

  useEffect(() => {
    const noteData = () => {
      getAnnotations().then((result) => {
        if (result) setAnnotations(result);
      });
    };
    noteData();
  }, [setAnnotations, user.id]);

  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowLayout(true), 1000);
  });

  return (
    <Content>
      {!showLayout && <Loading />}
      <Main>
        <Container>
          <NextClassContainer>
            <span>Anotações</span>
            <NoteStyled>
              {!isEmpty(annotations) ? (
                <Row>
                  {annotations.map((annotation) => (
                    <Col md="6">
                      <NoteCard key={annotation?.id} content={annotation} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="is-empty">
                  <span>Não há anotações no momento.</span>
                </div>
              )}
            </NoteStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Annotation;
