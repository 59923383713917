import React from 'react';

import Text from '../Text';
import NotificationIcon from '../icons/NotificationIcon';

import {
  Header,
  UserDataHeader,
  UserPerfilImage,
  DefaultUserAvatar,
} from './styles';
import { useAuth } from '../../hooks/auth';
import defaultUser from '../../assets/images/user.png';

const HeaderComponent: React.FC = ({ children }) => {
  const { user } = useAuth();
  const redirecToMap = () => {
    window.location.href = '/notices';
  };

  return (
    <Header>
      {children}
      <Text
        fontSize={16}
        fontWeight="bold"
        color="#1976D2"
        style={{ marginLeft: '100px' }}
        className="company-name"
      >
        {user ? user.training_center_name : 'Não disponivel'}
      </Text>
      <UserDataHeader>
        <button type="button" onClick={redirecToMap}>
          <NotificationIcon />
        </button>
        <Text fontSize={15} fontWeight="500" color="#373F49">
          {user.name}
        </Text>
        {!user.image_url ? (
          <DefaultUserAvatar src={defaultUser} alt="user default" />
        ) : (
          <UserPerfilImage
            src={`data:image/png;base64,${user.image_url}`}
            alt="UserName"
          />
        )}
      </UserDataHeader>
    </Header>
  );
};

export default HeaderComponent;
