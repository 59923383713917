import React, { useState } from 'react';
import { setCookie, getCookie } from '../../utils/cookies.manager';
import { BarContainer } from './styles';
import Button from '../Button';

const BarComponent: React.FC = () => {
  const [cookiesAccept, setCookiesAccept] = useState(
    !(getCookie('unicfcead_cookies') === ''),
  );

  const handleAcceptCookies = () => {
    setCookie('unicfcead_cookies', 'accept=true', 365);
    setCookiesAccept(true);
  };

  return cookiesAccept ? null : (
    <BarContainer>
      <p>
        Este site salva seu histórico de uso. Ao continuar navegando você
        concorda com a{' '}
        <a
          href="https://unicfcead.com.br/politicas-de-privacidade"
          target="_blank"
          rel="noreferrer"
        >
          politica de cookies e privacidade
        </a>
        .
      </p>
      <Button className="btn-accept" onClick={handleAcceptCookies}>
        Ok
      </Button>
    </BarContainer>
  );
};

export default BarComponent;
