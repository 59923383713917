import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  min-height: 90px;
  height: 50px;
  background: #ecf2f8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* /* position: fixed; */
  z-index: 13;
  @media (max-width: 750px) {
    > span {
      display: none;
    }
  }

  span {
    white-space: nowrap;
  }
`;

export const UserDataHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* max-width: 300px; */
  justify-content: flex-end;
  font-size: 16px;
  margin-right: 50px;

  button {
    margin-right: 15px;
    border: 0;
    background: transparent;
    svg {
      width: 25px;
    }
  }
`;

export const UserPerfilImage = styled.div`
  margin-left: 5%;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 650px) {
    display: none;
  }
`;
export const DefaultUserAvatar = styled.img`
  margin-left: 15px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 650px) {
    display: none;
  }
`;
