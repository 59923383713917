import styled from 'styled-components';

const HandoutCardStyle = styled.div`
  border-radius: 30px;
  -webkit-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  -moz-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);

  > div {
    padding: 30px;
    margin-bottom: 30px;
    /* height: 400px; */
    > div:first-child {
      text-align: center;
      height: 200px !important;
      img {
        height: 200px !important;
      }
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      h5 {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #373f49;
      }
      h3 {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #373f49;
      }
      b {
        div {
          height: 50px;
          p {
            text-align: center;
          }
        }
        flex-grow: 1;
      }
      button {
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;

export default HandoutCardStyle;
