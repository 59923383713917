import React from 'react';

import { Row, Col } from 'react-bootstrap';
import HandoutCardStyle from './styles';
import Button from '../Button';
import DownloadIcon from '../icons/Download';

const HandoutCard = ({ content }) => {
  return (
    <HandoutCardStyle>
      <Row>
        <Col md="12">
          <img src={content.image_url} alt={content.slug} />
        </Col>
        <Col md="12">
          <h5>{content.title}</h5>
          <b>
            <div
              className="class-description"
              /* eslint-disable react/no-danger */
              dangerouslySetInnerHTML={{ __html: content.description }}
            />
          </b>
          <Button
            hoverOff
            outerline
            color="#1976D2"
            onClick={() => {
              window.open(
                content.files && content.files[0] && content.files[0].file_url,
                '_self',
              );
            }}
            backgroundColor="#1976D2"
          >
            {content.files && content.files[0] ? 'Download' : 'Indisponivel'}
            {content.files && content.files[0] && (
              <DownloadIcon color="#1976D2" />
            )}
          </Button>
        </Col>
      </Row>
    </HandoutCardStyle>
  );
};

export default HandoutCard;
