import styled from 'styled-components';
// import { shade } from 'polished';

interface ButtonInterface {
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  outerline?: boolean;
  hoverOff?: boolean;
  width?: string;
  height?: string;
  borderColor?: string;
}

export const Container = styled.button<ButtonInterface>`
  background: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.outerline === true
      ? 'transparent'
      : props.backgroundColor
      ? props.backgroundColor
      : '#3EC1B1'};
  height: ${(props) => (props.height ? props.height : '48px')};
  border-radius: 6px;
  border-color: ${(props) => (props.borderColor ? props.borderColor : '#000')};
  border: ${(props) =>
    props.outerline === true
      ? `1px ${props.backgroundColor ? props.backgroundColor : '#3EC1B1'} solid`
      : 0};
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.outerline === true
      ? props.color
        ? props.color
        : '#3EC1B1'
      : props.color
      ? props.color
      : '#FFF'};
  width: ${(props) => (props.width ? props.width : '100%')};
  font-weight: 600;
  margin: 8px 0;
  font-size: 16px;
  padding: 10px;
  transition: background 0.2s;

  &:hover {
    background: linear-gradient(117.12deg, #ff5722 53.53%, #fdfeff 112.46%);
    background: ${(props) =>
      !props.hoverOff
        ? `${props.hoverColor ? props.hoverColor : '#3EC1B1'}`
        : 'transparent'};
  }
`;
