import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import ExamCard from '../../components/ExamCard';
import ResultCard from '../../components/ResultCard';
import Button from '../../components/Button';
import Loading from '../../components/Loading/index';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  ResultStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';
import { useApp } from '../../hooks/app';

const Result: React.FC = () => {
  const {
    currentUnitExamResult,
    currentUnitExamContent,
    setShowCongratulationModal,
  } = useApp();

  return (
    <Content>
      {currentUnitExamResult &&
      currentUnitExamResult[
        `${
          currentUnitExamContent.type === 'module' ? 'evaluation' : 'simulate'
        }_questions`
      ] ? (
        <Main>
          <Container>
            <NextClassContainer>
              <span>Cursos - CFC</span>
              <ResultStyled>
                <Row>
                  <Col md="12">
                    <ExamCard
                      title="asdadads"
                      description="dasdadsasd"
                      date="10/02/2021"
                    />
                  </Col>

                  <Row>
                    {currentUnitExamResult.finalized === 1 ? (
                      <Col className="class-attention-positive" md="12">
                        <p>Parabéns! Acertou tudo.</p>
                      </Col>
                    ) : (
                      <Col className="class-attention-negative" md="12">
                        <p>Não foi dessa vez, vamos refazer?</p>
                      </Col>
                    )}
                  </Row>
                </Row>
                {currentUnitExamResult[
                  `${
                    currentUnitExamContent.type === 'module'
                      ? 'evaluation'
                      : 'simulate'
                  }_questions`
                ].map((value: any, index: number) => {
                  return (
                    <Row>
                      <Col md="12">
                        <ResultCard
                          result={value}
                          content={currentUnitExamContent.content[index]}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Row>
                  {currentUnitExamResult.finalized === 1 ? (
                    <Col className="class-result-positive" md="12">
                      <Row>
                        <Col md="9">
                          <p>Parabéns, você acertou todas as questões!</p>
                          <p>Continue seus estudos do módulo.</p>
                        </Col>
                        <Col md="3">
                          <Col>
                            <Button
                              hoverColor="linear-gradient(117.12deg, #3ec1b1 53.53%, #FDFEFF 112.46%)"
                              color="#FFF"
                              backgroundColor="#3ec1b1"
                              onClick={() => {
                                setShowCongratulationModal({
                                  type: 'unit',
                                  show: true,
                                });
                              }}
                            >
                              Continuar Estudos
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col className="class-result-negative" md="12">
                      <Row>
                        <Col md="9">
                          <p>
                            Oops, infelizmente você não acertou todas as
                            questões da avaliação.
                          </p>
                          <p>
                            Você pode realizar novamente a avaliação desta
                            unidade.
                          </p>
                        </Col>
                        <Col md="3">
                          <Col>
                            <Link to="/course/ead/content/exam">
                              <Button
                                hoverColor="linear-gradient(117.12deg, #ff5722 53.53%, #FDFEFF 112.46%)"
                                color="#FFF"
                                backgroundColor="#ff5722"
                              >
                                Tentar Novamente
                              </Button>
                            </Link>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </ResultStyled>
            </NextClassContainer>
          </Container>
        </Main>
      ) : (
        <Loading />
      )}
    </Content>
  );
};

export default Result;
