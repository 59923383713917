import React from 'react';

interface SvgIcon {
  color?: string;
}

const LeftArrowIcon: React.FC<SvgIcon> = ({ color = 'white' }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20005 2.00088L3.20005 6.00088L7.20005 10.0009L6.40005 11.6009L0.800049 6.00088L6.40005 0.400879L7.20005 2.00088Z"
        fill={color}
      />
    </svg>
  );
};

export default LeftArrowIcon;
