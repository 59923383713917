import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Text from '../../components/Text';
import Checkbox from '../../components/Checkbox';

import { useAuth } from '../../hooks/auth';

import './index.css';
import { useToast } from '../../hooks/toast';
import { accepTerms } from '../../services/terms';

const Terms: React.FC = () => {
  const [selectedRemember, setSelectedRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const history = useHistory();

  const { user, signOut } = useAuth();

  useEffect(() => {
    if (user.accept_terms === 1) {
      history.push('/');
    }
  }, [history, user.accept_terms]);

  const removeErrors = () => {
    const textSelect = document.getElementById('textAccept');
    const checkboxSelect = document.getElementById('checkboxSelectAccept');
    if (textSelect && checkboxSelect) {
      textSelect.style.color = '#434D59';
      textSelect.style.fontWeight = 'normal';
      checkboxSelect.style.border = 'none';
    }
  };

  const acceptTerms = useCallback(async () => {
    if (selectedRemember) {
      setLoading(true);
      const accepted = await accepTerms();
      if (accepted.status === 200) {
        if (user.first_access) history.push('/change-password');
        else history.push('/');
      }

      setLoading(false);
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao prosseguir',
        description: 'Você precisa concordar com os termos para aceitar.',
      });

      const textSelect = document.getElementById('textAccept');
      const checkboxSelect = document.getElementById('checkboxSelectAccept');
      if (textSelect && checkboxSelect) {
        textSelect.style.color = 'red';
        textSelect.style.fontWeight = 'bold';
        checkboxSelect.style.border = '1px solid red';
      }
    }
  }, [addToast, history, selectedRemember]);  /* eslint-disable-line */

  return (
    <div className="TermsSection">
      <div className="TermsContent">
        <div className="TermsMain">
          <div>
            <div className="SectionTermsContainer">
              <div>
                <span style={{ fontSize: 22 }}>
                  <Text
                    style={{
                      fontWeight: 200,
                      color: '#A5BBC9',
                    }}
                  >
                    UNI
                  </Text>
                  <Text style={{ fontWeight: 700, color: '#1976d2' }}>CFC</Text>
                </span>
              </div>
              <Text fontSize={40} fontWeight="700">
                Termos de serviço
              </Text>

              <div className="termsContainer">
                <div>
                  <p>
                    <strong>Termos e Condi&ccedil;&otilde;es de Uso</strong>
                  </p>

                  <p>
                    Para continuar usando nosso site, voc&ecirc; deve concordar
                    com nossos Termos e Condi&ccedil;&otilde;es de Uso
                  </p>

                  <p>Pol&iacute;ticas de Privacidade</p>

                  <p>
                    A sua privacidade &eacute; importante para n&oacute;s.
                    &Eacute; pol&iacute;tica do UNICFC EAD respeitar a sua
                    privacidade em rela&ccedil;&atilde;o a qualquer
                    informa&ccedil;&atilde;o sua que possamos coletar no site
                    UNICFC EAD, e outros sites que possu&iacute;mos e operamos.
                  </p>

                  <p>
                    Solicitamos informa&ccedil;&otilde;es pessoais apenas quando
                    realmente precisamos delas para lhe fornecer um
                    servi&ccedil;o. Fazemo-lo por meios justos e legais, com o
                    seu conhecimento e consentimento. Tamb&eacute;m informamos
                    por que estamos coletando e como ser&aacute; usado.
                    &nbsp;&nbsp;
                  </p>

                  <p>
                    Apenas retemos as informa&ccedil;&otilde;es coletadas pelo
                    tempo necess&aacute;rio para fornecer o servi&ccedil;o
                    solicitado. Quando armazenamos dados, protegemos dentro de
                    meios comercialmente aceit&aacute;veis para evitar perdas e
                    roubos, bem como acesso, divulga&ccedil;&atilde;o,
                    c&oacute;pia, uso ou modifica&ccedil;&atilde;o n&atilde;o
                    autorizados. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>

                  <p>
                    N&atilde;o compartilhamos informa&ccedil;&otilde;es de
                    identifica&ccedil;&atilde;o pessoal publicamente ou com
                    terceiros, exceto quando exigido por lei. &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>

                  <p>
                    O nosso site pode ter links para sites externos que
                    n&atilde;o s&atilde;o operados por n&oacute;s. Esteja ciente
                    de que n&atilde;o temos controle sobre o conte&uacute;do e
                    pr&aacute;ticas desses sites e n&atilde;o podemos aceitar
                    &nbsp; &nbsp; responsabilidade por suas respectivas
                    pol&iacute;ticas de privacidade. &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>

                  <p>
                    Voc&ecirc; &eacute; livre para recusar a nossa
                    solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es
                    pessoais, entendendo que talvez n&atilde;o possamos fornecer
                    alguns dos servi&ccedil;os desejados. &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>

                  <p>
                    O uso continuado de nosso site ser&aacute; considerado como
                    aceita&ccedil;&atilde;o de nossas pr&aacute;ticas em torno
                    de privacidade e informa&ccedil;&otilde;es pessoais. Se
                    voc&ecirc; tiver alguma d&uacute;vida sobre como lidamos com
                    dados do usu&aacute;rio e informa&ccedil;&otilde;es
                    pessoais, entre em contato conosco.
                  </p>

                  <p>Pol&iacute;ticas de Cookies UNICFC EAD</p>

                  <p>
                    O que s&atilde;o cookies?
                    <br />
                    Como &eacute; pr&aacute;tica comum em quase todos os sites
                    profissionais, este site usa cookies, que s&atilde;o
                    pequenos arquivos baixados no seu computador, para melhorar
                    sua experi&ecirc;ncia. Esta p&aacute;gina descreve quais
                    informa&ccedil;&otilde;es eles coletam, como as usamos e por
                    que &agrave;s vezes precisamos armazenar esses cookies.
                    Tamb&eacute;m compartilharemos como voc&ecirc; pode impedir
                    que esses cookies sejam armazenados, no entanto, isso pode
                    fazer o downgrade ou &#39;quebrar&#39; certos elementos da
                    funcionalidade do site.
                  </p>

                  <p>
                    Como usamos os cookies?
                    <br />
                    Utilizamos cookies por v&aacute;rios motivos, detalhados
                    abaixo. Infelizmente, na maioria dos casos, n&atilde;o
                    existem op&ccedil;&otilde;es padr&atilde;o do setor para
                    desativar os cookies sem desativar completamente a
                    funcionalidade e os recursos que eles adicionam a este site.
                    &Eacute; recomend&aacute;vel que voc&ecirc; deixe todos os
                    cookies se n&atilde;o tiver certeza se precisa ou n&atilde;o
                    deles, caso sejam usados para fornecer um servi&ccedil;o que
                    voc&ecirc; usa.
                  </p>

                  <p>
                    <br />
                    Desativar cookies
                    <br />
                    Voc&ecirc; pode impedir a configura&ccedil;&atilde;o de
                    cookies ajustando as configura&ccedil;&otilde;es do seu
                    navegador (consulte a Ajuda do navegador para saber como
                    fazer isso). Esteja ciente de que a
                    desativa&ccedil;&atilde;o de cookies afetar&aacute; a
                    funcionalidade deste e de muitos outros sites que voc&ecirc;
                    visita. A desativa&ccedil;&atilde;o de cookies geralmente
                    resultar&aacute; na desativa&ccedil;&atilde;o de
                    determinadas funcionalidades e recursos deste site.
                    Portanto, &eacute; recomend&aacute;vel que voc&ecirc;
                    n&atilde;o desative os cookies.
                  </p>

                  <p>Cookies que definimos</p>

                  <p>
                    Cookies relacionados &agrave; conta
                    <br />
                    Se voc&ecirc; criar uma conta conosco, usaremos cookies para
                    o gerenciamento do processo de inscri&ccedil;&atilde;o e
                    administra&ccedil;&atilde;o geral. Esses cookies geralmente
                    ser&atilde;o exclu&iacute;dos quando voc&ecirc; sair do
                    sistema, por&eacute;m, em alguns casos, eles poder&atilde;o
                    permanecer posteriormente para lembrar as prefer&ecirc;ncias
                    do seu site ao sair.
                  </p>

                  <p>
                    Utilizamos cookies quando voc&ecirc; est&aacute; logado,
                    para que possamos lembrar dessa a&ccedil;&atilde;o. Isso
                    evita que voc&ecirc; precise fazer login sempre que visitar
                    uma nova p&aacute;gina. Esses cookies s&atilde;o normalmente
                    removidos ou limpos quando voc&ecirc; efetua logout para
                    garantir que voc&ecirc; possa acessar apenas a recursos e
                    &aacute;reas restritas ao efetuar login.
                  </p>

                  <p>Cookies relacionados a boletins por e-mail</p>

                  <p>
                    Este site oferece servi&ccedil;os de assinatura de boletim
                    informativo ou e-mail e os cookies podem ser usados para
                    lembrar se voc&ecirc; j&aacute; est&aacute; registrado e se
                    deve mostrar determinadas notifica&ccedil;&otilde;es
                    v&aacute;lidas apenas para usu&aacute;rios inscritos /
                    n&atilde;o inscritos.
                  </p>

                  <p>
                    Pedidos processando cookies relacionados
                    <br />
                    Este site oferece facilidades de com&eacute;rcio
                    eletr&ocirc;nico ou pagamento e alguns cookies s&atilde;o
                    essenciais para garantir que seu pedido seja lembrado entre
                    as p&aacute;ginas, para que possamos process&aacute;-lo
                    adequadamente.&nbsp;
                  </p>

                  <p>
                    Cookies relacionados a pesquisas&nbsp;
                    <br />
                    Este site oferece facilidades de com&eacute;rcio
                    eletr&ocirc;nico ou pagamento e alguns cookies s&atilde;o
                    essenciais para garantir que seu pedido seja lembrado entre
                    as p&aacute;ginas, para que possamos process&aacute;-lo
                    adequadamente.
                  </p>

                  <p>
                    <br />
                    Cookies relacionados a formul&aacute;rios&nbsp;
                    <br />
                    Quando voc&ecirc; envia dados por meio de um
                    formul&aacute;rio como os encontrados nas p&aacute;ginas de
                    contacto ou nos formul&aacute;rios de coment&aacute;rios, os
                    cookies podem ser configurados para lembrar os detalhes do
                    usu&aacute;rio para correspond&ecirc;ncia futura.&nbsp;
                  </p>

                  <p>
                    Cookies relacionados a formul&aacute;rios&nbsp;
                    <br />
                    Para proporcionar uma &oacute;tima experi&ecirc;ncia neste
                    site, fornecemos a funcionalidade para definir suas
                    prefer&ecirc;ncias de como esse site &eacute; executado
                    quando voc&ecirc; o usa. Para lembrar suas
                    prefer&ecirc;ncias, precisamos definir cookies para que
                    essas informa&ccedil;&otilde;es possam ser chamadas sempre
                    que voc&ecirc; interagir com uma p&aacute;gina for afetada
                    por suas prefer&ecirc;ncias.
                  </p>

                  <p>
                    Cookies de Terceiros
                    <br />
                    Em alguns casos especiais, tamb&eacute;m usamos cookies
                    fornecidos por terceiros confi&aacute;veis. A
                    se&ccedil;&atilde;o a seguir detalha quais cookies de
                    terceiros voc&ecirc; pode encontrar atrav&eacute;s deste
                    site. Este site usa o Google Analytics , que &eacute; uma
                    das solu&ccedil;&otilde;es de an&aacute;lise mais difundidas
                    e confi&aacute;veis da Web, para nos ajudar a entender como
                    voc&ecirc; usa o site e como podemos melhorar sua
                    experi&ecirc;ncia. Esses cookies podem rastrear itens como
                    quanto tempo voc&ecirc; gasta no site e as p&aacute;ginas
                    visitadas, para que possamos continuar produzindo
                    conte&uacute;do atraente.
                  </p>

                  <p>
                    As an&aacute;lises de terceiros s&atilde;o usadas para
                    rastrear e medir o uso deste site, para que possamos
                    continuar produzindo conte&uacute;do atrativo. Esses cookies
                    podem rastrear itens como o tempo que voc&ecirc; passa no
                    site ou as p&aacute;ginas visitadas, o que nos ajuda a
                    entender como podemos melhorar o site para voc&ecirc;.
                  </p>

                  <p>
                    Periodicamente, testamos novos recursos e fazemos
                    altera&ccedil;&otilde;es subtis na maneira como o site se
                    apresenta. Quando ainda estamos testando novos recursos,
                    esses cookies podem ser usados para garantir que voc&ecirc;
                    receba uma experi&ecirc;ncia consistente enquanto estiver no
                    site, enquanto entendemos quais otimiza&ccedil;&otilde;es os
                    nossos usu&aacute;rios mais apreciam.
                  </p>

                  <p>
                    Periodicamente, testamos novos recursos e fazemos
                    altera&ccedil;&otilde;es subtis na maneira como o site se
                    apresenta. Quando ainda estamos testando novos recursos,
                    esses cookies podem ser usados para garantir que voc&ecirc;
                    receba uma experi&ecirc;ncia consistente enquanto estiver no
                    site, enquanto entendemos quais otimiza&ccedil;&otilde;es os
                    nossos usu&aacute;rios mais apreciam. &nbsp;
                  </p>

                  <p>
                    Mais informa&ccedil;&otilde;es
                    <br />
                    Esperemos que esteja esclarecido e, como mencionado
                    anteriormente, se houver algo que voc&ecirc; n&atilde;o tem
                    certeza se precisa ou n&atilde;o, geralmente &eacute; mais
                    seguro deixar os cookies ativados, caso interaja com um dos
                    recursos que voc&ecirc; usa em nosso site.
                  </p>

                  <p>
                    Esta pol&iacute;tica &eacute; efetiva a partir de
                    Abril/2020.
                  </p>

                  <p>
                    1 Para mais informa&ccedil;&otilde;es sobre cookies do
                    Google Analytics, consulte a p&aacute;gina oficial do Google
                    Analytics.
                    <br />
                    &nbsp;
                  </p>

                  <address>&nbsp;</address>
                </div>
              </div>

              <div id="textAccept" className="acceptContainer">
                <Checkbox
                  id="checkboxSelectAccept"
                  name="accept_terms"
                  isSelected={selectedRemember}
                  onClickCapture={() => {
                    removeErrors();
                  }}
                  onClick={() => {
                    setSelectedRemember(!selectedRemember);
                  }}
                />
                Eu li concordo com todos o termos citados à cima.
              </div>

              <div className="buttonsContainer">
                <Button
                  outerline
                  backgroundColor="#1976D2"
                  color="#1976D2"
                  hoverOff
                  style={{ width: 195 }}
                  onClick={() => {
                    signOut();
                  }}
                >
                  Recusar
                </Button>
                <Button
                  loading={loading}
                  type="button"
                  backgroundColor="#1976D2"
                  hoverColor="#0c6dce"
                  style={{ width: 195 }}
                  onClick={acceptTerms}
                >
                  Aceitar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
