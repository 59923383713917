import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0px auto;
  grid-template-rows: 90px auto;

  grid-template-areas:
    'ML MH'
    'ML CT'
    'ML FT';

  min-height: 100%;
  margin-bottom: -60px;
`;

export const Header = styled.div`
  grid-area: MH;
`;

export const MenuLateral = styled.div`
  grid-area: ML;
`;

export const Footer = styled.footer`
  display: flex;
  position: relative;
  /* margin-left: -250px; */
  bottom: 0px;
  color: #fff;
  font-weight: 300;

  justify-content: center;
  align-items: center;

  height: 80px;

  background: #0e4378;

  span {
    font-size: 12px;
    color: #fff;
  }
`;

// Layout
// MH - MainHeader
// ML - MenuLateral
// CT - Content
