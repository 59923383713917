import styled from 'styled-components';

interface CheckboxInput {
  isSelected: boolean;
}

export const CheckboxInput = styled.div<CheckboxInput>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
  background: ${(props) => (props.isSelected ? '#1976d2' : '#ECF2F8')};
`;
