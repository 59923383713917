import styled from 'styled-components';

export const BarContainer = styled.div`
  width: 100%;
  min-height: 50px;
  background: #e4e6e8;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 50px;
  position: fixed;
  z-index: 999999999999999999;

  .btn-accept {
    flex: 1;
  }

  p {
    flex: 6;
    a {
      text-decoration: none;
      color: #318be4;
    }
  }
`;
