import React from 'react';

interface SvgIcon {
  color?: string;
}

const DownloadIcon: React.FC<SvgIcon> = ({ color = 'grey' }) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9167 7.06885H12.1279V1.23551C12.1279 0.593848 11.6217 0.0688477 11.0029 0.0688477H6.50293C5.88418 0.0688477 5.37793 0.593848 5.37793 1.23551V7.06885H3.58918C2.58793 7.06885 2.08168 8.32885 2.79043 9.06385L7.95418 14.4188C8.39293 14.8738 9.10168 14.8738 9.54043 14.4188L14.7042 9.06385C15.4129 8.32885 14.9179 7.06885 13.9167 7.06885ZM0.87793 18.7355C0.87793 19.3772 1.38418 19.9022 2.00293 19.9022H15.5029C16.1217 19.9022 16.6279 19.3772 16.6279 18.7355C16.6279 18.0938 16.1217 17.5688 15.5029 17.5688H2.00293C1.38418 17.5688 0.87793 18.0938 0.87793 18.7355Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
