import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from '../pages/Signin';
import Forgot from '../pages/Forgot';
import Recovery from '../pages/Recovery';
import HelpCenter from '../pages/HelpCenter';

const AuthRoutes: React.FC = () => (
  <Switch>
    <Route path="/forgot" exact component={Forgot} />
    <Route path="/recovery" exact component={Recovery} />
    <Route path="/help" exact component={HelpCenter} />
    <Route path="/" component={SignIn} />
  </Switch>
);

export default AuthRoutes;
