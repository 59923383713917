import React from 'react';
import CheckCircleIcon from '../icons/CheckCircle';

// import { Row, Col } from 'react-bootstrap';
// import Image from '../../assets/images/leasson.svg';
import { TagContainer } from './styles';
// import checkIcon from '../../assets/images/checkCircle.svg';

interface EadCardComponentProp {
  module: any;
  setModuleSelected: any;
}

const parseStatus = {
  evaluation: 'blue',
  pending: 'white',
  progress: 'blue',
  finished: 'green',
};

const TagComponent: React.FC<EadCardComponentProp> = ({
  module,
  setModuleSelected,
}) => {
  const [moduleNumber, moduleName] = module.name?.split(' - ');
  const type = module.moduluse_status;
  return (
    <>
      <TagContainer
        width="240"
        type={type ? parseStatus[type] : 'white'}
        onClick={() => setModuleSelected(module.id)}
      >
        <div className="key-tag">
          <div className="container-text">
            <h4>
              Modulo {moduleNumber}
              {type === 'green' ? (
                <span>
                  <CheckCircleIcon color="#FFF" />
                </span>
              ) : (
                ''
              )}
            </h4>
            <p>{moduleName}</p>
          </div>
        </div>
        <div className="body-tag" />
      </TagContainer>
    </>
  );
};

export default TagComponent;
