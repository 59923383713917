import styled from 'styled-components';

interface HamburguerProps {
  open: boolean;
}

export const MenuLateral = styled.nav<HamburguerProps>`
  position: fixed;
  background: linear-gradient(180deg, #0e4378 0%, #236e65 100%);
  text-align: left;
  display: ${({ open }) => (open ? 'block' : 'none')};
  transition: all 0.3s ease-in-out;
  height: 100vh;
  width: 300px;
  float: left;
  z-index: 999 !important;
  box-shadow: 1px -3px 33px 3px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 1px -3px 33px 3px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 1px -3px 33px 3px rgba(0, 0, 0, 0.55);

  @media (max-width: 650px) {
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    text-align: left;
    height: 100%;
    padding: 0.8rem;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    overflow: scroll;
  }

  @media (max-height: 700px) {
    overflow: scroll;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  /* height: 100%; */

  div {
    margin-top: 1rem;

    img {
      padding: 10px;
      margin-right: 4rem;
      width: 80;
    }
  }

  ul {
    margin-top: 10%;
    padding: 0px;
    text-align: left;
    list-style-type: none;
    width: 100%;

    li {
      display: flex;
      margin-left: 10%;
      width: 100%;
      position: relative;
      height: 32px;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #fff !important;
        text-decoration: none;

        svg {
          margin-left: 2.25rem;
        }

        > img {
          margin-right: 13px;
          width: 27px;
        }
      }

      & + li {
        margin-top: 1.25rem;
      }
    }
  }
`;

export const Margin = styled.div`
  height: 1;
  margin-top: 15px;
`;

export const SubItems = styled.div`
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  width: 298px;
  height: 100%;
  justify-content: flex-end;

  a {
    width: 158px;
    margin-left: 3.65rem;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    text-decoration: none;

    > img {
      width: 159px;
      margin-bottom: 2rem;
    }

    & + a {
      margin-top: 5%;
    }
  }
`;

export const StyledBurger = styled.button<HamburguerProps>`
  position: ${({ open }) => (open ? 'fixed' : 'absolute')};
  /* top: ${({ open }) => (open ? '1%' : '30px')}; */
  left: ${({ open }) => (open ? '15.86rem' : '2rem')};

  @media (max-width: 650px) {
    /* top: ${({ open }) => (open ? '1%' : '3.5%')}; */
    left: ${({ open }) => (open ? '260px' : '1rem')};
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 999;

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? '#FFF' : '#333')} !important;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
