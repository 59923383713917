import axios from 'axios';

export const saveAvatar = async (payload) => {
  const response = await axios.post(
    'https://in3h5uvz1b.execute-api.us-east-2.amazonaws.com/dev/',
    payload,
  );

  const { data } = response;
  return data;
};

export const updatePassword = async (payload) => {
  const response = await axios.post(
    'https://rpmajy1vyh.execute-api.us-east-2.amazonaws.com/dev/',
    payload,
  );

  const { data } = response;
  return data;
};
