/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */

import React, { useEffect, useRef, useCallback, useState } from 'react';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaRegTimesCircle,
} from 'react-icons/fa';
import Webcam from 'react-webcam';
import QRCode from 'qrcode-svg';
import Vars from '../../config/variables';
import { useAuth } from '../../hooks/auth';
import Button from '../Button';
import Text from '../Text';

import WhatsAppIcon from '../../assets/icons/whatsappIcon.svg';
import useStyles from './ModalStyles';
import { useToast } from '../../hooks/toast';
import { saveAvatar } from '../../services/avatar';

const videoConstraints = {
  width: 260,
  height: 430,
  facingMode: 'user',
};

const videoConstraintsGet = {
  audio: false,
  video: { facingMode: 'user', width: 260, height: 430 },
};

interface ModalProps {
  onCloseRequest: () => void;
  closeButtonVisible: boolean;
  // modalTitle: string;
}

const Modal = ({
  // modalTitle,
  onCloseRequest,
  closeButtonVisible,
}: ModalProps) => {
  const { user, updateUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [imageCapture, setImageCapture] = useState('');
  const [userNoUsedCam, setUserNoUsedCam] = useState(false);
  const [camShow, setCamShow] = useState(false);
  const [camLoading, setCamLoading] = useState(false);
  const [qrCode, setQrCode] = useState('');

  const classes = useStyles();
  const modal = useRef(null) as any;
  const webcamRef = useRef<any>(null);
  const { addToast } = useToast();

  const Capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({});
    if (imageSrc) {
      setImageCapture(imageSrc);
      setCaptured(true);
    }
  }, [webcamRef]);

  const ResetCam = useCallback(() => {
    setCaptured(false);
    setImageCapture('');
  }, []);

  const ConfirmImage = useCallback(async () => {
    if (imageCapture) {
      setLoading(true);

      const base64ImageContent = imageCapture.replace(
        'data:image/jpeg;base64,',
        '',
      );

      const up = await saveAvatar({
        student_id: user.id,
        image: base64ImageContent,
      });

      updateUser(up);

      if (up) {
        addToast({
          title: 'Foto enviada com sucesso!',
          description: 'Sua foto foi enviada com sucesso.',
          type: 'success',
        });

        onCloseRequest();
      }
    }
    /* eslint-disable-next-line */
  }, [addToast, imageCapture, onCloseRequest, updateUser]);

  const handleKeyUp = useCallback(
    (e) => {
      const keys: any = {
        27: () => {
          e.preventDefault();
          onCloseRequest();
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    },
    [onCloseRequest],
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (!modal) {
        if (!modal.current.contains(e.target)) {
          onCloseRequest();
          document.removeEventListener('click', handleOutsideClick, false);
        }
      }
    },
    [onCloseRequest],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick]);

  useEffect(() => {
    setCamLoading(true);
    navigator.mediaDevices
      .getUserMedia(videoConstraintsGet)
      .then((stream) => {
        setTimeout(() => {
          setCamLoading(false);
        }, 3000);

        setCamShow(true);

        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch(() => {
        setCamLoading(false);
        setUserNoUsedCam(true);
        generateQrCode();
      });
  }, []);

  const generateQrCode = () => {
    const qr = new QRCode({
      content: `${Vars.PUBLIC_URL}/?refer=/mobile/send/img`,
      join: true,
      predefined: false,
    }).svg();

    setQrCode(qr);
  };

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modal} ref={modal}>
        {closeButtonVisible && (
          <div className={classes.closeBtn} style={{ marginRight: 20 }}>
            <button
              onClick={onCloseRequest}
              type="button"
              style={{ border: 0, background: 'transparent' }}
            >
              <FaRegTimesCircle color="white" />
            </button>
          </div>
        )}

        <div className={classes.modalContent}>
          {userNoUsedCam ? (
            // eslint-disable-next-line react/no-danger

            <div className={classes.modalMain}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: qrCode }} />

              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #1976D2',
                  margin: '20px 0',
                  borderRadius: 7,
                  background: 'transparent',
                  padding: 15,
                  color: '#1976D2',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
                type="button"
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=+55${user.phone.replace(
                      /[\u0300-\u036f]/g,
                      '',
                    )}&text=Ola ${user.name}, 
                    acesse esse site para tirar sua foto: ${Vars.PUBLIC_URL}
                    /?refer=/mobile/send/img . UNICFC `,
                    '_blank',
                  );
                }}
              >
                <img
                  src={WhatsAppIcon}
                  width={20}
                  height={20}
                  alt="Whatsapp"
                  style={{ marginRight: 5 }}
                />
                Link pelo Whatsapp
              </button>
            </div>
          ) : (
            <div style={{ maxWidth: '100%', textAlign: 'center' }}>
              {captured ? (
                <div className={classes.imageCapture}>
                  <img alt="Foto Capturada" src={imageCapture} />
                  <div className={classes.imageOverlay}>
                    <FaCheckCircle size={30} style={{ margin: 10 }} />
                  </div>
                </div>
              ) : (
                <>
                  {camLoading && (
                    <span style={{ fontWeight: 700, padding: 20 }}>
                      Carregando...
                    </span>
                  )}

                  {camShow && !camLoading && (
                    <>
                      <img
                        src="https://unicfc-images-new.s3.us-east-2.amazonaws.com/eye.svg"
                        alt="eye"
                        width="70px"
                        style={{
                          margin: '-36px 0 13px 0',
                        }}
                      />
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {userNoUsedCam ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FFF',

              justifyContent: 'space-between',
              height: '450px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                  textAlign: 'center',
                  padding: '0 15px',
                }}
                fontSize={22}
                fontWeight="bold"
              >
                <FaExclamationTriangle color="#FF5722" size={100} />
                Seu computador não uma possui câmera!
              </Text>

              <div style={{ marginTop: 10, fontSize: 22, padding: '0 15px' }}>
                <span>
                  Escaneie o QR Code com seu celular para continuar o processo
                  ou gere um link no whatsapp.
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FFF',
              margin: '0 40px',
              paddingTop: 50,
              justifyContent: 'space-between',
              // height: '450px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* <Text fontSize={42} fontWeight="bold" color="#FFF">
                {modalTitle}
              </Text> */}
              <Text
                style={{ marginTop: '15px', textAlign: 'center' }}
                fontSize={18}
                fontWeight="bold"
              >
                Tire uma nova foto para o seu perfil.
              </Text>

              <div
                style={{ marginTop: '15px', fontSize: 18, padding: '0 40px' }}
              >
                <ul className="text-description">
                  <li className={classes.description}>
                    Certifique-se que seu rosto esta encaixado no quadro ao
                    lado;
                  </li>
                  <li className={classes.description}>
                    Procure um ambiente iluminado;
                  </li>
                  <li className={classes.description}>
                    Evite acessórios no rosto.
                  </li>
                </ul>
              </div>
            </div>

            <div style={{ marginBottom: '1.22rem' }}>
              {!captured ? (
                <Button
                  backgroundColor="#FF5722"
                  hoverColor="#f04410"
                  type="button"
                  onClick={Capture}
                >
                  Tirar foto
                </Button>
              ) : (
                <>
                  <Button
                    backgroundColor="#FF5722"
                    hoverColor="#f04410"
                    type="button"
                    onClick={ResetCam}
                  >
                    Nova Foto
                  </Button>

                  <Button
                    loading={loading}
                    backgroundColor="#3ec1b1"
                    hoverColor="#2fa799"
                    type="button"
                    onClick={ConfirmImage}
                  >
                    Confirmar
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
