import styled from 'styled-components';

interface RowLeassonStyledProps {
  content?: string;
}

export const Content = styled.div`
  min-height: calc(100vh - 170px);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  min-height: 100%;

  @media (max-width: 650px) {
    position: relative;
  }
`;

export const Container = styled.div`
  width: 1280px;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column !important;
`;

export const NextClassContainer = styled.section`
  width: 90%;
  margin-top: 3%;
  text-align: left;
  margin-left: 5%;

  span {
    font-weight: bold;
    font-size: 24px;
    color: #373f49;
  }

  .class-course-card {
    margin-top: 30px;
  }
`;

export const LeassonSelectedStyled = styled.div`
  margin-bottom: 50px;

  .header-leasson-selected {
    > div:nth-child(1) {
      display: flex;
      align-items: center;
    }
    > div:nth-child(2) {
      text-align: center;
      > div {
        float: right;
        width: min-content;
        > span:nth-child(1) {
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 11px;
          letter-spacing: 0em;
          text-align: left;
        }
        > span:nth-child(2) {
          .countdownContainer {
            justify-content: center;
            span {
              font-size: 32px !important;
              color: #000 !important;
              font-weight: 700;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .cisco-webex-download-container {
    width: 100%;
    h3 {
      font-family: Inter;
      font-size: 25px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: #1976d2;
      margin-bottom: 50px;
    }
    h5 {
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
      margin-bottom: 30px;
    }
    .btn-download-container {
      text-align: center;
      img {
        margin-bottom: 8px;
      }
    }
  }

  .webex-video {
    img {
      width: 100%;
    }
  }

  .container-wb {
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 680px) {
    .webex-video {
      margin-top: 25px;
    }
  }
`;

export const LeassonCfcStyled = styled.div`
  margin-bottom: 50px;

  .carrousel-tag {
    > div:first-child,
    > div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .container-content {
    background: #e8e8e8;
    padding: 0 50px;
  }

  .container-tag {
    margin: 30px 0;
    svg {
      height: 146px !important;
      width: 250px !important;
    }
    @media (max-width: 680px) {
      height: 200px !important;
      width: 250px !important;
    }
  }
`;

export const RowLeassonStyled = styled.div<RowLeassonStyledProps>`
  /* margin-top: 50px; */
  /* filter: ${(props) => (props.enable ? none : 'grayscale(100)')}; */
  .row-table {
    border-bottom: 1px solid #bdd7f2;
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 680px) {
        > div {
          display: none;
        }
      }

      > div {
        width: 100%;
        height: 60px;
        background: #fff;
        margin-bottom: -60px;
        padding: 8px 0;
        > div {
          width: 50%;
          height: 100%;
          border-right: 2px solid ${(props) => props.content.checkIconColor};
        }
      }
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: start;
      align-items: center;
      font-weight: 800;
      color: ${(props) => props.content.titleColor};
    }
    > div:nth-child(3) {
      text-align: right !important;
      display: block;
      button {
        margin: 15px;
      }
      @media (max-width: 680px) {
        margin-top: 10px;
        text-align: center !important;
        button {
          margin-top: 0px;
        }
      }
    }
    .destaque-subtitle {
      color: ${(props) => props.content.subtitleColor} !important;
    }

    @media (max-width: 680px) {
      margin-top: 20px;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;

  justify-content: center;
  align-items: center;

  bottom: 0;

  height: 100px;
  width: 100%;

  background: #0e4378;

  span {
    font-size: 12px;
    color: #fff;
  }
`;
