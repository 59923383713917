import React from 'react';
import { Switch } from 'react-router-dom';

import { useApp } from '../hooks/app';

import Route from './Route';
import Layout from '../components/Layout';

import Ead from '../pages/Dashboard/leasson.ead';
import LiveClass from '../pages/Dashboard/leasson.live';
import LessonContent from '../pages/Dashboard/leasson.content';
// import Dashboard from '../pages/Dashboard/leasson.quiz';
import Dashboard from '../pages/Dashboard/index';
import Library from '../pages/Library';
import MyData from '../pages/MyData';
import Terms from '../pages/Terms';
import Annotations from '../pages/Annotations';
import Notices from '../pages/Notices';
import Exam from '../pages/Exam';
import Result from '../pages/Result';
// import ResultPositive from '../pages/ResultPositive';
import Forum from '../pages/Forum';
import ForumAsk from '../pages/ForumAsk';
import ForumNewTopic from '../pages/ForumNewTopic';
import ForumResp from '../pages/ForumResp';
import FinishedModule from '../components/FinishedModule';
import InitialPage from '../pages/InitialPage';
// import FirstPassword from '../pages/FirstPassword';
import CookiesAcceptComponent from '../components/CookiesWarning';
import HelpCenter from '../pages/HelpCenter';
import NotFound from '../pages/NotFound';
import Recovery from '../pages/Recovery';

const Routes: React.FC = () => {
  const { showCongratulationModal } = useApp();

  return (
    <>
      <CookiesAcceptComponent />
      {showCongratulationModal.show && <FinishedModule />}
      <Layout>
        <Switch>
          <Route path="/me" component={MyData} isPrivate exact />
          <Route path="/course" component={Dashboard} isPrivate exact />
          <Route
            path="/course/liveclass"
            component={LiveClass}
            isPrivate
            exact
          />
          <Route path="/course/ead" component={Ead} isPrivate exact />
          <Route
            path="/course/ead/content"
            component={LessonContent}
            isPrivate
            exact
          />
          <Route
            path="/course/ead/content/exam"
            component={Exam}
            isPrivate
            exact
          />
          <Route
            path="/course/ead/content/exam/result"
            component={Result}
            isPrivate
            exact
          />
          <Route path="/forum" component={Forum} isPrivate exact />
          <Route
            path="/forum/topic"
            component={ForumNewTopic}
            isPrivate
            exact
          />
          <Route path="/forum/topic/:id" component={ForumAsk} isPrivate exact />
          <Route
            path="/forum/topic/answer/:id"
            component={ForumResp}
            isPrivate
            exact
          />
          <Route path="/library" component={Library} isPrivate exact />
          <Route path="/annotations" component={Annotations} isPrivate exact />
          <Route path="/notices" component={Notices} isPrivate exact />
          <Route path="/terms" component={Terms} isPrivate exact />
          <Route path="/change-password" component={Recovery} isPrivate exact />
          <Route path="/help" exact component={HelpCenter} isPrivate />
          <Route path="/" component={InitialPage} exact isPrivate />
          <Route component={NotFound} isPrivate />
        </Switch>
      </Layout>
    </>
  );
};

export default Routes;
