/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { Form as Unform } from '@unform/web';
import moment from 'moment';
import { Form, Col } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../utils/getValidationError';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  ChangeDataOption,
  ChangeImageContainer,
  MyDataContainer,
} from './styles';
import Button from '../../components/Button';
import Modal from '../../components/ModalCam';
import { useAuth } from '../../hooks/auth';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import defaultUserAvatar from '../../assets/images/user.png';
import { updatePassword } from '../../services/avatar';

interface ResetPasswordFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const MyData: React.FC = () => {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const { addToast } = useToast();
  const [, setOpen] = useState(true);

  const width = window.innerWidth;

  useEffect(
    () => {
      if (width <= 650) {
        setOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().required('Senha antiga obrigatória'),
          password: Yup.string().required('Senha obrigatória'),
          password_confirmation: Yup.string().required(
            'Senha de confirmação obrigatória',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.password !== data.password_confirmation) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao alterar senha.',
            description: 'A senha e a confirmacão de senha estão diferentes.',
          });
          return;
        }
        if (data.password.length < 6) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao alterar senha.',
            description: 'A senha deve ter pelo menos 6 caracteres.',
          });
          return;
        }

        const resultUpdatePassword = await updatePassword({
          password: data.old_password,
          new_password: data.password_confirmation,
          student_id: user.id,
        });

        if (resultUpdatePassword) {
          addToast({
            type: 'success',
            title: 'Senha alterada com sucesso.',
            description: 'Sua senha foi alterada com sucesso!',
          });

          setLoading(false);
          formRef.current?.reset();
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao alterar senha.',
          description:
            'Ocorreu um erro ao fazer a alteração, cheque as credenciais.',
        });
      }
    },
    [addToast]  /* eslint-disable-line */
  );

  const birthdayDate = moment(user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <ChangeDataOption>
              <ChangeImageContainer>
                <div
                  className="imgPerfil"
                  style={{
                    backgroundImage: `url("data:image/png;base64,${user.image_url}"), url(${defaultUserAvatar})`,
                  }}
                />

                <Button
                  color="#1976D2"
                  backgroundColor="#1976D2"
                  hoverOff
                  outerline
                  type="button"
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  Alterar Foto
                </Button>
              </ChangeImageContainer>

              <div>
                <span>Alterar Senha</span>
                <Unform ref={formRef} onSubmit={handleSubmit}>
                  <Input
                    name="old_password"
                    placeholder="Digite sua senha antiga"
                    type="password"
                  />
                  <Input
                    name="password"
                    placeholder="Digite sua nova senha"
                    type="password"
                  />
                  <Input
                    name="password_confirmation"
                    placeholder="Digite novamente"
                    type="password"
                  />

                  <div
                    style={{
                      marginTop: 5,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{ width: 215 }}
                      color="#1976D2"
                      backgroundColor="#1976D2"
                      hoverOff
                      outerline
                      type="submit"
                      loading={loading}
                    >
                      Salvar alterações
                    </Button>
                  </div>
                </Unform>
              </div>
            </ChangeDataOption>
            {modalShow && (
              <Modal
                modalTitle="Nova foto"
                closeButtonVisible
                onCloseRequest={() => {
                  setModalShow(false);
                }}
              />
            )}

            <MyDataContainer>
              <Unform
                onSubmit={() => {
                  return {};
                }}
                style={{ width: '100%' }}
              >
                <Form.Group controlId="formGridName">
                  <Form.Label>Nome Completo: </Form.Label>
                  <Form.Control
                    className="input-class"
                    type="text"
                    value={user.name}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formGridName">
                  <Form.Label>Email: </Form.Label>
                  <Form.Control
                    className="input-class"
                    type="text"
                    value={user.email ? user.email : ''}
                    readOnly
                  />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Telefone:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.phone}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Celular:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.mobile ? user.mobile : ''}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>CPF:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.identifier}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>CNH:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.cnh}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>RG:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.register}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Órgão expedidor:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.rg_organ}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>UF Registro:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.state_register}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Data Nasc.:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={birthdayDate}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Nacionalidade:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.nationality}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Sexo:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.gender}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>RENARCH:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.renach}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridName">
                  <Form.Label>Nome da Mãe: </Form.Label>
                  <Form.Control
                    className="input-class"
                    type="email"
                    value={user.mother_name}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formGridName">
                  <Form.Label>Endereço: </Form.Label>
                  <Form.Control
                    className="input-class"
                    type="email"
                    value={user.street}
                    readOnly
                  />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Bairro:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.neighborhood}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>CEP:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.zipcode}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Complemento:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.complement ? user.complement : ''}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Cidade:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      value={user.city}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress2">
                    <Form.Label>Estado:</Form.Label>
                    <Form.Control
                      className="input-class"
                      type="text"
                      readOnly
                      value={user.state_register}
                    />
                  </Form.Group>
                </Form.Row>
              </Unform>
            </MyDataContainer>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default MyData;
