import styled from 'styled-components';

export const CardContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  heig > div {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    justify-content: center;
    align-items: center;

    color: #373f49;
  }

  .class-card {
    margin-top: 15px;
    margin-bottom: 40px;
    width: 100%;
    border: #deebf8;
    background: #deebf8;
  }

  .class-header {
    background: #1976d2;
    color: #fff;
    padding: 5%;
    height: 09vh;

    h5 {
      align-items: center;
      justify-content: center;
    }
  }

  .class-img {
    margin: 5px 8px 5px 8px;
    height: 17vh;
  }

  .class-body {
    > div {
      font-family: Inter;
      font-style: normal;
      font-size: 0.9rem;
      line-height: 19px;
      border: none;

      color: #000;
    }

    .class-title {
      font-family: Inter;
      font-style: normal;
      font-size: 0.9rem;
      line-height: 19px;
      font-weight: bold;
    }

    .class-subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #373f49;
    }

    .class-answers {
      font-size: 0.75rem;
      padding: 1px;
      margin-left: 15px;
    }

    .class-input {
      margin-right: 15px;
      width: 12px;
    }

    .class-strong-letter {
      margin-left: 25px;
      font-weight: bold;
      padding: 10px;
    }

    .class-description {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      /* identical to box height */

      > p {
        color: #373f49;
      }
    }

    .class-icon {
      width: 5vh;
    }

    .class-attention > p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 92.52%;
      /* identical to box height, or 17px */
      display: flex;
      align-items: center;

      color: #da4a1d;
    }

    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: red;
    }
  }

  .class-notification {
    margin: 40px 0 -20px 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
  }

  .class-notification-success {
    color: #3ec1b1;
  }

  .class-notification-wrong {
    color: #b22222;
  }

  .class-second-card {
    margin: 30px;
    /* height: 20vh; */
    background-color: #e8e8e8;
    border: 2px solid #cfcfcf;
    border-radius: 7px;
    position: relative;
  }

  .class-first-letters {
    font-size: 0.8rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    padding-left: 20px;
    padding-top: 20px;
    color: #000;
  }

  .class-second-letters {
    font-size: 0.8rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    padding-left: 60px;
    padding-top: 20px;
    color: #000;
  }

  .class-cod {
    font-size: 0.7rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
  }
`;

export const HandoutCardStyle = styled.div`
  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;
