import React from 'react';

import { AuthProvider } from './auth';
import { AppProvider } from './app';
import { ToastProvider } from './toast';
import { CountdownProvider } from '../contexts/CountdownContext';

const ApplicationProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <AppProvider>
      <CountdownProvider>
        <ToastProvider>{children}</ToastProvider>
      </CountdownProvider>
    </AppProvider>
  </AuthProvider>
);

export default ApplicationProvider;
