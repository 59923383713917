import styled from 'styled-components';

export const Content = styled.div``;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  min-height: 100%;

  @media (max-width: 650px) {
    position: relative;
  }
`;

export const Container = styled.div`
  width: 1280px;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column !important;

  span {
    font-weight: 700;
    margin-top: 20px;
    margin-left: 50px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #373f49;
  }

  .class-button {
    margin-top: 60px;
    background: #3ec1b1;
    border-color: #3ec1b1;
    border-radius: 6.94137px;
    width: 219px;
    height: 56px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 22px;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
`;

export const NextClassContainer = styled.section`
  width: 90%;
  margin-top: 3%;
  text-align: left;
  margin-left: 5%;

  > span {
    font-weight: bold;
    font-size: 24px;
    color: #373f49;
    margin-left: 3px;
    margin-bottom: 40px;
  }
`;

export const ResultStyled = styled.div`
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0e4378;
  }

  .class-icon {
    width: 4vh;
    margin-top: 10px;
    margin-left: 25px;
  }

  .class-attention-negative > p {
    margin-left: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 92.52%;
    /* identical to box height, or 30px */
    display: flex;
    align-items: center;

    color: #b22222;
  }

  .class-attention-positive > p {
    margin-left: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 92.52%;
    /* identical to box height, or 30px */
    display: flex;
    align-items: center;

    color: #3ec1b1;
  }

  .class-result-positive {
    padding: 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 40px;

    color: #3ec1b1;

    > p {
      margin-top: -14px;
    }
  }

  .class-button-footer-positive {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-left: 30px;

    button {
      white-space: nowrap;
      background-color: #3ec1b1;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      border-color: #3ec1b1;
      color: #ffffff;
    }
  }

  .class-result-negative {
    padding: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 40px;

    color: #ff5722;

    > p {
      margin-top: -14px;
    }
  }

  .class-button-footer-negative {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-left: 30px;

    button {
      white-space: nowrap;
      background-color: #ff5722;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      border-color: #ff5722;
      color: #ffffff;
    }
  }
`;
