import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';

import ForumAskCard from '../../components/ForumAskCard';
import ForumAskCard2 from '../../components/ForumAskCard2';
import profileImgJoao from '../../assets/images/profileImgJoao.svg';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NoteStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';

const Forum: React.FC = () => {
  const [forumData, setForumData] = useState([]);  /* eslint-disable-line */

  useEffect(() => {
    const newsForumData = () => {
      axios
        .get('https://z0nmx78ryk.execute-api.us-east-2.amazonaws.com/dev/')
        .then((result) => {
          const getForumData = result.data;
          setForumData(getForumData);
        });
    };
    newsForumData();
  }, []);

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <span>Forum UNICFC EAD - Pergunta</span>
            <NoteStyled>
              {/* {forumData.map((value: any) => {
                return ( */}
              <Row>
                <Col md="12">
                  <ForumAskCard title="teste" description="" text="teste" />
                  <ForumAskCard2
                    title="teste"
                    description=""
                    profileImg={profileImgJoao}
                    author="João"
                    text="teste"
                  />
                </Col>
              </Row>
              {/* ); */}
              {/* })} */}
            </NoteStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Forum;
