import styled from 'styled-components';

export const CardContainer = styled.div`
  position: fixed;
  z-index: 999;

  .form-annotation-container {
    position: fixed;
    bottom: 30px;
    left: 30px;
    display: flex;
    flex-direction: row;
    -webkit-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    -moz-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    width: 500px;

    .closeBtn {
      position: absolute;
      top: -30px;
      right: 5px;

      button {
        background: unset;
        border: 1px solid #000;
        height: 25px;
        width: 25px;
        font-weight: 800;
        border-radius: 50px;
      }
    }
  }

  .class-card {
    border: unset;
    border-radius: 5px;
    background-color: '#1976d2';
    width: 500px;
  }

  .add-annotation-icon {
    button {
      position: fixed;
      background: #fff;
      box-sizing: unset;
      left: 20px;
      bottom: 20px;
      border: unset;
      /* padding: 0px; */
      border-radius: 100px;
      z-index: 999999;
      -webkit-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
      -moz-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
      box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }

  .send-annotation-icon {
    button {
      background: unset;
      border: unset;
    }
  }

  .class-add-button {
    white-space: nowrap;
    top: 180px;
    right: 23px;
    color: '#1976d2';
    transform: rotate(-90deg);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1rem;

    > span {
      color: '#1976d2';
    }
  }

  .class-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #1976d2;
    height: 45px;
    color: #fff;
    padding: 5px 10px;
    border-color: #1976d2;
    display: flex;
    flex-direction: row;

    .form-group {
      width: 500px;
    }

    .class-note-header {
      color: #fff;
      background-color: '#1976d2';
      border: 'unset';

      ::placeholder {
        color: #fff;
      }
    }

    > div {
      display: flex;
    }

    h5 {
      line-height: 25px;
      margin: 0;
    }
  }

  .class-body {
    padding: 5px;
    border: unset;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    background: '#DEEBF8';
  }

  .class-text-area {
    border: unset;
    background: #deebf8;
  }

  @media (max-width: 650px) {
    .form-annotation-container {
      width: 300px;
    }

    .class-card {
      width: 300px;
    }
  }
`;

export const HandoutCardStyle = styled.div`
  border-radius: 12px;
  -webkit-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  -moz-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);

  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;
