import React from 'react';

interface SvgIcon {
  color: string;
}

const CFCicon: React.FC<SvgIcon> = ({ color }) => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.06445 6.03209H7.01212V2.21158C7.01212 1.22567 7.8141 0.423691 8.80001 0.423691H27.7614C28.7473 0.423691 29.5493 1.22567 29.5493 2.21158V6.03209H33.497C34.8771 6.03209 36 7.15503 36 8.53514V29.2662C36 30.6464 34.8771 31.7693 33.497 31.7693H3.06445C1.68435 31.7693 0.561405 30.6464 0.561405 29.2662V8.53514C0.561405 7.15489 1.68435 6.03209 3.06445 6.03209ZM27.4038 2.56916H9.15745V6.03209H27.4038V2.56916ZM33.497 8.17756H3.06445C2.87065 8.17756 2.70687 8.34133 2.70687 8.53514V14.9685H13.5517V14.0074C13.5517 13.0215 14.3536 12.2195 15.3395 12.2195H21.2219C22.2078 12.2195 23.0097 13.0215 23.0097 14.0074V14.9685H33.8545V8.53514C33.8545 8.34119 33.6908 8.17756 33.497 8.17756ZM15.6971 14.3651V17.828H20.8643V14.3651H15.6971ZM3.06445 29.6238H33.497C33.6908 29.6238 33.8545 29.4602 33.8545 29.2662V17.114H23.0097V18.1855C23.0097 19.1714 22.2078 19.9734 21.2219 19.9734H15.3395C14.3536 19.9734 13.5517 19.1714 13.5517 18.1855V17.114H2.70687V29.2662C2.70687 29.4602 2.87065 29.6238 3.06445 29.6238Z"
        fill={color}
      />
    </svg>
  );
};

export default CFCicon;
