/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { Content, Container, Main, NextClassContainer } from './styles';

import EadCardComponent from '../../components/EadCard';
import CourseCardComponent from '../../components/CourseCard';
import Modal from '../../components/ModalCam';
import { getMatriculations } from '../../services/courses';
import Loading from '../../components/Loading';

const Courses = () => {
  const [courses, setCourses] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  useEffect(() => {
    const matriculation = async () => {
      if (courses === false) {
        const result = await getMatriculations();
        setCourses(result);
      }
    };
    matriculation();
  }, [courses]);
  return courses ? (
    <Content>
      <Main>
        {statusModal && (
          <Modal
            modalTitle="Hora da foto!"
            closeButtonVisible={false}
            onCloseRequest={() => {
              setStatusModal(false);
            }}
          />
        )}
        <Container>
          <NextClassContainer>
            <span>Cursos</span>
            {courses &&
              courses.map((course, index) =>
                course.course_id === 14 ? (
                  <CourseCardComponent key={index} content={course} />
                ) : (
                  <EadCardComponent key={index} content={course} />
                ),
              )}
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  ) : (
    <Loading />
  );
};

export default Courses;
