import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';

import YoutubeIcon from '../../assets/icons/YoutubeIcon.svg';
import UniCFCLogo from '../../assets/logos/UNICFC.svg';
import mySimulatedExamIcon from '../../assets/icons/mySimulatedExamIcon.svg';
import ArrowLeftIcon from '../icons/LeftArrow';

import {
  MenuContent,
  MenuLateral,
  Margin,
  SubItems,
  StyledBurger,
} from './styles';

interface MenuLateralData {
  id: number;
  title: string;
  image: string;
  route: string;
  isActive?: boolean;
}

interface MenuLateralSubItemsData {
  id: number;
  title: string;
  route: string;
  external?: boolean;
  onClick?: () => void;
}

interface MenuLateralProps {
  Menu: MenuLateralData[];
  SubItemsMenu: MenuLateralSubItemsData[];
  menuOpened: boolean;
}

interface HamburguerProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
}

export function Hamburguer({ open, setOpen }: HamburguerProps): JSX.Element {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}

export default function MenuLateralComponent({
  Menu,
  SubItemsMenu,
  menuOpened,
}: MenuLateralProps): JSX.Element {
  const { signOut } = useAuth();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const percentVideo = window.innerWidth < 500 ? 0.7 : 0.45;
  const widthVideo = window.innerWidth * percentVideo;
  const heightVideo = widthVideo * 0.5625;

  return (
    <MenuLateral open={menuOpened}>
      <Modal show={show} onHide={handleClose} className="containerYoutube">
        <Modal.Body>
          <button type="button" className="closeBtn" onClick={handleClose}>
            X
          </button>
          {window.innerWidth > 600 ? (
            <iframe
              height={heightVideo}
              width={widthVideo}
              title="Tutorial acesso ao aluno - computador 2021.mp4"
              src="https://player.vimeo.com/video/537113372?title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <iframe
              height={heightVideo}
              width={widthVideo}
              title="Tutorial acesso ao aluno - celular 2021.mp4"
              src="https://player.vimeo.com/video/537111155?title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          )}
        </Modal.Body>
      </Modal>
      <MenuContent>
        <div>
          <img src={UniCFCLogo} alt="UNICFC" width={180} />
        </div>

        <ul>
          {Menu.map((data) => {
            return (
              <li key={data.id}>
                <Link to={data.route}>
                  <img src={data.image} alt={data.title} width={180} />
                  {data.title}
                  {data.isActive === true && <ArrowLeftIcon />}
                </Link>
              </li>
            );
          })}
          <li key={8}>
            <a
              href="https://www.mariaolma.com.br/prova/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={mySimulatedExamIcon} alt="Simlados" width={180} />
              Provas Simuladas
            </a>
          </li>
        </ul>

        <Margin />

        <SubItems>
          {SubItemsMenu.map((subitem) => {
            return (
              <>
                {!subitem.external ? (
                  <Link key={subitem.id} to={subitem.route}>
                    {subitem.title}
                  </Link>
                ) : (
                  <a key={subitem.id} href={subitem.route}>
                    {subitem.title}
                  </a>
                )}
              </>
            );
          })}
          <a
            href="#element"
            onClick={() => {
              handleShow();
            }}
          >
            Tutorial
          </a>
          <a
            href="/"
            onClick={() => {
              signOut();
            }}
          >
            Sair
          </a>

          <button
            style={{ margin: '40px', background: 'unset', border: 0 }}
            type="button"
          >
            <a
              href="https://www.youtube.com.br/unicfc"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '0' }}
            >
              <img src={YoutubeIcon} alt="Youtube UniCFC" />
            </a>
          </button>
        </SubItems>
      </MenuContent>
    </MenuLateral>
  );
}
