import styled from 'styled-components';
import { Form } from '@unform/web';

interface IMargin {
  margin?: number;
}

export const Main = styled.div`
  min-height: calc(100vh - 170px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items: center; */
  margin: 0 auto;
`;

export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 55px 32px -10px #0a325a;
  border-radius: 9px;
  padding: 30px;
  height: 709px;
  max-width: 370px;
`;

export const SelectLoginTypeContainer = styled.div`
  margin-top: 30px;

  > div {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SelectLoginStateContainer = styled.div`
  margin-top: 35px;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  > div {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      vertical-align: middle;
      margin-right: 9px;
    }
  }

  span {
    font-size: 14px;
    line-height: 15px;
  }
`;

export const ForgotContainer = styled(Form)`
  margin-top: 30px;

  > span {
    display: flex;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #434d59;

    margin: 8% 0;
  }
`;

export const InfoContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

export const ForgotContent = styled.div`
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div + div {
    margin-top: 18px;
  }

  span:first-child {
    margin-top: 4%;
    margin-bottom: 4%;
  }
`;

export const Margin = styled.div<IMargin>`
  height: 1;
  margin-top: ${(props) => (props.margin ? props.margin : 15)};
`;

export const SelectTouch = styled.button`
  border: 0;
  background: transparent;
`;
