import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import { CardContainer } from './styles';
import DeleteIcon from '../icons/DeleteIcon';
import { deleteAnnotations } from '../../services/courses';

const NoteCard = ({ content }) => {
  const deleteNotes = async (id) => {
    /* eslint-disable-next-line */
    if (!window.confirm('Tem certeza que deseja remover essa anotação?')) {
      return false;
    }
    const payload = { id };
    const result = await deleteAnnotations(payload);
    if (result) window.location.reload();
    else {
      /* eslint-disable-next-line */
      alert(
        'Não foi possivel remover essa a anotação selecionada. Por favor tente novamente.',
      );
    }
    /* eslint-disable-next-line */
    alert(
      'Não foi possivel remover essa a anotação selecionada. Por favor tente novamente.',
    );
    return false;
  };

  return (
    <CardContainer>
      <Row>
        <Col md="10" xs="10">
          <span>
            Dia{' '}
            {moment(content.created_at, 'YYYY-MM-DD HH:mm:ss')
              .subtract({ h: 3 })
              .format('DD/MM/YYYY - HH:mm')}
            hs
          </span>
          <Card className="class-card">
            <Card.Header className="class-header">
              <Row>
                <Col>
                  <h5>{content.title}</h5>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="class-body">{content.body}</Card.Body>
          </Card>
        </Col>
        <Col md="2" className="delete-icon" xs="2">
          <button
            type="button"
            onClick={() => deleteNotes(content.id)}
            className="closeBtn"
          >
            <DeleteIcon />
          </button>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default NoteCard;
