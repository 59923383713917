import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './style';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  relItem: string;
  isSelected?: boolean;
};

const Select: React.FC<ButtonProps> = ({
  Icon,
  relItem,
  isSelected = false,
  ...rest
}) => {
  return (
    <Container isSelected={isSelected} {...rest}>
      <div>{Icon}</div>

      <span>{relItem}</span>
    </Container>
  );
};

export default Select;
