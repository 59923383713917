import styled from 'styled-components';

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  position: fixed;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-loading {
    /* filter: blur(50px);
    -webkit-filter: blur(50px); */
  }

  #breathing-button {
    margin-top: -100px;
    width: 300px;
    padding: 12px;
    -webkit-animation: breathing 4s ease-out infinite normal;
    animation: breathing 4s ease-out infinite normal;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    border-radius: 2px;
    text-align: center;
    position: absolute;
    z-index: 13;
  }

  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }

    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
`;

export default LoadingContainer;
