import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import NoticeCard from '../../components/NoticeCards';
import { Content, Container, Main, NextClassContainer } from './styles';

import 'react-multi-carousel/lib/styles.css';
import { getNotices } from '../../services/courses';
import Loading from '../../components/Loading';

const Annotation: React.FC = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const noticesData = () => {
      axios;
      getNotices().then((result) => {
        setNotices(result);
      });
    };
    noticesData();
  }, []);

  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowLayout(true), 1000);
  });

  return (
    <Content>
      {!showLayout && <Loading />}
      <Main>
        <Container>
          <NextClassContainer>
            <span>Avisos</span>
            <Row>
              {notices.map((notice) => (
                <Col md="12">
                  <NoticeCard content={notice} />
                </Col>
              ))}
            </Row>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Annotation;
