import React from 'react';

import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CardContainer } from './styles';

import resolvedIcon from '../../assets/icons/resolvedIcon.svg';

interface ForumCardProp {
  type?: any;
  title: string;
  description: string;
  text: string;
}

const ForumCard: React.FC<ForumCardProp> = () => {
  return (
    <CardContainer>
      <Row>
        <Col md="12">
          <Card className="class-card">
            <Card.Body className="class-body">
              <Row>
                <Col className="class-inside-card-information " md="9">
                  <Row>
                    <Col className="class-title" md="12">
                      <Card.Title>
                        <strong>Dúvida - </strong>Não consegui acessar a aula
                      </Card.Title>
                    </Col>
                    <Col className="class-text" md="12">
                      <p>
                        <strong>Tópico: </strong>Login
                      </p>
                    </Col>
                    <Col className="class-text" md="12">
                      <p>
                        Fiz meu cadastro corretamente e não consegui acessar
                        minha aula, visto que me passaram o horário 19h:00 e
                        acessei alguns minutos antes, aparece como indisponível.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={{ span: 3 }}>
                  <Row>
                    <Col className="class-date" md="12">
                      <div>
                        Recebido dia <b>20/03/2021</b>
                      </div>
                    </Col>
                    <Col md="12">
                      <Link to="/forum/topic">
                        <Button className="class-button-edit">Editar</Button>
                      </Link>
                    </Col>
                    <Col md="12">
                      <Link to="/forum/topic/25">
                        <Button className="class-button-seemore">
                          Ver mais
                        </Button>
                      </Link>
                    </Col>
                    <Col md="12">
                      <Row>
                        <Col className="class-icon" md="2">
                          <img src={resolvedIcon} alt="Resolved Icon" />
                        </Col>
                        <Col className="class-alert" md="10">
                          <p>Resolvido</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default ForumCard;
