import React from 'react';

interface SvgIcon {
  color?: string;
}

const EditCircleIcon: React.FC<SvgIcon> = ({ color = 'grey' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.51 0.0341797C5.98351 0.0341797 0.708008 5.30968 0.708008 11.8362C0.708008 18.3627 5.98351 23.6382 12.51 23.6382C19.0365 23.6382 24.312 18.3627 24.312 11.8362C24.312 5.30968 19.0365 0.0341797 12.51 0.0341797ZM16.1686 6.0178C16.3339 6.0178 16.4991 6.07681 16.6407 6.20663L18.1396 7.70549C18.411 7.96513 18.411 8.3782 18.1396 8.62604L16.9594 9.80624L14.54 7.38683L15.7202 6.20663C15.8382 6.07681 16.0034 6.0178 16.1686 6.0178ZM13.8436 8.07135L16.2749 10.5026L9.12284 17.6546H6.69163V15.2234L13.8436 8.07135Z"
        fill={color}
      />
    </svg>
  );
};

export default EditCircleIcon;
