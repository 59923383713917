import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { CardContainer } from './styles';

const NoteCard = ({ content }) => {
  return (
    <CardContainer>
      <div className="tab-out">
        <div>
          Recebido dia{' '}
          <b>
            {moment(content.updated_at, 'YYYY-MM-DD HH:mm:ss').format(
              'DD/MM/YYYY',
            )}
          </b>
        </div>
      </div>
      <Row>
        <Col md="12">
          <Card className="class-card">
            <Card.Body className="class-body">
              <Row>
                <Col md="12">
                  <Card.Title>
                    <p>{content.title}</p>
                  </Card.Title>
                </Col>
                <Col md="12">
                  <div
                    className="class-description"
                    /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default NoteCard;
