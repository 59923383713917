import styled from 'styled-components';

export const Content = styled.div``;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  min-height: 100%;

  @media (max-width: 650px) {
    position: relative;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column !important;
  min-height: calc(100vh - 170px);
  width: 1280px;
  .input-class {
    background: #dcdcdc;
    cursor: default;
  }
`;

export const NotFoundContainer = styled.div`
  text-align: center;

  img {
    width: 50%;
  }

  .title-description {
    font-size: 35px;
    font-weight: 600;
  }

  .text-description {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
  }

  .button-container {
    margin-top: 50px;
    width: 200px;
  }

  @media (max-width: 650px) {
    img {
      width: 100%;
    }

    .title-description {
      font-size: 25px;
      font-weight: 600;
    }

    .text-description {
      font-size: 15px;
      font-weight: 600;
    }

    .button-container {
      margin-top: 50px;
      width: 200px;
    }
  }
`;

export const NextClassContainer = styled.section`
  width: 90%;
  margin-top: 3%;
  text-align: left;
  margin-left: 5%;

  > span {
    font-weight: bold;
    font-size: 24px;
    color: #373f49;
  }
`;
