import styled from 'styled-components';
import greenTagKey from '../../assets/images/tag-ead-green/key.svg';
import greenTagBody from '../../assets/images/tag-ead-green/body.svg';
import blueTagKey from '../../assets/images/tag-ead-blue/key.svg';
import blueTagBody from '../../assets/images/tag-ead-blue/body.svg';
import whiteTagKey from '../../assets/images/tag-ead-white/key.svg';
import whiteTagBody from '../../assets/images/tag-ead-white/body.svg';

interface TagContainerProp {
  props?: any;
}

const getTagType = (type: any) => {
  switch (type) {
    case 'green':
      return {
        key: greenTagKey,
        body: greenTagBody,
        color: '#fff',
      };
    case 'blue':
      return {
        key: blueTagKey,
        body: blueTagBody,
        color: '#fff',
      };
    case 'white':
      return {
        key: whiteTagKey,
        body: whiteTagBody,
        color: '#ADC3D9',
      };
    default:
      return null;
  }
};

export const TagContainer = styled.div<TagContainerProp>`
  height: ${(props) => (props.width ? `${props.width * 0.6}px` : '120px')};
  width: ${(props) => (props.width ? `${props.width}px` : '200px')};
  cursor: pointer;
  .key-tag {
    height: 100%;
    width: 100%;
    float: left;
    background: ${(props) => `url(${getTagType(props.type)?.key})`};
    background-repeat: no-repeat;
    background-size: 40% 100%;
    background-position-x: left;

    .container-text {
      margin: 12% 0 0 33%;
      color: ${(props) => getTagType(props.type)?.color};
      padding-right: 15px;
      h4 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 11.2912px;
        line-height: 14px;
        align-items: center;
        margin-bottom: 20px;

        span {
          float: right;
          svg {
            height: 22px !important;
            width: 22px !important;
          }
        }
      }
      p {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
      }
    }
  }
  .body-tag {
    height: 100%;
    width: 100%;
    background: ${(props) => `url(${getTagType(props.type)?.body})`};
    background-repeat: no-repeat;
    background-size: 90% 90%;
    background-position-y: center;
    background-position-x: right;
  }
`;
