import React, { createContext, useCallback, useState, useContext } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [currentCourse, setCurrentCourse] = useState(() => {
    const currentCourseData = localStorage.getItem('@UniCFC:CurrentCourse');
    return JSON.parse(currentCourseData);
  });

  const [currentModule, setCurrentModule] = useState(() => {
    const currentModuleData = localStorage.getItem('@UniCFC:CurrentModule');
    return JSON.parse(currentModuleData);
  });

  const [currentLeasson, setCurrentLeasson] = useState(() => {
    const currentLeassonData = localStorage.getItem('@UniCFC:CurrentLeasson');
    return JSON.parse(currentLeassonData);
  });
  const [currentUnit, setCurrentUnit] = useState(() => {
    const currentUnitData = localStorage.getItem('@UniCFC:CurrentUnit');
    return JSON.parse(currentUnitData);
  });
  const [currentUnitExamContent, setCurrentUnitExamContent] = useState(() => {
    const currentUnitData = localStorage.getItem(
      '@UniCFC:CurrentUnitExamContent',
    );
    return JSON.parse(currentUnitData);
  });
  const [currentUnitExamResult, setCurrentUnitExamResult] = useState(() => {
    const currentUnitData = localStorage.getItem(
      '@UniCFC:CurrentUnitExamResult',
    );
    return JSON.parse(currentUnitData);
  });
  const [showCongratulationModal, setShowCongratulationModal] = useState({
    show: false,
  });

  const addCourse = useCallback(
    (dataCourse) => {
      localStorage.setItem('@UniCFC:CurrentCourse', JSON.stringify(dataCourse));
      setCurrentCourse(dataCourse);
    },
    [setCurrentCourse],
  );

  const addModule = useCallback(
    (dataModule) => {
      localStorage.setItem('@UniCFC:CurrentModule', JSON.stringify(dataModule));
      setCurrentModule(dataModule);
    },
    [setCurrentModule],
  );

  const addUnit = useCallback(
    (dataUnit) => {
      localStorage.setItem('@UniCFC:CurrentUnit', JSON.stringify(dataUnit));
      setCurrentUnit(dataUnit);
    },
    [setCurrentUnit],
  );

  const addLeasson = useCallback(
    (dataLeasson) => {
      localStorage.setItem(
        '@UniCFC:CurrentLeasson',
        JSON.stringify(dataLeasson),
      );
      setCurrentLeasson(dataLeasson);
    },
    [setCurrentLeasson],
  );

  const addUnitExamContent = useCallback(
    (dataUnitExamContent) => {
      localStorage.setItem(
        '@UniCFC:CurrentUnitExamContent',
        JSON.stringify(dataUnitExamContent),
      );
      setCurrentUnitExamContent(dataUnitExamContent);
    },
    [setCurrentUnitExamContent],
  );

  const addUnitExamResult = useCallback(
    (dataUnitExamResult) => {
      localStorage.setItem(
        '@UniCFC:CurrentUnitExamResult',
        JSON.stringify(dataUnitExamResult),
      );
      setCurrentUnitExamResult(dataUnitExamResult);
    },
    [setCurrentUnitExamResult],
  );

  const addShowCongratulationModal = useCallback(
    (dataShowCongratulationModal) => {
      localStorage.setItem(
        '@UniCFC:ShowCongratulationModal',
        JSON.stringify(dataShowCongratulationModal),
      );
      setShowCongratulationModal(dataShowCongratulationModal);
    },
    [setShowCongratulationModal],
  );

  return (
    <AppContext.Provider
      value={{
        currentCourse,
        setCurrentCourse: addCourse,
        currentModule,
        setCurrentModule: addModule,
        currentUnit,
        setCurrentUnit: addUnit,
        currentLeasson,
        setCurrentLeasson: addLeasson,
        currentUnitExamContent,
        setCurrentUnitExamContent: addUnitExamContent,
        currentUnitExamResult,
        setCurrentUnitExamResult: addUnitExamResult,
        showCongratulationModal,
        setShowCongratulationModal: addShowCongratulationModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  const context = useContext(AppContext);
  return context;
}

export default AppContext;
