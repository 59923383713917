import React from 'react';

interface SvgIcon {
  color: string;
}

const PinIcon: React.FC<SvgIcon> = ({ color }) => {
  return (
    <svg
      width="24"
      height="34"
      viewBox="0 0 24 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60873 5.92614C3.653 2.39824 7.28406 0.242916 11.3219 0.160547C11.4943 0.156995 11.6677 0.156995 11.8402 0.160547C15.8779 0.242916 19.509 2.39824 21.5533 5.92614C23.6429 9.53219 23.7001 13.8638 21.7063 17.5132L13.3535 32.8018C13.3497 32.8086 13.346 32.8153 13.3421 32.8221C12.9746 33.4608 12.3162 33.8421 11.5809 33.8421C10.8456 33.8421 10.1873 33.4607 9.81985 32.8221C9.81596 32.8153 9.81221 32.8086 9.80846 32.8018L1.45563 17.5132C-0.53805 13.8638 -0.480879 9.53219 1.60873 5.92614ZM11.581 15.4211C14.1929 15.4211 16.3179 13.2961 16.3179 10.6842C16.3179 8.07232 14.1929 5.94739 11.581 5.94739C8.96912 5.94739 6.84419 8.07232 6.84419 10.6842C6.84419 13.2961 8.96906 15.4211 11.581 15.4211Z"
        fill={color}
      />
    </svg>
  );
};

export default PinIcon;
