import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';

import { Link } from 'react-router-dom';
import ForumCard from '../../components/ForumCard';
import ForumCardBlue from '../../components/ForumCardBlue';
import searchIcon from '../../assets/icons/search.svg';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NoteStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';

const Forum: React.FC = () => {
  const [, setForumData] = useState([]);

  useEffect(() => {
    const newsForumData = () => {
      axios
        .get('https://z0nmx78ryk.execute-api.us-east-2.amazonaws.com/dev/')
        .then((result) => {
          const getForumData = result.data;
          setForumData(getForumData);
        });
    };
    newsForumData();
  }, []);

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <span>Forum</span>
            <Row>
              <Col md={{ span: 2, offset: 10 }} className="class-new-topic">
                <Link to="/forum/topic">
                  <Button className="class-button-new-topic">
                    Novo Tópico
                  </Button>
                </Link>
              </Col>
              <Col md="11">
                <Row>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        type="text"
                        placeholder="Buscar Tópico"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{
                          background: '#F7F9FC',
                        }}
                        as="select"
                      >
                        <option>Tipo</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        as="select"
                      >
                        <option>Tópico</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Col className="class-search" md="12">
                  <Button className="class-search-button">
                    <img src={searchIcon} alt="searchIcon" />
                  </Button>
                </Col>
              </Col>
            </Row>
            <NoteStyled>
              {/* {forumData.map((value: any) => {
                return ( */}
              <Row>
                <Col md="12">
                  <ForumCard
                    title="teste"
                    // description={value.description}
                    text="teste"
                  />
                  <ForumCardBlue
                    title="teste"
                    // description={value.description}
                    text="teste"
                  />
                </Col>
              </Row>
              {/* ); */}
              {/* })} */}
            </NoteStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Forum;
