import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  LeassonCfcStyled,
  RowLeassonStyled,
} from './styles';

import Text from '../../components/Text';

import 'react-multi-carousel/lib/styles.css';
import { useApp } from '../../hooks/app';
import {
  getEadDetail,
  getEadUnit,
  getModuleExam,
} from '../../services/courses';
import TagComponent from '../../components/TagComponent';
import LeftArrowIcon from '../../components/icons/LeftArrow/left.icon';
import RightArrowIcon from '../../components/icons/RightArrow';
import CheckCircleIcon from '../../components/icons/CheckCircle';
import EditCircleIcon from '../../components/icons/EditCircle';
import RefreshCircleIcon from '../../components/icons/RefreshCircle';
import Button from '../../components/Button';
import CfcCard from '../../components/CardCFC/cfc.history.card';
import Loading from '../../components/Loading';

const Ead: React.FC = () => {
  const carouselRef = React.useRef<Carousel>(null);
  const {
    currentCourse,
    setCurrentUnit,
    setCurrentUnitExamContent,
    currentModule,
    setCurrentModule,
  } = useApp();
  const [eadSelectDetail, setEadSelectDetail] = useState(false);
  const [modulesEad, setModulesEad] = useState(false);
  const [unitsEad, setUnitsEad] = useState([]);
  const [unitsModuleEad, setUnitsModuleEad] = useState([]);
  const [moduleExam, setModuleExam] = useState(false);
  const [moduleExamStyles, setModuleExamStyles] = useState({});

  const fModuleExamStyles = () => {
    if (currentModule.moduluse_status === 'finished') {
      setModuleExamStyles({
        checkIconColor: '#3EC1B1',
        titleColor: '#373F49',
        subtitleColor: '#3EC1B1',
        firstButtonColor: '#3EC1B1',
        buttonSolid: false,
        contentFirstButton: 'Refazer Avaliação',
        activeClickFirstButton: true,
      });
    } else if (currentModule.moduluse_status === 'evaluation') {
      setModuleExamStyles({
        checkIconColor: '#1976D2',
        titleColor: '#373F49',
        subtitleColor: '#1976D2',
        firstButtonColor: '#1976D2',
        buttonSolid: false,
        contentFirstButton: 'Iniciar Avaliação',
        activeClickFirstButton: true,
      });
    } else {
      setModuleExamStyles({
        checkIconColor: '#ADC3D9',
        titleColor: '#373F49',
        subtitleColor: '#ADC3D9',
        firstButtonColor: '#ADC3D9',
        buttonSolid: false,
        contentFirstButton: 'Não disponivel',
        activeClickFirstButton: false,
      });
    }
  };

  const getEadDetails = useCallback(async () => {
    const result = await getEadDetail(
      currentCourse.id,
      currentCourse.course_id,
    );

    if (result) {
      const [courseSelected, ModulesAndUnits] = result;
      const units = ModulesAndUnits.filter((item: any) => item[0]);
      const modules = ModulesAndUnits.filter((item: any) => !item[0]);
      setEadSelectDetail(courseSelected);
      setModulesEad(modules);
      setUnitsEad(units);
    }
  }, [currentCourse.id, currentCourse.course_id]);
  useEffect(() => {
    if (!eadSelectDetail) getEadDetails();
  }, [eadSelectDetail, getEadDetails]);

  useEffect(() => {
    if (currentModule) {
      getModuleExam({
        student_id: currentCourse.student_id,
        moduluse_id: currentModule.id,
        course_id: currentModule.course_id,
        matriculation_id: 1,
      }).then((result) => {
        if (result) setModuleExam(result);
      });
    }
    fModuleExamStyles();
  }, [currentCourse.student_id, currentModule]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2300 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 2300, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const buildTable = (unitContent: any) => {
    let content: any;
    const status = unitContent.unit_status
      ? unitContent.unit_status
      : 'pending';
    switch (status) {
      case 'ready':
        content = {
          checkIconColor: '#1976D2',
          titleColor: '#373F49',
          subtitleColor: '#1976D2',
          firstButtonColor: '#1976D2',
          secondButtonColor: '#ADC3D9',
          buttonSolid: false,
          contentFirstButton: 'Estudar',
          contentSecondButton: 'Iniciar Avaliação',
          showFirstButton: 'true',
          showSecondButton: 'true',
          activeClickFirstButton: true,
          activeClickSecondButton: false,
        };
        break;
      case 'evaluation':
        content = {
          checkIconColor: '#1976D2',
          titleColor: '#373F49',
          subtitleColor: '#1976D2',
          firstButtonColor: '#1976D2',
          secondButtonColor: '#1976D2',
          buttonSolid: false,
          contentFirstButton: 'Revisar',
          contentSecondButton: 'Iniciar Avaliação',
          showFirstButton: 'true',
          showSecondButton: 'true',
          activeClickFirstButton: true,
          activeClickSecondButton: true,
        };
        break;
      case 'retry':
        content = {
          checkIconColor: '#1976D2',
          titleColor: '#373F49',
          subtitleColor: '#1976D2',
          firstButtonColor: '#1976D2',
          secondButtonColor: '#1976D2',
          buttonSolid: false,
          contentFirstButton: 'Revisar',
          contentSecondButton: 'Refazer Avaliação',
          showFirstButton: 'true',
          showSecondButton: 'true',
          activeClickFirstButton: true,
          activeClickSecondButton: true,
        };
        break;
      case 'finished':
        content = {
          checkIconColor: '#3EC1B1',
          titleColor: '#373F49',
          subtitleColor: '#3EC1B1',
          firstButtonColor: '#3EC1B1',
          secondButtonColor: '#3EC1B1',
          buttonSolid: false,
          contentFirstButton: 'Revisar',
          contentSecondButton: 'Refazer Avaliação',
          showFirstButton: 'true',
          showSecondButton: 'true',
          activeClickFirstButton: true,
          activeClickSecondButton: true,
        };
        break;
      case 'pending':
        content = {
          checkIconColor: '#ADC3D9',
          titleColor: '#ADC3D9',
          subtitleColor: '#ADC3D9',
          firstButtonColor: '#ADC3D9',
          secondButtonColor: '#ADC3D9',
          buttonSolid: false,
          contentFirstButton: 'Estudar',
          contentSecondButton: '',
          showFirstButton: 'true',
          showSecondButton: 'false',
          activeClickFirstButton: false,
          activeClickSecondButton: false,
        };
        break;
      default:
        content = {
          checkIconColor: '#ADC3D9',
          titleColor: '#373F49',
          subtitleColor: '#FF5722',
          firstButtonColor: '#FF5722',
          secondButtonColor: '#FF5722',
          buttonSolid: true,
        };
        break;
    }

    const [indexUnit, title] = unitContent.name.split(' - ');
    return (
      <RowLeassonStyled content={content}>
        <Row className="row-table">
          <Col md="1" xs="1">
            <CheckCircleIcon color={content.checkIconColor} />
            <div>
              <div />
            </div>
          </Col>
          <Col md="6" xs="11">
            Aula {indexUnit} -{' '}
            <span className="destaque-subtitle">{title}</span>
          </Col>
          <Col md="5" xs="12">
            {content.showFirstButton === 'true' && (
              <Link
                to={content.activeClickFirstButton && '/course/ead/content'}
              >
                <Button
                  width="200px"
                  color={
                    content.buttonSolid ? '#fff' : content.firstButtonColor
                  }
                  backgroundColor={content.firstButtonColor}
                  outerline
                  hoverOff
                  onClick={() => handleContentOrExam(unitContent)}
                >
                  <RefreshCircleIcon
                    color={
                      content.buttonSolid ? '#fff' : content.firstButtonColor
                    }
                  />{' '}
                  {content.contentFirstButton}
                </Button>
              </Link>
            )}
            {content.showSecondButton === 'true' && (
              <Link
                to={
                  content.activeClickSecondButton && '/course/ead/content/exam'
                }
              >
                <Button
                  width="200px"
                  color={
                    content.buttonSolid ? '#fff' : content.secondButtonColor
                  }
                  backgroundColor={content.secondButtonColor}
                  outerline={!content.buttonSolid}
                  hoverOff
                  onClick={() => handleContentOrExam(unitContent)}
                >
                  <EditCircleIcon
                    color={
                      content.buttonSolid ? '#fff' : content.secondButtonColor
                    }
                  />{' '}
                  {content.contentSecondButton}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </RowLeassonStyled>
    );
  };

  const nextTag = () => {
    if (carouselRef.current?.state.currentSlide !== undefined) {
      const nextSlide = carouselRef.current?.state.currentSlide + 1;
      carouselRef.current.goToSlide(nextSlide);
    }
  };

  const previousTag = () => {
    if (carouselRef.current?.state.currentSlide !== undefined) {
      const previousSlide = carouselRef.current?.state.currentSlide - 1;
      if (previousSlide >= 0) carouselRef.current.goToSlide(previousSlide);
    }
  };

  const handleContentOrExam = async (data: any) => {
    const result = await getEadUnit(
      currentCourse.id,
      currentCourse.course_id,
      currentModule.id,
      data.id,
    );
    if (result) setCurrentUnit(result);
  };

  const handleSelectModule = (module: any) => {
    const unitsModuleSelected = unitsEad.filter(
      (unit) => unit[0]?.moduluse_id === module,
    );
    const moduleSelected = modulesEad.find((item: any) => item.id === module);
    setCurrentModule(moduleSelected);
    setUnitsModuleEad(unitsModuleSelected[0]);
  };

  console.log(unitsModuleEad);

  return modulesEad ? (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <LeassonCfcStyled>
              <Row className="header-container">
                <Col md="12">
                  <Text fontSize={16}>Aula ao vivo</Text>
                </Col>
                <Col md="12">
                  <CfcCard content={currentCourse} />
                </Col>
                <Col md="12">
                  <Row className="carrousel-tag">
                    <Col md="1" xs="2">
                      <LeftArrowIcon onClick={previousTag} />
                    </Col>
                    <Col md="10" xs="8">
                      <Carousel
                        responsive={responsive}
                        ref={carouselRef}
                        arrows={false}
                        containerClass="container-tag"
                      >
                        {modulesEad &&
                          modulesEad.map((module: any) => {
                            return (
                              <TagComponent
                                module={module}
                                setModuleSelected={handleSelectModule}
                              />
                            );
                          })}
                      </Carousel>
                    </Col>
                    <Col md="1" xs="2">
                      <RightArrowIcon onClick={nextTag} />
                    </Col>
                  </Row>
                </Col>
                <Col md="12">
                  {unitsModuleEad &&
                    unitsModuleEad.map((unit) => buildTable(unit))}
                  {unitsModuleEad.length > 0 && (
                    <RowLeassonStyled content={moduleExamStyles}>
                      <Row className="row-table">
                        <Col md="1" xs="1">
                          <CheckCircleIcon
                            color={moduleExamStyles.checkIconColor}
                          />
                        </Col>
                        <Col md="6" xs="11">
                          <span className="destaque-subtitle">
                            Avaliação do Módulo
                          </span>
                        </Col>
                        <Col md="5" xs="12">
                          <Link
                            to={
                              moduleExamStyles.activeClickFirstButton &&
                              '/course/ead/content/exam'
                            }
                          >
                            <Button
                              width="200px"
                              color={moduleExamStyles.firstButtonColor}
                              backgroundColor={
                                moduleExamStyles.firstButtonColor
                              }
                              outerline
                              hoverOff
                              onClick={() => {
                                setCurrentUnitExamContent({
                                  type: 'module',
                                  content: moduleExam,
                                });
                              }}
                            >
                              <EditCircleIcon
                                color={moduleExamStyles.firstButtonColor}
                              />{' '}
                              {moduleExamStyles.contentFirstButton}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </RowLeassonStyled>
                  )}
                </Col>
              </Row>
            </LeassonCfcStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  ) : (
    <Loading />
  );
};

export default Ead;
