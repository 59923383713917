import styled from 'styled-components';

export const JustificationScreen = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;

  .justification-background {
    height: 100%;
    width: 100%;
    background: #23476b;
    opacity: 0.9;
  }

  .justification-container {
    button {
      width: 30%;
      float: right;
      margin: 5px 0px 0 0;
    }

    .close-btn {
      background: unset;
      width: initial;
      position: absolute;
      top: -60px;
      color: #fff;
      right: 5px;
      border: 1px solid #fff;
      padding: 3px 8px;
      border-radius: 50px;
    }

    width: 650px;
    height: 395px;
    position: absolute;
    background: #fff;
    border-radius: 15px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    .icon-container {
      > div:nth-child(1) {
        color: #ff5722;
        font-size: 75px;
        svg {
          vertical-align: baseline;
        }
      }
      > div:nth-child(2) h3 {
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
      }
    }

    .timer-container {
      > div:nth-child(1) {
        display: flex;
        align-items: flex-end;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #373f49;
      }
      > div:nth-child(2) div {
        text-align: center;
        span:first-child {
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 11px;
          letter-spacing: 0em;
          text-align: left;
          color: #373f49;
        }
        span:last-child > div > span {
          font-family: Inter;
          font-size: 32px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: #373f49 !important;
        }
      }
    }

    .form-container {
      margin-top: 20px;
    }
  }
`;
