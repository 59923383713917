import React from 'react';

interface SvgIcon {
  color?: string;
}

const CheckCircleIcon: React.FC<SvgIcon> = ({ color = 'grey' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 0C5.28304 0 0 5.28304 0 11.8C0 18.317 5.28304 23.6 11.8 23.6C18.317 23.6 23.6 18.317 23.6 11.8C23.5931 5.28596 18.3141 0.00696346 11.8 0ZM18.2959 8.18162L9.86732 16.6102C9.53816 16.9393 9.00464 16.9393 8.67553 16.6102L5.30408 13.2388C4.96924 12.9154 4.95996 12.3818 5.28334 12.047C5.60672 11.7121 6.14029 11.7029 6.47513 12.0262C6.48214 12.0331 6.48905 12.0399 6.49587 12.047L9.27143 14.8225L17.1041 6.98983C17.439 6.66645 17.9725 6.67573 18.2959 7.01052C18.6114 7.33716 18.6114 7.85498 18.2959 8.18162Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckCircleIcon;
