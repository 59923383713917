import React from 'react';

import { Row, Col, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Image from '../../assets/images/leasson.svg';
import checkIcon from '../../assets/images/checkCircle.svg';
import { useApp } from '../../hooks/app';
import Button from '../Button';

import './lesson.css';

interface EadCardComponentProp {
  type?: string;
  content?: any;
}

const EadCardComponent: React.FC<EadCardComponentProp> = ({ content }) => {
  const { setCurrentCourse } = useApp();
  const type = '';
  const TimerType = () => {
    switch (type) {
      case 'finished':
        return (
          <>
            <Col md="6" className="leasson-finished">
              <img src={checkIcon} alt="concluido" />
              <span>Conluído</span>
            </Col>
            <Col md="6" className="leasson-btn-certifield">
              <Button
                hoverColor="linear-gradient(117.12deg, #3EC1B1 53.53%, #FDFEFF 112.46%)"
                color="#FFF"
                backgroundColor="#3EC1B1"
              >
                Certificado
              </Button>
            </Col>
          </>
        );
      default:
        return (
          <Col md="12" className="leasson-progress">
            <ProgressBar now={content.progress} variant="original-uni" />
            <span>{content.progress}%</span>
          </Col>
        );
    }
  };

  const ButtonType = () => {
    switch (type) {
      case 'finished':
        return (
          <Col md="4" className="lesson-in-Time">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
            <Link to="/course/ead">
              <Button
                hoverColor="linear-gradient(117.12deg, #1976D2 53.53%, #FDFEFF 112.46%)"
                color="#FFF"
                backgroundColor="#1976D2"
                onClick={() => setCurrentCourse(content)}
              >
                Revisar
              </Button>
            </Link>
          </Col>
        );
      default:
        return (
          <Col md="4" className="lesson-started">
            <span>
              Iniciado em{' '}
              <b>
                {moment(content.activation_date, 'YYYY-MM-DD HH:mm:ss').format(
                  'DD/MM/YYYY',
                )}
              </b>
            </span>
            <Link to="/course/ead">
              <Button
                hoverColor="linear-gradient(117.12deg, #1976D2 53.53%, #FDFEFF 112.46%)"
                color="#FFF"
                backgroundColor="#1976D2"
                onClick={() => setCurrentCourse(content)}
              >
                Revisar
              </Button>
            </Link>
          </Col>
        );
    }
  };

  return (
    <section className="Lesson">
      <div className="LessonContainer">
        <div className="LessonContent">
          <Row>
            <Col md="3">
              <img src={Image} alt="Primeira Habilitação" />
            </Col>
            <Col md="5" className="LessonDetails">
              <Row>
                <Col md="12">
                  <h1>{content.course_name}</h1>
                </Col>
                <TimerType />
              </Row>
            </Col>
            <ButtonType />
          </Row>
          <div>
            <div />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EadCardComponent;
