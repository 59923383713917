import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

// import ForumCard from '../../components/ForumCard';
// import ForumCardBlue from '../../components/ForumCardBlue';
// import searchIcon from '../../assets/icons/search.svg';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  NoteStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';

const Forum: React.FC = () => {
  const [forumData, setForumData] = useState([]);  /* eslint-disable-line */

  useEffect(() => {
    const newsForumData = () => {
      axios
        .get('https://z0nmx78ryk.execute-api.us-east-2.amazonaws.com/dev/')
        .then((result) => {
          const getForumData = result.data;
          setForumData(getForumData);
        });
    };
    newsForumData();
  }, []);

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <span>Forum UNICFC EAD - Novo tópico</span>
            <span style={{ color: '#1976d2' }}>Novo tópico</span>
            <Row>
              <Col md="12">
                <Row>
                  <Col className="class-name-title" md="1">
                    <div>Título</div>
                  </Col>
                  <Col md="11" className="class-new-title">
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="class-resume" md="1">
                    <div>Resumo</div>
                  </Col>
                  <Col md="11" className="class-new-title">
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        as="select"
                      >
                        <option>Tipo</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{
                          background: '#F7F9FC',
                        }}
                        as="select"
                      >
                        <option>Tópico</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 4 }}>
                    <Form.Group controlId="formGridName">
                      <Form.Control
                        style={{ background: '#F7F9FC' }}
                        as="select"
                      >
                        <option>Status</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <NoteStyled>
              {/* {forumData.map((value: any) => {
                return ( */}
              <Row>
                <Col className="class-name-description" md="12">
                  <span>Descrição</span>
                </Col>
                <Col className="class-editor" md="12">
                  <Editor
                    initialValue=""
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
                    }}
                  />
                </Col>
                <Col md="6">
                  <Button className="class-first-button">Voltar</Button>
                </Col>
                <Col
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  md="6"
                >
                  <Button className="class-second-button">Criar Tópico</Button>
                </Col>
              </Row>
              {/* ); */}
              {/* })} */}
            </NoteStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Forum;
