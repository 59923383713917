import styled from 'styled-components';

interface ITextComponent {
  fontSize?: number;
  fontWeight?: string;
  color?: string;
}

export const TextComponent = styled.span<ITextComponent>`
  font-family: 'Inter', sans-serif;
  color: ${(props) => props.color && props.color};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-size: ${(props) => props.fontSize && `${props.fontSize}px`};
`;
