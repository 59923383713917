import React from 'react';

interface SvgIcon {
  color: string;
}

const InstructorIcon: React.FC<SvgIcon> = ({ color }) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.97411 0.605263C4.67641 0.605964 1.99402 3.28836 1.99402 6.58605C1.99402 9.88374 4.67641 12.5655 7.97411 12.5655C11.2718 12.5655 13.9542 9.88312 13.9542 6.58543C13.9542 3.28766 11.2718 0.605263 7.97411 0.605263ZM7.97411 10.5728C5.77214 10.5728 3.98738 8.78802 3.98738 6.58605C3.98738 4.38409 5.77214 2.59933 7.97411 2.59933C10.1761 2.59933 11.9608 4.38409 11.9608 6.58605C11.9608 8.78802 10.1761 10.5728 7.97411 10.5728Z"
        fill={color}
      />
      <path
        d="M37.8746 10.5721H17.941C16.84 10.5721 15.9476 11.4644 15.9476 12.5654V14.0877L17.941 15.0844V12.5654H37.8746V28.5116H17.941V26.1422L15.9476 25.6445V28.5116C15.9476 29.6127 16.84 30.505 17.941 30.505H26.1484L21.0732 38.9629C20.7889 39.434 20.943 40.0472 21.4161 40.3296C21.8832 40.616 22.4985 40.4619 22.7828 39.9868L26.9111 33.1057V39.4745C26.9111 40.0254 27.357 40.4712 27.9078 40.4712C28.4586 40.4712 28.9045 40.0254 28.9045 39.4745V33.1057L33.0334 39.9862C33.2201 40.2978 33.551 40.4706 33.8879 40.4706C34.0633 40.4706 34.2401 40.426 34.4002 40.3284C34.8733 40.046 35.0268 39.4327 34.743 38.9616L29.6679 30.5038H37.8746C38.9756 30.5038 39.8679 29.6114 39.8679 28.5104V12.5654C39.868 11.4644 38.9757 10.5721 37.8746 10.5721Z"
        fill={color}
      />
      <path
        d="M34.8453 16.2778C34.6951 15.7482 34.1443 15.4366 33.6134 15.5947L21.3136 19.109C21.1701 18.9702 21.0093 18.8486 20.8259 18.7562L12.8525 14.7695C12.5755 14.6313 12.2705 14.5589 11.9608 14.5589H3.98733C3.0239 14.5589 2.1986 15.2479 2.02589 16.1955L0.0325305 27.1583C-0.0731333 27.7397 0.0843891 28.3383 0.463128 28.7921C0.841867 29.2459 1.40266 29.5084 1.99405 29.5084H3.26777L3.99403 38.636C4.07641 39.6725 4.94087 40.4712 5.98077 40.4712H9.9675C10.9834 40.4712 11.8366 39.7077 11.9489 38.6984L13.6904 23.0259L19.4512 24.4658C19.6107 24.5057 19.7728 24.5249 19.9343 24.5249C20.3742 24.5249 20.8067 24.3794 21.1609 24.103C21.6446 23.7255 21.9277 23.1454 21.9277 22.5316V21.0053L34.1622 17.5096C34.6918 17.3595 34.9974 16.8066 34.8453 16.2778ZM19.9342 22.5323L11.9608 20.5389L9.96742 38.4785H5.98069L5.10891 27.515H1.99397L3.98733 16.5522H6.97737V22.616C6.97737 23.1669 7.42323 23.6127 7.97405 23.6127C8.52488 23.6127 8.97074 23.1669 8.97074 22.616V16.5522H11.9608L19.9342 20.5389V22.5323Z"
        fill={color}
      />
    </svg>
  );
};

export default InstructorIcon;
