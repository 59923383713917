import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Row, Col } from 'react-bootstrap';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  LibraryStyled,
} from './styles';

import HandoutCard from '../../components/CardHandout';
import Loading from '../../components/Loading';

const Library: React.FC = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const libraryData = () => {
      axios
        .get('https://beuurmqd4k.execute-api.us-east-2.amazonaws.com/dev/')
        .then((result) => {
          const getDataLibrary = result.data;
          setBooks(getDataLibrary);
        });
    };
    libraryData();
  }, []);

  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowLayout(true), 1000);
  });

  return (
    <Content>
      {!showLayout && <Loading />}
      <Main>
        <Container>
          <NextClassContainer>
            <span>Biblioteca</span>
            <LibraryStyled>
              <Row>
                {books.map((book) => {
                  return (
                    <Col md="6" key={book.id}>
                      <HandoutCard content={book} />
                    </Col>
                  );
                })}
              </Row>
            </LibraryStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Library;
