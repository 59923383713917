import React from 'react';

const CheckIcon: React.FC = () => {
  return (
    <svg
      width="215"
      height="203"
      viewBox="0 0 215 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M118.49 202.414C171.378 202.414 214.252 159.442 214.252 106.434C214.252 53.4261 171.378 10.4546 118.49 10.4546C65.6026 10.4546 22.7285 53.4261 22.7285 106.434C22.7285 159.442 65.6026 202.414 118.49 202.414Z"
          fill="#3EC1B1"
        />
        <path
          d="M36.9733 30.2714C36.8139 30.2714 36.6544 30.2049 36.5348 30.0717C36.3355 29.832 36.3754 29.4724 36.6146 29.2726C45.0653 22.2809 54.5259 16.8473 64.7704 13.0785C75.3605 9.18975 86.5085 7.21875 97.8958 7.21875C115.688 7.21875 133.067 12.1196 148.162 21.3886C148.428 21.5484 148.507 21.8947 148.348 22.161C148.188 22.4274 147.843 22.5073 147.577 22.3475C132.655 13.1983 115.475 8.35074 97.8825 8.35074C75.8255 8.35074 54.3133 16.0882 37.3321 30.1383C37.2258 30.2315 37.0929 30.2714 36.9733 30.2714Z"
          fill="white"
        />
        <path
          d="M36.9739 30.937C36.6019 30.937 36.2564 30.7772 36.0305 30.4843C35.8179 30.2312 35.7249 29.9116 35.7515 29.592C35.7781 29.259 35.9375 28.966 36.19 28.7663C44.6939 21.7346 54.2342 16.2478 64.5319 12.4656C75.2016 8.55023 86.4161 6.56592 97.8831 6.56592C115.794 6.56592 133.307 11.5067 148.508 20.8423C148.787 21.0155 148.986 21.2818 149.066 21.6014C149.146 21.9211 149.093 22.254 148.92 22.5337C148.747 22.8133 148.481 23.0131 148.162 23.093C147.844 23.1729 147.511 23.1196 147.232 22.9465C132.417 13.824 115.356 9.01635 97.8831 9.01635C75.9723 9.01635 54.6195 16.7006 37.7446 30.6574C37.532 30.8305 37.253 30.937 36.9739 30.937Z"
          fill="white"
        />
        <path
          d="M20.3907 160.73C20.2179 160.73 20.0452 160.65 19.9389 160.504C7.91385 143.937 1.5625 124.32 1.5625 103.771C1.5625 94.9147 2.75836 86.1384 5.12351 77.6951C5.20323 77.4021 5.52213 77.2156 5.81445 77.3088C6.10677 77.3887 6.29279 77.7084 6.19978 78.0014C3.8745 86.3515 2.69192 95.0212 2.69192 103.771C2.69192 124.08 8.97684 143.47 20.8557 159.838C21.0417 160.091 20.9886 160.437 20.7361 160.624C20.6165 160.69 20.5102 160.73 20.3907 160.73Z"
          fill="white"
        />
        <path
          d="M20.391 161.396C20.0056 161.396 19.6336 161.209 19.3944 160.89C7.28965 144.203 0.898438 124.453 0.898438 103.771C0.898438 94.8478 2.10759 86.0182 4.47273 77.5083C4.56574 77.1887 4.76505 76.9356 5.05737 76.7625C5.3497 76.6027 5.68188 76.5628 5.98749 76.656C6.30638 76.7492 6.57213 76.949 6.73158 77.2419C6.89103 77.5216 6.93089 77.8546 6.83788 78.1742C4.52588 86.471 3.34331 95.0742 3.34331 103.771C3.34331 123.933 9.57507 143.19 21.3742 159.438C21.7728 159.984 21.6533 160.756 21.1085 161.156C20.8959 161.316 20.6567 161.396 20.391 161.396Z"
          fill="white"
        />
        <path
          d="M68.2516 195.621C68.1985 195.621 68.1321 195.608 68.0789 195.595C58.9771 192.625 50.3802 188.323 42.5274 182.783C42.2749 182.61 42.2085 182.251 42.3945 181.998C42.5672 181.745 42.926 181.678 43.1784 181.864C50.9382 187.338 59.4421 191.6 68.4377 194.529C68.73 194.623 68.8894 194.942 68.7964 195.235C68.7034 195.475 68.4908 195.621 68.2516 195.621Z"
          fill="white"
        />
        <path
          d="M68.2513 196.287C68.1184 196.287 67.9988 196.261 67.8659 196.221C58.6977 193.237 50.0476 188.896 42.1416 183.316C41.5836 182.93 41.4507 182.157 41.8493 181.598C42.2346 181.039 43.0053 180.905 43.5634 181.305C51.27 186.738 59.7075 190.973 68.6366 193.877C69.2744 194.09 69.6331 194.782 69.4205 195.422C69.2478 195.954 68.7828 196.287 68.2513 196.287Z"
          fill="white"
        />
        <path
          d="M97.8821 200.309C92.3944 200.309 86.8802 199.843 81.5121 198.924C81.2065 198.871 81.0072 198.578 81.0471 198.271C81.1002 197.965 81.3925 197.765 81.6981 197.805C86.9998 198.711 92.4476 199.177 97.8821 199.177C99.1444 199.177 100.407 199.15 101.669 199.097C101.975 199.084 102.24 199.324 102.254 199.643C102.267 199.949 102.028 200.216 101.709 200.229C100.447 200.282 99.1577 200.309 97.8821 200.309Z"
          fill="white"
        />
        <path
          d="M97.8827 200.975C92.3552 200.975 86.801 200.509 81.4064 199.577C80.742 199.47 80.2902 198.831 80.4098 198.165C80.5294 197.499 81.1539 197.046 81.8316 197.166C88.3291 198.285 95.0525 198.711 101.656 198.458C102.321 198.431 102.905 198.964 102.932 199.643C102.958 200.322 102.427 200.895 101.749 200.922C100.46 200.948 99.1716 200.975 97.8827 200.975Z"
          fill="white"
        />
        <path
          d="M189.339 132.869C189.286 132.869 189.233 132.856 189.166 132.843C188.874 132.749 188.701 132.43 188.794 132.137C191.638 122.988 193.073 113.439 193.073 103.757C193.073 90.8791 190.561 78.3739 185.592 66.6145C185.472 66.3215 185.605 66.0019 185.884 65.882C186.163 65.7621 186.495 65.8953 186.615 66.175C191.638 78.0809 194.189 90.7193 194.189 103.757C194.189 113.546 192.727 123.201 189.857 132.47C189.804 132.723 189.578 132.869 189.339 132.869Z"
          fill="white"
        />
        <path
          d="M189.339 133.535C189.219 133.535 189.086 133.522 188.967 133.482C188.661 133.389 188.409 133.176 188.249 132.883C188.09 132.59 188.063 132.257 188.17 131.951C190.987 122.868 192.422 113.386 192.422 103.771C192.422 90.9857 189.924 78.5738 184.994 66.8943C184.861 66.588 184.861 66.255 184.981 65.9487C185.1 65.6424 185.339 65.4027 185.645 65.2829C185.951 65.163 186.283 65.1497 186.588 65.2695C186.894 65.3894 187.133 65.6291 187.253 65.9354C192.315 77.9212 194.88 90.6528 194.88 103.771C194.88 113.626 193.418 123.347 190.522 132.683C190.349 133.189 189.884 133.535 189.339 133.535Z"
          fill="white"
        />
        <path
          d="M98.5867 159.744C95.2516 159.744 92.0759 158.306 89.8702 155.802L57.1435 118.553C52.9048 113.732 53.3699 106.367 58.1799 102.119C62.9899 97.8706 70.3378 98.3367 74.5765 103.158L98.0419 129.859L187.452 11.6661C191.399 6.59209 198.694 5.6865 203.756 9.64181C208.819 13.5971 209.722 20.9085 205.776 25.9825L107.755 155.256C105.629 157.986 102.4 159.637 98.9455 159.744C98.8259 159.744 98.7063 159.744 98.5867 159.744Z"
          fill="white"
        />
        <path
          d="M62.4581 133.335C62.3119 133.335 62.1525 133.282 62.0329 133.162L51.6156 122.774C49.3036 120.47 48.028 117.407 48.0148 114.131C48.0015 110.869 49.2638 107.792 51.5625 105.462C56.3061 100.681 64.0526 100.654 68.8227 105.408L93.484 129.979L162.166 34.5057C162.352 34.226 162.724 34.1594 162.99 34.3458C163.256 34.5323 163.336 34.9052 163.15 35.1715L94.082 131.258C93.9889 131.404 93.8162 131.497 93.6435 131.511C93.4707 131.524 93.298 131.471 93.1651 131.338L67.9856 106.274C63.6805 101.986 56.6914 101.999 52.3996 106.327C50.3267 108.418 49.184 111.201 49.1973 114.145C49.2106 117.088 50.3666 119.858 52.4527 121.949L62.87 132.336C63.1092 132.563 63.1092 132.949 62.87 133.175C62.7637 133.282 62.6043 133.335 62.4581 133.335Z"
          fill="#35A597"
        />
        <path
          d="M62.4584 134.001C62.1262 134.001 61.8073 133.868 61.5681 133.628L51.1509 123.241C48.706 120.817 47.364 117.581 47.3507 114.131C47.3374 110.682 48.6661 107.446 51.0977 104.995C53.516 102.572 56.7315 101.213 60.1863 101.213C60.1995 101.213 60.2128 101.213 60.2261 101.213C63.6543 101.213 66.8698 102.545 69.3014 104.955L93.4179 128.98L161.635 34.1327C161.808 33.8664 162.1 33.6799 162.432 33.6133C162.765 33.5468 163.097 33.6133 163.376 33.8131C163.655 33.9996 163.841 34.2925 163.907 34.6255C163.974 34.9584 163.907 35.2914 163.708 35.571L94.6138 131.657C94.4144 131.95 94.0823 132.15 93.6969 132.19C93.3249 132.23 92.9528 132.097 92.6871 131.83L67.5209 106.74C63.4682 102.705 56.9043 102.731 52.8782 106.78C50.925 108.751 49.862 111.361 49.8753 114.131C49.8886 116.901 50.9648 119.512 52.9314 121.456L63.3486 131.844C63.5878 132.083 63.7207 132.403 63.7207 132.736C63.7207 133.069 63.5878 133.388 63.3486 133.628C63.1095 133.868 62.7906 134.001 62.4584 134.001Z"
          fill="#35A597"
        />
        <path
          d="M95.3442 161.329C92.1286 161.329 89.0194 160.051 86.734 157.773L72.862 143.963C72.6228 143.737 72.6228 143.35 72.862 143.124C73.0879 142.884 73.4732 142.884 73.6991 143.124L87.5711 156.934C89.7369 159.092 92.7399 160.264 95.7959 160.13C95.9953 160.117 96.2078 160.104 96.4072 160.09C99.6891 159.771 102.626 158.026 104.473 155.296L130.582 116.662C130.768 116.382 131.14 116.316 131.406 116.502C131.685 116.689 131.751 117.061 131.565 117.328L105.443 155.949C103.396 158.972 100.141 160.916 96.5135 161.262C96.2876 161.289 96.0617 161.302 95.8358 161.302C95.6764 161.316 95.5036 161.329 95.3442 161.329Z"
          fill="#35A597"
        />
        <path
          d="M95.3443 161.995C91.9693 161.995 88.6607 160.623 86.269 158.239L72.397 144.429C71.9054 143.936 71.9054 143.137 72.397 142.644C72.6362 142.405 72.9551 142.271 73.2873 142.271C73.6195 142.271 73.9384 142.405 74.1775 142.644L88.0495 156.455C90.0958 158.492 92.8994 159.584 95.7695 159.464C95.9555 159.451 96.1548 159.438 96.3408 159.424C99.4235 159.131 102.174 157.48 103.915 154.91L130.024 116.262C130.21 115.983 130.503 115.796 130.835 115.73C131.167 115.663 131.499 115.73 131.778 115.929C132.057 116.116 132.243 116.409 132.31 116.742C132.376 117.075 132.31 117.408 132.11 117.687L106.001 156.321C103.848 159.518 100.407 161.555 96.58 161.928C96.3541 161.955 96.1149 161.968 95.8758 161.981C95.6898 161.981 95.517 161.995 95.3443 161.995ZM73.2341 143.577L72.8621 143.949L73.2341 143.577Z"
          fill="#35A597"
        />
        <path
          d="M138.409 106.714C138.289 106.714 138.183 106.687 138.077 106.607C137.798 106.421 137.731 106.048 137.917 105.782L200.66 19.0976C202.308 16.6605 202.919 13.7174 202.361 10.8141C201.803 7.92422 200.155 5.4072 197.724 3.75582C192.688 0.346528 185.832 1.66497 182.417 6.71232L170.352 24.5578C170.166 24.8242 169.794 24.9041 169.528 24.7177C169.262 24.5312 169.183 24.1583 169.369 23.892L181.433 6.04644C185.207 0.466386 192.821 -1.01186 198.388 2.78364C201.085 4.62146 202.919 7.39152 203.53 10.601C204.142 13.8106 203.477 17.0734 201.643 19.7768L138.901 106.461C138.781 106.621 138.595 106.714 138.409 106.714Z"
          fill="#35A597"
        />
        <path
          d="M138.409 107.379C138.156 107.379 137.904 107.3 137.704 107.166C137.133 106.78 136.987 105.994 137.372 105.408L200.128 18.6979C201.67 16.4206 202.228 13.6505 201.709 10.9337C201.191 8.21694 199.637 5.85973 197.351 4.30158C192.621 1.09205 186.176 2.33058 182.974 7.07163L170.909 24.9172C170.723 25.1968 170.431 25.3833 170.099 25.4499C169.767 25.5164 169.434 25.4499 169.155 25.2501C168.584 24.8639 168.425 24.0781 168.823 23.4922L180.888 5.64665C184.874 -0.23971 192.887 -1.78455 198.76 2.1974C201.603 4.12845 203.53 7.05831 204.181 10.441C204.832 13.8236 204.128 17.2596 202.201 20.1095L139.445 106.82C139.206 107.166 138.82 107.379 138.409 107.379Z"
          fill="#35A597"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="213.355"
            height="202.414"
            fill="white"
            transform="translate(0.898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
