import React from 'react';

interface SvgIcon {
  color?: string;
  onClick?: any;
}

const LeftArrowIcon: React.FC<SvgIcon> = ({ color = 'grey', onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8298 19.7085L13.1436 17.3948L5.62802 9.86282L13.1436 2.33087L10.8298 0.0171373L0.98414 9.86282L10.8298 19.7085Z"
        fill={color}
      />
    </svg>
  );
};

export default LeftArrowIcon;
