import React from 'react';

interface SvgIcon {
  color?: string;
  onClick?: any;
}

const RightArrowIcon: React.FC<SvgIcon> = ({ color = 'grey', onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44228 0.0170898L0.12854 2.33082L7.64407 9.86277L0.12854 17.3947L2.44228 19.7084L12.288 9.86277L2.44228 0.0170898Z"
        fill={color}
      />
    </svg>
  );
};

export default RightArrowIcon;
