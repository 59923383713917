import styled from 'styled-components';

export const CardContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  > div {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    justify-content: center;
    align-items: center;

    color: #373f49;
  }

  .class-card {
    border: 1.8px solid #b1b1b1;
    display: flex;
    position: absolute;
    justify-content: flex-end;
    border-radius: 15px;
    -webkit-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    -moz-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    margin-top: 3px;
    margin-bottom: 25px;
    margin-left: 7%;
    padding: 10px;
    width: 90%;
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 0) 50%,
      rgba(255, 0, 0, 0) 70%,
      #d7d7d7
    );
  }

  .class-button-seemore {
    position: absolute;
    top: 130px;
    margin-top: 10px;
    margin-left: 45px;
    background: #ffffff;
    border: 2px solid #373f49;
    box-sizing: border-box;
    border-radius: 5.90101px;
    width: 119px;
    height: 36px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 22px;
    align-items: center;
    text-align: center;

    color: #373f49;
  }

  .class-button-edit {
    margin-top: 20px;
    margin-left: 45px;
    background: #ffffff;
    border: 2px solid #ff5722;
    box-sizing: border-box;
    border-radius: 5.90101px;
    width: 119px;
    height: 36px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #ff5722;
  }

  .class-img {
    width: 80px;
    margin-left: 45px;
    margin-top: -8px;
    position: absolute;
  }

  .class-date {
    color: #000;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: Inter;
    font-style: normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .class-hour {
    color: #000;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: Inter;
    font-style: normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .class-header {
    background: #373f49;
    color: #fff;
    padding: 5%;

    h5 {
      align-items: center;
      justify-content: center;
    }
  }

  .class-body {
    box-sizing: border-box;
    border-radius: 15px;
    flex: 1;
    justify-content: center;
    padding: 15px;

    > div {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #373f49;
    }

    .class-inside-card-information {
      flex: 1;
      justify-content: center;
      align-items: center;
      margin: 15px 30px 15px 0px;
      padding: 4px 10px -20px 4px;
    }

    .class-title {
      margin-bottom: 35px;
      margin-left: -30px;
      padding: 5px;
      padding-top: --3px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      /* identical to box height */

      /* A2 - Link */
      color: #373f49;
    }

    .class-text {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: #373f49;
    }

    .class-description {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      /* identical to box height */

      > p {
        color: #373f49;
      }
    }

    .class-icon {
      margin-left: 60px;
      margin-top: 20px;
    }

    .class-alert {
      margin-top: 20px;
      margin-left: -7px;
      flex: 1;
      font-size: 0.9rem;
      color: #373f49;
    }

    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: red;
    }
  }
`;

export const HandoutCardStyle = styled.div`
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  -moz-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);

  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;
