import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import ExamCard from '../../components/ExamCard';
import QuestionCard from '../../components/QuestionCard';
import attentionIcon from '../../assets/icons/attention.svg';
import Button from '../../components/Button';

import {
  Content,
  Container,
  Main,
  NextClassContainer,
  ExamStyled,
} from './styles';

import 'react-multi-carousel/lib/styles.css';
import { useApp } from '../../hooks/app';
import { saveExam, saveModuleExam } from '../../services/courses';

const Exam: React.FC = () => {
  const {
    currentUnitExamContent,
    setCurrentUnitExamResult,
    currentUnit,
    currentModule,
  } = useApp();

  const [questionsData, setQuestionsData] = useState([]);

  const handleQuestion = (data) => {
    let cloneQuestions = questionsData;
    let finded = false;
    const newQuestions = questionsData.map((item) => {
      if (item.question_id === data.question_id) {
        finded = true;
        return data;
      }
      return item;
    });
    if (finded) cloneQuestions = newQuestions;
    else cloneQuestions.push(data);
    setQuestionsData(cloneQuestions);
  };

  const saveExamAndCheck = async () => {
    let payload;
    let result;
    if (currentUnitExamContent.type === 'unit') {
      payload = {
        student_id: currentUnit[0].student_id,
        unit_id: currentUnit[0].id,
        moduluse_id: currentUnit[0].moduluse_id,
        course_id: currentUnit[0].course,
        finalized: 1,
        matriculation_id: currentUnit[0].matriculation_id,
        simulate_questions: questionsData,
      };
      result = await saveExam(payload);
    } else if (currentUnitExamContent.type === 'module') {
      payload = {
        course_id: currentModule.course_id,
        matriculation_id: currentModule.matriculation_id,
        student_id: currentModule.student_id,
        moduluse_id: currentModule.id,
        evaluation_questions: questionsData,
      };
      result = await saveModuleExam(payload);
    }

    if (result) {
      setCurrentUnitExamResult(result);
    }
  };

  return (
    <Content>
      <Main>
        <Container>
          <NextClassContainer>
            <span>Cursos - CFC - Exame</span>
            <ExamStyled>
              <Row>
                <Col md="12">
                  <ExamCard title="" description="" date="10/02/2021" />
                </Col>
                <Row>
                  <Col className="class-attention" md="12">
                    <img
                      className="class-icon"
                      src={attentionIcon}
                      alt="Attention Icon"
                    />
                    <p>
                      É necessário acertar todas as questões deste simulado para
                      continuar os seus estudos.
                    </p>
                  </Col>
                </Row>
                <Col md="12">
                  {currentUnitExamContent &&
                    currentUnitExamContent.content.map((item) => (
                      <QuestionCard
                        key={item.id}
                        handleQuestion={handleQuestion}
                        content={item}
                      />
                    ))}
                </Col>
                <Col md="12" style={{ marginBottom: '50px' }}>
                  <Link to="/course/ead/content/exam/result">
                    <Button
                      className="class-button"
                      width="220px"
                      hoverColor="linear-gradient(117.12deg, #3ec1b1 53.53%, #FDFEFF 112.46%)"
                      color="#FFF"
                      backgroundColor="#3ec1b1"
                      onClick={saveExamAndCheck}
                    >
                      Continuar Estudos
                    </Button>
                  </Link>
                </Col>
              </Row>
            </ExamStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default Exam;
