import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  // Prevent page scrolling when modal is open
  '@global': {
    body: {
      overflow: 'hidden',
    },
  },

  // Modal wrapper
  modalOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: '1rem',
    backgroundColor: 'rgba(35, 71, 107, 0.9)',
    zIndex: '9999999',
    opacity: 1,
    animation: '$show .5s ease',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  // Fade in open animation
  '@keyframes show': {
    '0%': {
      display: 'none',
      opacity: 0,
    },
    '1%': {
      display: 'flex',
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  // Modal itself
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    marginTop: 50,

    display: 'flex',
    alignItems: 'center',

    '@media (max-width: 485px)': {
      display: 'flex',
      flexDirection: 'column',
    },

    '@media (min-width: 576px)': {
      width: '36rem',
    },

    '& p:last-of-type': {
      marginBottom: 0,
    },
  },

  imageCapture: {
    position: 'relative',
    // width: 215,
    // height: 350,
    // margin: '0 15px',
    // marginBottom: '6rem',
  },

  imageOverlay: {
    position: 'absolute',
    bottom: '0',
    background: 'rgba(62, 193, 177, 0.5)',
    color: '#f1f1f1',
    width: '100%',
    height: '100%',
    transition: '0.5s ease',
    opacity: '1',
    fontSize: 20,

    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  closeBtn: {
    position: 'absolute',
    left: '48%',
    top: '-20px',
  },

  description: {
    marginBottom: '15px',
  },

  modalContent: {
    marginTop: '50px',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // width: 300,
    // height: 450,

    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: 9,

    '@media (max-width: 360px)': {
      // height: 450,
    },

    '@media (max-width: 485px)': {
      alignItems: 'center',
      justifyContent: 'center',
    },

    boxShadow: [0, 0, '0.625rem', 'rgba(0, 0, 0, 0.2)'],
  },

  modalMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

export default useStyles;
