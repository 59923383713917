import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DownArrowIcon from '../../components/icons/DownArrow';

import Text from '../../components/Text';
import api from '../../services/apiClient';

import './index.css';

interface TopicsProps {
  id: number;
  title: string;
  response: string;
}

const HelpCenter: React.FC = () => {
  const [topics, setTopics] = useState<TopicsProps[]>([]);
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTopis = async () => {
      try {
        setLoading(true);
        const getTopics = await api.get(
          'https://i45hcs62rk.execute-api.us-east-2.amazonaws.com/dev/',
        );

        setTopics(getTopics.data);
        setLoading(false);
      } catch {
        setError(true);
      }
    };

    getTopis();
  }, []);

  const handleExpand = (index: number): void => {
    const el = document.getElementById(`dropdownButton${index}`);

    if (el?.classList.contains('dropdownContainerExpand')) {
      el?.classList.remove('dropdownContainerExpand');
      return;
    }

    el?.classList.add('dropdownContainerExpand');
  };

  return (
    <div className="HelpCenterSection">
      <div className="HelpCenterContent">
        <div className="HelpCenterMain">
          <div>
            <div className="SectionHelpCenterContainer">
              <div>
                <Link
                  to="/"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    color: '#606E7F',
                    fontWeight: 'bold',
                    fontSize: 14,
                    alignItems: 'center',
                  }}
                >
                  <FaArrowLeft style={{ marginRight: 5 }} /> Voltar
                </Link>
                <span style={{ fontSize: 22 }}>
                  <Text
                    style={{
                      fontWeight: 200,
                      color: '#A5BBC9',
                    }}
                  >
                    UNI
                  </Text>
                  <Text style={{ fontWeight: 700, color: '#1976d2' }}>CFC</Text>
                </span>
              </div>

              <Text
                fontSize={40}
                fontWeight="700"
                style={{ marginTop: 55, textAlign: 'center' }}
              >
                Como podemos te ajudar?
              </Text>

              <div
                style={{
                  display: 'flex',
                  width: '80%',
                  textAlign: 'center',
                  marginTop: 40,
                }}
              >
                <Text fontSize={16} color="#606E7F">
                  Encontre aqui em nossa Central de Ajuda as respostas para as
                  perguntas mais frequentes.
                </Text>
              </div>

              <div className="HelpCenterCardsContainer">
                {error && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <FaExclamationTriangle size={60} color="orange" />
                    <Text color="red" fontSize={22} fontWeight="400">
                      Houve um erro ao conectar com nossos servidores. Verifique
                      sua conexão e tente novamente.
                    </Text>
                  </div>
                )}

                {loading && !error && (
                  <Text
                    fontSize={22}
                    fontWeight="400"
                    className="loading-align"
                  >
                    Carregando...
                  </Text>
                )}

                {!loading &&
                  !error &&
                  topics.map((topic, index) => (
                    <section key={topic.id} className="ContentHelpCenterStyle">
                      <button
                        className="ContentHelpCenterStyleContainer"
                        style={{ border: 0, width: '100%' }}
                        type="button"
                        onClick={() => {
                          handleExpand(index);
                        }}
                      >
                        <span>{topic.title}</span>

                        <DownArrowIcon color="#FFF" />
                      </button>

                      <div className="helpCenterStyleTableSection">
                        <div
                          id={`dropdownButton${index}`}
                          className="helpCenterStyleTableContainer"
                        >
                          <span
                            style={{ fontSize: 12 }}
                            className="containerHtml"
                            /* eslint-disable react/no-danger */
                            dangerouslySetInnerHTML={{ __html: topic.response }}
                          />
                        </div>
                      </div>
                    </section>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
