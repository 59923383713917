import React from 'react';

interface SvgIcon {
  color?: string;
}

const RefreshCircleIcon: React.FC<SvgIcon> = ({ color = 'grey' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7524 0.211914C6.24489 0.211914 0.950439 5.50693 0.950439 12.0139C0.950439 18.5311 6.23524 23.8159 12.7524 23.8159C19.26 23.8159 24.5545 18.5215 24.5545 12.0139C24.5545 5.49615 19.2702 0.211914 12.7524 0.211914ZM18.0861 12.6381C18.0861 13.693 17.7732 14.7242 17.1872 15.6013C16.6011 16.4784 15.7681 17.162 14.7935 17.5657C13.8189 17.9694 12.7465 18.075 11.7119 17.8692C10.6773 17.6634 9.72694 17.1554 8.98102 16.4095C8.23511 15.6636 7.72713 14.7132 7.52133 13.6786C7.31553 12.644 7.42116 11.5716 7.82485 10.597C8.22853 9.6224 8.91215 8.78941 9.78926 8.20334C10.6664 7.61728 11.6976 7.30447 12.7524 7.30447H12.9913L11.6296 5.9427L12.7524 4.81924L16.1455 8.21232L12.7524 11.6054L11.629 10.4819L13.2132 8.89774C13.077 8.8932 12.9227 8.8932 12.7524 8.8932C12.0118 8.8932 11.2878 9.11283 10.6719 9.52432C10.0561 9.93582 9.57608 10.5207 9.29264 11.205C9.0092 11.8893 8.93504 12.6422 9.07954 13.3687C9.22403 14.0951 9.5807 14.7624 10.1044 15.2861C10.6282 15.8098 11.2954 16.1665 12.0219 16.311C12.7483 16.4555 13.5013 16.3813 14.1855 16.0979C14.8698 15.8144 15.4547 15.3344 15.8662 14.7186C16.2777 14.1028 16.4973 13.3787 16.4973 12.6381V11.8437H18.0861V12.6381Z"
        fill={color}
      />
    </svg>
  );
};

export default RefreshCircleIcon;
