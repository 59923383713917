import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

import './styles/global.css';

import AppProvider from './hooks';

import Routes from './routes';

const ampli = {
  Auth: {
    identityPoolId: 'us-east-2:b939a3fc-ea04-48bf-a7bd-6522ee817b4a',
    region: 'us-east-2',
  },
  Storage: {
    bucket: 'unicfcead',
    region: 'us-east-2',
  },
};
Amplify.configure(ampli);

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>
    </Router>
  );
};

export default App;
