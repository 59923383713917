import styled from 'styled-components';

export const CardContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  > div {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    justify-content: center;
    align-items: center;
    color: #373f49;
  }

  .tab-out > div {
    align-items: center;
    justify-content: center;
    width: 160px;
    padding: 5px;
    border: 1px solid #7bb0e5;
    border-radius: 15px 15px 0 0;
    border-bottom: unset;
    margin-bottom: -5px;
    margin-right: 1px;
    /* background: #7bb0e5; */
    z-index: 99;
    background-image: linear-gradient(to right, #cbdff5, #84b3e3);
  }

  .tab-out {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .class-card {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    -webkit-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    -moz-box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 4px 30px -17px rgba(0, 0, 0, 0.95);
    margin-top: 3px;
    margin-bottom: 30px;
    width: 100%;
  }

  .class-header {
    background: #1976d2;
    color: #fff;
    padding: 5%;
    height: 09vh;

    h5 {
      align-items: center;
      justify-content: center;
    }
  }

  .class-body {
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 0) 50%,
      rgba(255, 0, 0, 0) 55%,
      #7bb0e5
    );
    border: 1px solid #7bb0e5;
    box-sizing: border-box;

    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;

    > div {
      font-family: Inter;
      font-style: normal;
      font-weight: 100;
      font-size: 20px;
      line-height: 15px;
      color: #0e4378;
    }

    .class-description {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: #373f49;
    }

    .class-label {
      border: 1px solid #7bb0e5;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      width: 25vh;
      height: 4vh;
      margin-top: -44px;
      right: 5px;
      background-color: #7bb0e5;
    }

    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: red;
    }

    .card-body {
      width: 15%;
      height: 20%;
      margin-left: -70px;
      margin-top: -10%;
      position: relative;

      background: red;
      border: 1px solid #7bb0e5;
      box-sizing: border-box;
      border-radius: 15px;
    }
  }
`;

export const HandoutCardStyle = styled.div`
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  -moz-box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 4px 40px -17px rgba(0, 0, 0, 0.95);

  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;
