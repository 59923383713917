import React from 'react';

import UniCFCLogo from '../../assets/logos/UNICFC_LOADING.svg';

import { Content, Container, Main } from './styles';

const InitialPage: React.FC = () => {
  return (
    <Content>
      <Main>
        <Container>
          <div className="class-welcome">
            <div>
              <span>Seja Bem Vindo à</span>
            </div>
            <img src={UniCFCLogo} alt="UNICFC" width={180} />
          </div>
        </Container>
      </Main>
    </Content>
  );
};

export default InitialPage;
