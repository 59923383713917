import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FinishedModuleScreen } from './styles';
import CheckIcon from '../icons/Check';
import Button from '../Button';
import { useApp } from '../../hooks/app';

const FinishedModule: React.FC = () => {
  const { showCongratulationModal, setShowCongratulationModal } = useApp();

  return (
    <FinishedModuleScreen>
      <div className="finished-module-background" />
      <div className="finished-module-container">
        <Row>
          <Col md="12">
            <Row className="icon-container">
              <Col md="12">
                <CheckIcon />
              </Col>
              <Col md="12">
                <h3>Parabéns</h3>
              </Col>
              <Col md="12">
                <h5>
                  {showCongratulationModal.type &&
                    (showCongratulationModal.type === 'module'
                      ? 'Módulo Concluído'
                      : 'Unidade Concluida')}
                </h5>
              </Col>
            </Row>
          </Col>
          <Col md="12">
            <Link to="/course/ead">
              <Button
                type="button"
                style={{
                  background: 'unset',
                  border: '1px solid #fff',
                  color: '#fff',
                }}
                onClick={() => {
                  setShowCongratulationModal({ show: false, type: '' });
                }}
              >
                <span>Voltar para cursos</span>
              </Button>
            </Link>
          </Col>
          {/* <Col md="12">
            <Button
              type="button"
              style={{
                background: '#FF5722',
                color: '#fff',
              }}
              onClick={() => {
                setShowCongratulationModal({ show: false, type: '' });
              }}
            >
              <span>Certificado</span>
            </Button>
          </Col> */}
        </Row>
      </div>
    </FinishedModuleScreen>
  );
};

export default FinishedModule;
