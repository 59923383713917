import axios from 'axios';

export const accepTerms = async () => {
  const userData: string = JSON.parse(
    localStorage.getItem('@UniCFC:user') || '',
  );
  const payload = {
    student_id: userData.id,
  };
  const response = await axios.post(
    'https://oq7a43z1e6.execute-api.us-east-2.amazonaws.com/dev/',
    payload,
  );
  return response;
};
