import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Footer } from './styles';
import MenuLateralComponent, { Hamburguer } from '../MenuLateral';
import HeaderComponent from '../Header';
import MyAlertIcon from '../../assets/icons/myAlertIcon.svg';
import MyCoursesIcon from '../../assets/icons/myCoursesIcon.svg';
import MyDataIcon from '../../assets/icons/myDataIcon.svg';
import MyNotesIcon from '../../assets/icons/myNotesIcon.svg';
import MyBook from '../../assets/icons/myBook.svg';
import { useAuth } from '../../hooks/auth';

const Layout: React.FC = ({ children }) => {
  const [open, setOpen] = useState(window.innerWidth > 600);

  const { user, signOut } = useAuth();

  const history = useHistory();

  const verifyObligatoriness = () => {
    setTimeout(() => {
      const path = window.location.pathname;
      if (
        (!user.accept_terms || user.first_access) &&
        path !== '/terms' &&
        path !== '/change-password'
      ) {
        signOut();
        history.push('/login');
        return null;
      }
      return true;
    }, 1000);
    return true;
  };

  return (
    verifyObligatoriness() && (
      <>
        <MenuLateralComponent
          menuOpened={open}
          Menu={[
            {
              id: 1,
              title: 'Meus Dados',
              image: MyDataIcon,
              route: '/me',
            },
            {
              id: 2,
              title: 'Cursos',
              image: MyCoursesIcon,
              route: '/course',
              // isActive: true,
            },
            { id: 4, title: 'Avisos', image: MyAlertIcon, route: '/notices' },
            {
              id: 5,
              title: 'Anotações',
              image: MyNotesIcon,
              route: '/annotations',
            },
            {
              id: 7,
              title: 'Biblioteca',
              image: MyBook,
              route: '/library',
            },
          ]}
          SubItemsMenu={[
            {
              id: 1,
              title: 'Forum',
              route: '/forum',
            },
            {
              id: 2,
              title: 'Suporte',
              route: '/help',
            },
          ]}
        />
        <HeaderComponent>
          <Hamburguer open={open} setOpen={setOpen} />
        </HeaderComponent>
        <div style={{ paddingTop: '' }}>{children}</div>
        <Footer>
          <span>©2021 UNICFC S/A - Todos os direitos reservados</span>
        </Footer>
      </>
    )
  );
};

export default Layout;
