import React from 'react';

interface SvgIcon {
  color?: string;
}

/* eslint-disable-next-line */
const DeleteIcon: React.FC<SvgIcon> = ({ color = '#1976D2' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#1976D2" />
      <path
        d="M23 9H9.15385C8.51659 9 8 9.51659 8 10.1538V10.7308C8 11.368 8.51659 11.8846 9.15385 11.8846H23C23.6372 11.8846 24.1538 11.368 24.1538 10.7308V10.1538C24.1538 9.51659 23.6372 9 23 9Z"
        fill="white"
      />
      <path
        d="M9.53068 13.0386C9.49014 13.0384 9.45002 13.0467 9.41291 13.063C9.37581 13.0793 9.34257 13.1033 9.31535 13.1334C9.28813 13.1634 9.26755 13.1988 9.25495 13.2374C9.24235 13.2759 9.23801 13.3166 9.24222 13.357L10.1909 22.4637C10.1907 22.4663 10.1907 22.469 10.1909 22.4716C10.2405 22.8928 10.443 23.2812 10.76 23.563C11.077 23.8447 11.4864 24.0003 11.9105 24.0001H20.2431C20.6671 24.0001 21.0763 23.8445 21.3931 23.5627C21.71 23.281 21.9124 22.8927 21.9619 22.4716V22.4641L22.9092 13.357C22.9134 13.3166 22.909 13.2759 22.8964 13.2374C22.8838 13.1988 22.8632 13.1634 22.836 13.1334C22.8088 13.1033 22.7756 13.0793 22.7385 13.063C22.7014 13.0467 22.6612 13.0384 22.6207 13.0386H9.53068ZM18.504 19.5538C18.5588 19.6071 18.6025 19.6708 18.6326 19.7411C18.6626 19.8115 18.6783 19.8871 18.6789 19.9635C18.6794 20.04 18.6647 20.1158 18.6357 20.1866C18.6067 20.2573 18.5639 20.3216 18.5098 20.3757C18.4557 20.4297 18.3914 20.4725 18.3207 20.5015C18.2499 20.5305 18.1741 20.5451 18.0976 20.5445C18.0211 20.5439 17.9456 20.5282 17.8752 20.4981C17.8049 20.4681 17.7413 20.4243 17.688 20.3695L16.077 18.7584L14.4655 20.3695C14.3568 20.4751 14.211 20.5337 14.0594 20.5326C13.9079 20.5315 13.7628 20.4709 13.6556 20.3637C13.5485 20.2566 13.4877 20.1116 13.4866 19.96C13.4855 19.8085 13.544 19.6626 13.6495 19.5538L15.261 17.9424L13.6495 16.331C13.544 16.2223 13.4855 16.0764 13.4866 15.9248C13.4877 15.7733 13.5485 15.6283 13.6556 15.5211C13.7628 15.414 13.9079 15.3533 14.0594 15.3522C14.211 15.3512 14.3568 15.4098 14.4655 15.5154L16.077 17.1264L17.688 15.5154C17.7967 15.4098 17.9426 15.3512 18.0941 15.3522C18.2457 15.3533 18.3907 15.414 18.4979 15.5211C18.6051 15.6283 18.6658 15.7733 18.6669 15.9248C18.6681 16.0764 18.6096 16.2223 18.504 16.331L16.8926 17.9424L18.504 19.5538Z"
        fill="white"
      />
    </svg>
  );
};

export default DeleteIcon;
