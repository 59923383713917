import React, { useState } from 'react';
import axios from 'axios';
import { Card, Form, Spinner } from 'react-bootstrap';
import { CardContainer } from './styles';
import AddIcon from '../icons/AddIcon';
import RightIcon from '../icons/BlueRightIcon';
import { useApp } from '../../hooks/app';

const NoteCard = () => {
  const [showNote, setShowNote] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const { currentUnit, currentCourse } = useApp(); /* eslint-disable-line */

  const handleSubmit = async () => {
    if (title.length <= 0) {
      alert('Você deve inserir um título'); /* eslint-disable-line */
      return;
    }
    if (text.length <= 0) {
      alert('Você deve inserir um texto'); /* eslint-disable-line */
      return;
    }

    setLoading(true);

    const objectNote = {
      title,
      body: text,
      student_id: currentUnit[0].student_id,
      slug: currentUnit[0].slug,
      course_id: currentUnit[0].course,
      unit_id: currentUnit[0].id,
      matriculation_id: currentUnit[0].matriculation_id,
    };
    const result = await axios.post(
      'https://6if84yzdb0.execute-api.us-east-2.amazonaws.com/dev/',
      objectNote,
    );
    if (result) {
      alert('salvo com sucesso'); /* eslint-disable-line */
      setShowNote(false);
      setTitle('');
      setText('');
    } else {
      alert('sua anotação não pode ser salva, algo deu errado'); /* eslint-disable-line */
    }

    setLoading(false);
  };

  const handleChangeNote = (e: any) => {
    e.preventDefault();
    if (e.target.name === 'Title') setTitle(e.target.value);
    if (e.target.name === 'Text') setText(e.target.value);
  };

  const handleToggle = () => {
    if (showNote) handleSubmit();
    else setShowNote(!showNote);
  };

  return (
    <CardContainer>
      <div className="add-annotation-icon">
        {!showNote && (
          <button onClick={handleToggle} type="button" className="closeBtn">
            {loading ? (
              <Spinner animation="border" style={{ color: '#1976d2' }} />
            ) : (
              <RightIcon />
            )}
          </button>
        )}
      </div>
      {showNote && (
        <div className="form-annotation-container">
          <div className="closeBtn">
            <button type="button" onClick={() => setShowNote(false)}>
              X
            </button>
          </div>
          <div>
            <Form>
              <Card
                style={{ backgroundColor: '#DEEBF8', padding: '3px' }}
                className="class-card"
              >
                <Card.Header className="class-header">
                  <Form.Group>
                    <Form.Control
                      style={{ background: '#1976d2', border: 'unset' }}
                      className="class-note-header"
                      type="text"
                      placeholder="Título"
                      as="input"
                      name="Title"
                      onChange={handleChangeNote}
                    />
                  </Form.Group>
                  <div className="send-annotation-icon">
                    <button type="button" onClick={handleToggle}>
                      <AddIcon color="white" />
                    </button>
                  </div>
                </Card.Header>
                <Card.Body className="class-body">
                  <Form.Group className="class-text-area">
                    <Form.Control
                      className="class-text-area"
                      style={{ background: '#DEEBF8' }}
                      type="text"
                      placeholder="Escreva sua anotação aqui"
                      name="Text"
                      as="textarea"
                      rows={10}
                      onChange={handleChangeNote}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Form>
          </div>
        </div>
      )}
    </CardContainer>
  );
};

export default NoteCard;
