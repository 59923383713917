import axios from 'axios';

export const updatePassword = async (payload) => {
  const response = await axios.post(
    'https://rpmajy1vyh.execute-api.us-east-2.amazonaws.com/dev/',
    payload,
  );
  const { data } = response;
  return data;
};
