import styled from 'styled-components';
// import { shade } from 'polished';

interface SelectLoginInterface {
  isSelected?: boolean;
}

export const Container = styled.button<SelectLoginInterface>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 0;
  background: transparent;

  width: 80px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;

    background: ${(props) =>
      props.isSelected
        ? `linear-gradient(0deg, #1976d2, #1976d2), #ff5722`
        : `#ECF2F8`};

    border-radius: 16px;

    img {
      margin: 0 auto;
      align-self: center;
    }
  }

  span {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: ${(props) => (props.isSelected ? '#1976d2' : '#ADC3D9')};
  }
`;
