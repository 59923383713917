import styled from 'styled-components';

export const FinishedModuleScreen = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;

  .finished-module-background {
    height: 100%;
    width: 100%;
    background: #23476b;
    opacity: 0.9;
  }

  .finished-module-container {
    width: 300px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon-container {
      > div:nth-child(1) {
        text-align: center;
      }
      > div:nth-child(2) h3 {
        font-family: Inter;
        font-size: 65px;
        font-style: normal;
        font-weight: 700;
        line-height: 79px;
        letter-spacing: 0em;
        text-align: center;
        color: #3ec1b1;
      }
      > div:nth-child(3) h5 {
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
      }
    }
  }
`;
