import styled from 'styled-components';

export const Content = styled.div``;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  height: auto;

  @media (max-width: 650px) {
    position: relative;
  }
`;

export const Container = styled.div`
  width: 1280px;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column !important;

  span {
    font-weight: 700;
    margin-top: 20px;
    margin-left: 50px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #373f49;
  }
`;

export const NextClassContainer = styled.section`
  width: 90%;
  margin-top: 3%;
  margin-left: 5%;

  > span {
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 24px;
    color: #373f49;
    margin-bottom: 40px;
  }

  .class-name-title {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: -15px;
    font-weight: normal;
    font-size: 18px;
    color: #373f49;
  }

  .class-resume {
    margin-top: 10px;
    margin-right: -5px;
    font-weight: normal;
    font-size: 18px;
    color: #373f49;
  }

  .class-name-description {
    margin-bottom: 40px;

    span {
      margin-left: 0px;
      display: flex;
      justify-content: flex-start;
      color: #373f49;
      font-size: 16px;
    }
  }

  .class-new-topic {
    display: flex;
    justify-content: flex-end;
  }

  .class-button-new-topic {
    margin-left: -25px;
    width: 160px;
    margin-bottom: 17px;
    background: #1976d2;
    border-radius: 6.94137px;
  }

  .class-editor {
    margin-top: -25px;
    margin-bottom: 30px;
  }

  .class-first-button {
    justify-content: center;
    width: 240px;
    height: 48px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18.5103px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;
    color: #1976d2;
    background-color: #fff;

    &:hover {
      background: #b9e7f7;
    }
  }

  .class-second-button {
    border-color: #1976d2;
    justify-content: center;
    width: 240px;
    height: 48px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18.5103px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;
    color: #fff;
    background-color: #1976d2;

    &:hover {
      background: #19a4d2;
    }
  }

  .class-search {
    display: flex;
    justify-content: flex-end;
  }

  .class-search-button {
    position: absolute;
    top: -54px;
    right: -80px;
    width: 60px;
    background: #1976d2;
    border-radius: 6.94137px;
  }
`;

export const Footer = styled.footer`
  display: flex;

  justify-content: center;
  align-items: center;

  bottom: 0;

  height: 100px;
  width: 100%;

  background: #0e4378;

  span {
    font-size: 12px;
    color: #fff;
  }
`;

export const NoteStyled = styled.div`
  margin-top: 20px;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0e4378;
  }

  .class-icon {
    width: 4vh;
    margin-top: 10px;
    margin-left: 25px;
  }

  .class-attention > p {
    margin-top: -18px;
    margin-left: 80px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 100%;
    /* identical to box height, or 17px */
    display: flex;
    align-items: center;

    color: #da4a1d;
  }
`;

export const ChangeDataOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 650px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
  }

  > div {
    border: 1px solid #7bb0e5;
    border-radius: 15px;
  }

  > div:last-child {
    display: flex;

    flex-direction: column;

    width: 40vh;

    padding: 20px;

    @media (max-width: 650px) {
      margin-top: 1.22rem;
    }

    div + div {
      margin: 8px 0;
    }

    > span {
      font-weight: bold;
      font-size: 18px;

      color: #373f49;

      margin-bottom: 1rem;
    }
  }
`;

export const ChangeImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 40vh;

  padding: 15px;

  margin: 0 2.25rem;

  .imgPerfil {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 1rem;
  }
`;

export const MyDataContainer = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px;
`;

export const RowOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-around;

  width: 95%;

  > div span {
    font-size: 16px !important;
    font-weight: normal;
  }

  div:first-child {
    flex: 1;

    > span {
      display: flex;
      justify-content: flex-end;
    }
  }

  div:last-child {
    display: flex;
    align-items: center;

    width: 95%;

    height: 48px;
    border-radius: 6px;
    background: #f7f9fc;
    border: 1px solid rgba(55, 63, 73, 0.1);

    padding: 5px;

    span {
      color: #373f49;
      margin-left: 5px;
    }
  }

  div + div {
    margin: 0 8px;
  }

  margin-top: 13px;
`;

export const RowTwo = styled.div`
  flex: 1;
  flex-wrap: wrap;
  width: 95%;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

export const RowTree = styled.div`
  flex: 1;

  width: 95%;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
`;
