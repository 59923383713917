import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import App from './app.routes';
import Auth from './auth.routes';

const Routes: React.FC = () => {
  const { user } = useAuth();

  return <BrowserRouter>{user ? <App /> : <Auth />}</BrowserRouter>;
};

export default Routes;
