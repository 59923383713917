import styled from 'styled-components';

export const CardContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: px;

  > div {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    justify-content: center;
    align-items: center;

    color: #373f49;
  }

  .class-card {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    border: none;
  }

  .class-body {
    > div {
      font-family: Inter;
      font-style: normal;
      font-size: 0.7rem;
      line-height: 19px;
      border: none;

      color: #000;
    }

    .class-title {
      font-family: Inter;
      font-style: normal;
      font-size: 1rem;
      line-height: 19px;
      font-weight: bold;
    }

    .class-answers {
      font-size: 0.75rem;
      padding: 5px;
      margin-left: 10px;
    }

    .class-input {
      margin-right: 15px;
      width: 12px;
    }

    .class-icon {
      width: 5vh;
    }

    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: red;
    }

    .card-body {
      width: 15%;
      height: 20%;
      margin-left: -70px;
      margin-top: 0;
      position: relative;
      box-sizing: border-box;
    }
  }

  .class-cod {
    font-size: 0.7rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
  }
`;

export const HandoutCardStyle = styled.div`
  > div {
    padding: 30px;
    margin-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
      p {
        position: absolute;
        width: 165px;
        height: 60px;
        left: 414px;
        top: 354px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #0e4378;
      }
      button {
        margin-top: 80px;
        width: 176px;
      }

      @media (max-width: 650px) {
        align-items: center;
        button {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 650px) {
      > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
`;
