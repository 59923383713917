/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import {
  Content,
  Container,
  Main,
  NextClassContainer,
  LeassonSelectedStyled,
} from './styles';
import Text from '../../components/Text';
import webex from '../../assets/images/webex.svg';
import androidBtn from '../../assets/images/android.jpg';
import iosBtn from '../../assets/images/ios.jpg';
import pcMacBtn from '../../assets/images/pc-mac.jpg';
import JustificationModal from '../../components/JustificationModal';
import LeassonSelectedComponent from '../../components/LeassonSelectedComponent';
import Modal from '../../components/ModalCam';
import AppContext from '../../contexts/AppContext';
import Timer from '../../components/CountdownV2';
import { justifyLeasson } from '../../services/courses';

const LiveClass = () => {
  const { currentLeasson, currentCourse } = useContext(AppContext);
  const [showJustification, setShowJustification] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [timerBase, setTimerBase] = useState(false);

  const timerBaseManager = useCallback(() => {
    const currentTime = currentLeasson.minutesToStart;
    if (currentTime > 0 && currentTime < 60) setTimerBase('oneHour');
    else if (currentTime < 0 && currentTime > -60) setTimerBase('progress');
    else if (currentTime < -60) setTimerBase('');
  });

  useEffect(() => {
    timerBaseManager();
  }, [currentCourse, timerBaseManager]);

  const handleJustification = async (text) => {
    const payload = {
      classroom_id: currentLeasson.id,
      student_id: currentCourse.student_id,
      date_hour: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
      text,
    };
    const result = await justifyLeasson(payload);
    console.log(result);
  };

  return (
    <Content>
      {showJustification && (
        <JustificationModal
          handleJustification={handleJustification}
          setShowJustification={setShowJustification}
        />
      )}
      <Main>
        {statusModal && (
          <Modal
            modalTitle="Hora da foto!"
            closeButtonVisible={false}
            onCloseRequest={() => {
              setStatusModal(false);
            }}
          />
        )}
        <Container>
          <NextClassContainer>
            <LeassonSelectedStyled>
              <Row className="header-container">
                <Col md="12">
                  <Row className="header-leasson-selected">
                    <Col md="8" xs="6">
                      <Text fontSize={16}>Aula ao vivo</Text>
                    </Col>
                    <Col md="4" xs="6">
                      {currentLeasson.status === 'ready' && timerBase !== '' && (
                        <div>
                          <span>Duração da aula</span>
                          <span>
                            <Timer
                              initialMinute={currentLeasson.minutesToStart}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md="12">
                  <LeassonSelectedComponent
                    courseDetails={currentCourse}
                    content={currentLeasson}
                    action={setShowJustification}
                    status={timerBase}
                  />
                </Col>
                <Col md="12">
                  <Row>
                    <Col md="3" className="container-wb">
                      <div className="cisco-webex-download-container">
                        <h3>
                          Sua primeira vez?
                          <br /> Faça o download do Cisco WebEx
                        </h3>
                        <h5>Qual dispositivo você esta usando?</h5>
                        <div className="btn-download-container">
                          <img src={pcMacBtn} alt="pc/mac" />
                          <img src={androidBtn} alt="android" />
                          <img src={iosBtn} alt="ios" />
                        </div>
                      </div>
                    </Col>
                    <Col md="9" className="webex-video">
                      <img src={webex} alt="pc/mac" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </LeassonSelectedStyled>
          </NextClassContainer>
        </Container>
      </Main>
    </Content>
  );
};

export default LiveClass;
